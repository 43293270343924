import React from 'react';
import styled from 'styled-components';
import axios from 'axios';

import Link from 'components/ui/Link';
import { LEGACY_DOMAIN, STATIC_BASE_URL } from 'config';
import PropTypes from 'prop-types';
import BaseLoginButton from '../BaseLoginButton';

const CORNELL_REQUEST_URL = `https://${LEGACY_DOMAIN}/pkg/dobi-api/4.0/api/cornell/login`;

export const ImgIcon = styled.img`
  height: 22px;
  width: 22px;
`;

export default class CornellLoginButton extends React.Component {
  static propTypes = {
    signUp: PropTypes.bool.isRequired,
  };

  state = {
    redirectUrl: '',
  };

  async componentDidMount() {
    const { data: redirectUrl } = await axios.get(CORNELL_REQUEST_URL,
      { params: { _: Date.now() } });
    // eslint-disable-next-line
    this.setState({
      redirectUrl,
    });
  }

  render() {
    const { redirectUrl } = this.state;
    if (!redirectUrl) {
      return null;
    }
    const { signUp } = this.props;

    return (
      <Link href={redirectUrl} target="_self">
        <BaseLoginButton
          color="#b31b1b"
          dataTestId="authModalCornellLoginButton"
          providerNameKey="CORNELL"
          renderIcon={
            () => <ImgIcon src={`${STATIC_BASE_URL}/media/578d2dea2659bf83031fff0b/59cd89e4efde60b73e138906.jpg`} />
          }
          signUp={signUp}
        />
      </Link>
    );
  }
}
