// tslint:disable:jsx-boolean-value
import React, { useCallback, useMemo } from 'react';
import useFocusArea from 'hooks/use-focus-area';
import ScrollNavigation from 'components/ui/ScrollNavigation';
import { isMobileLayout } from 'services/device/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { trackChannelSelect } from 'services/insights/actions';
import { getChannelSelectPages, channelSelectEnabled, getCurrentChannelId, getShowMobileChannelSelector, getShouldCloseChannelSelectOnOutsideClick } from 'services/app/selectors';
import { setShowMobileChannelSelector } from 'services/app/actions';
import ChannelPage from './ChannelPage';
import { MobileChannelSelectProps } from './interfaces';
import { Wrapper } from './styles';

export default function MobileChannelSelect({ onClose, ...props }: MobileChannelSelectProps) {
  const dispatch = useDispatch();
  const isMobile = useSelector(isMobileLayout);
  const pages = useSelector(getChannelSelectPages);
  const isChannelSelectEnabled = useSelector(channelSelectEnabled);
  const currentChannelId = useSelector(getCurrentChannelId);
  const showMobileChannelSelector = useSelector(getShowMobileChannelSelector);
  const closeOnOutsideClick = useSelector(getShouldCloseChannelSelectOnOutsideClick);

  const shouldRender = useMemo(() => !!(
    isMobile && showMobileChannelSelector && isChannelSelectEnabled && pages?.length
  ), [isMobile, showMobileChannelSelector, isChannelSelectEnabled, pages?.length]);

  const wrapperRef = useFocusArea<HTMLDivElement>({
    onExit: () => {
      if (closeOnOutsideClick && shouldRender) {
        dispatch(setShowMobileChannelSelector(false));
      }
    },
    active: shouldRender,
  });

  const onEngage = useCallback((doc: {}, extra: {}) => {
    dispatch(setShowMobileChannelSelector(false));
    dispatch(trackChannelSelect('engage', doc, extra));
    onClose?.();
  }, [onClose]);

  return shouldRender ? (
    <Wrapper ref={wrapperRef}>
      <ScrollNavigation
        {...props}
        pages={pages!}
        snapAcceleration={3}
        wrapperOffset={30}
        wrapperSeparation={5}
      >
        <ChannelPage
          currentChannelId={currentChannelId}
          onEngage={onEngage}
        />
      </ScrollNavigation>
    </Wrapper>
  ) : null;
}
