import React, { useMemo } from 'react';
import LeaderboardMockPanel from 'components/panels/LeaderboardPanel/LeaderboardMockPanel';
import {
  CHAT_ID,
  QUEST_ID,
  LEADERBOARD_ID,
  SHOPIFY_ID,
  PEOPLE_ID,
} from 'components/objects/PanelV2/constants';
import { PanelView } from '.';
import ChatPreviewPanel from './ChatPreviewPanel';
import QuestPreviewPanel from './QuestPreviewPanel';
import PeopleGroupPreviewPanel from './PeopleGroupPreviewPanel';
import { IRenderer } from 'models/IPanel';

const PREVIEW_PANEL_PROPS = {
  isEditMode: true,
  isMobileLayout: false,
};

const PREVIEW_OVERRIDE_COMPONENTS = {
  [CHAT_ID]: ChatPreviewPanel,
};

const MOCK_OVERRIDE_COMPONENTS = {
  [LEADERBOARD_ID]: LeaderboardMockPanel,
  [QUEST_ID]: QuestPreviewPanel,
  [PEOPLE_ID]: PeopleGroupPreviewPanel,
};

interface IPreviewPanel {
  className: string;
  hidden: boolean;
  renderer: IRenderer;
  useMockPanel: boolean;
}

const PreviewPanel: React.FC<IPreviewPanel> = ({
  renderer,
  className,
  hidden = false,
  useMockPanel = false,
}) => {
  const doc = useMemo(
    () => ({
      _id: 'reid_farted',
      data: {},
      renderer,
      slug: 'andy_is_nerd',
    }),
    [renderer],
  );

  if (useMockPanel && MOCK_OVERRIDE_COMPONENTS[renderer.panelType]) {
    const MockOverrideComponent = MOCK_OVERRIDE_COMPONENTS[renderer.panelType];
    return <MockOverrideComponent renderer={renderer} />;
  }
  if (PREVIEW_OVERRIDE_COMPONENTS[renderer.panelType]) {
    const PreviewOverrideComponent =
      PREVIEW_OVERRIDE_COMPONENTS[renderer.panelType];
    return <PreviewOverrideComponent renderer={renderer} />;
  }
  return (
    <PanelView
      className={className}
      doc={doc}
      hidden={hidden}
      {...PREVIEW_PANEL_PROPS}
    />
  );
};

export default React.memo(PreviewPanel);
