import React from 'react';
import { Lifespan } from './styles';
import LiveBadge from 'components/ui/LiveBadge';
import useQuestLifespan from 'hooks/use-quest-lifespan';

type QuestExpirationProps = {
  timeMaximum?: number | null;
  timeMinimum?: number | null;
};

const QuestExpiration = ({ timeMinimum, timeMaximum }: QuestExpirationProps) => {
  const { expiresIn, availableIn } = useQuestLifespan(timeMinimum, timeMaximum);
  if (!expiresIn || availableIn) {
    return null;
  }

  return (
    <Lifespan>
      <LiveBadge.Dot />
      {expiresIn}
    </Lifespan>
  );
};

export default QuestExpiration;
