import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Checkbox from 'components/ui/Checkbox';
import { CONTENT_FONT, TEXT_P2 } from 'style/mixins';
import { DEEP_LINK } from 'injection-classes';
import { PRIVACY_POLICY_URL, TERMS_OF_USE_URL } from 'shared/constants';
import { ACCENT_PRIMARY, TEXT_100 } from 'style/constants';
import Link from 'components/ui/Link';
import { DynamicTranslationType, useEndUserTranslation } from 'hooks/use-translation';

const TermsAndPolicyWrapper = styled.div`
  margin-top: 10px;
  color: ${TEXT_100};
  display: flex;
  font-family: ${CONTENT_FONT};
`;

export const AgreeText = styled.div`
  ${TEXT_P2};
  ${CONTENT_FONT}
  font-weight: 400;
  padding-left: 12px;
  font-size: 12px;
  color: ${TEXT_100};
  line-height: 17.71px;
  cursor: default;
`;

export const StyledLink = styled(Link)`
  color: ${ACCENT_PRIMARY};
  font-size: 12px;
`;

const TermsAndPolicy = ({ customModal, setAcceptedTermsAndPolicy }) => {
  const { endUserT } = useEndUserTranslation();
  const [accepted, setAccepted] = useState(false);

  useEffect(() => {
    setAcceptedTermsAndPolicy(accepted);
  }, [accepted]);

  return (
    <TermsAndPolicyWrapper>
      <Checkbox
        checked={accepted}
        data-testid="authModalRegisterViewTermsAndPolicyCheckbox"
        onChange={() => setAccepted(value => !value)}
        size={16}
      />
      <AgreeText data-testid="authModalRegisterViewTermsAndPolicyText" onClick={() => setAccepted(value => !value)}>
        {
          customModal.terms || customModal.privacy ?
            endUserT([DynamicTranslationType.createAccountAgreeTermsOfUse], ['ACTION_LOGIN_UP_HELPER']) :
            endUserT([DynamicTranslationType.createAccountAgreeMaestroTermsOfUse], ['AGREE_TERMS_OF_USE'])
        }
        <StyledLink className={DEEP_LINK} href={customModal.terms || TERMS_OF_USE_URL}>
          {endUserT([DynamicTranslationType.createAccountTermsOfUse], ['TERMS_OF_USE'])}
        </StyledLink>
        &nbsp;{endUserT([DynamicTranslationType.createAccountAnd], ['AND'])}&nbsp;
        <StyledLink className={DEEP_LINK} href={customModal.privacy || PRIVACY_POLICY_URL}>
          {endUserT([DynamicTranslationType.createAccountPrivacyPolicy], ['PRIVACY_POLICY'])}
        </StyledLink>
      </AgreeText>
    </TermsAndPolicyWrapper>
  );
};

TermsAndPolicy.propTypes = {
  customModal: PropTypes.shape({
    privacy: PropTypes.string,
    terms: PropTypes.string,
  }),
  setAcceptedTermsAndPolicy: PropTypes.func.isRequired,
};

export default TermsAndPolicy;
