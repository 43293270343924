import React, { useState, useRef, MutableRefObject, useEffect } from 'react';
import styled from 'styled-components';
import { bw } from 'colors';
import { ACCENT_PRIMARY, SURFACE_3, ALERT_ERROR } from 'style/constants';

const AccessCodeContainer = styled.div`
  margin: 7px 0 5px 0;
`;

const StyledFormInputOneCharacter = styled.input<{
  hasError: boolean;
  isTransparent?: boolean;
}>`
  width: 36px;
  height: 39px;
  background: ${SURFACE_3};
  border: 1px solid
    ${(props) => (props.hasError ? ALERT_ERROR(props) : ACCENT_PRIMARY(props))};
  border-radius: 5px;
  padding: 11.5px 10px 11.5px 12px;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  margin-right: 7px;
  color: ${(props) =>
    props.isTransparent
      ? 'transparent'
      : bw(SURFACE_3(props))};
  text-transform: uppercase;
`;

interface IProps {
  className: string;
  hasError: boolean;
  isTransparent?: boolean;
  loggedIn: boolean;
  setAccessCode: (accessCode: string) => void;
}

const AccessCode = ({
  setAccessCode,
  hasError,
  className,
  loggedIn,
  isTransparent,
}: IProps) => {
  const inputRef0 = useRef() as MutableRefObject<HTMLInputElement>;
  const inputRef1 = useRef() as MutableRefObject<HTMLInputElement>;
  const inputRef2 = useRef() as MutableRefObject<HTMLInputElement>;
  const inputRef3 = useRef() as MutableRefObject<HTMLInputElement>;
  const inputRef4 = useRef() as MutableRefObject<HTMLInputElement>;
  const inputRef5 = useRef() as MutableRefObject<HTMLInputElement>;
  const [code, setCode] = useState({
    key0: '',
    key1: '',
    key2: '',
    key3: '',
    key4: '',
    key5: '',
  });

  useEffect(() => {
    if (inputRef0.current) {
      inputRef0.current.focus();
    }
  }, []);

  useEffect(() => {
    let newAccessCode = '';

    for (const [, value] of Object.entries(code)) {
      newAccessCode = `${newAccessCode}${value}`;
    }

    if (newAccessCode.length === 6) {
      setAccessCode(newAccessCode);
    } else {
      setAccessCode('');
    }
  }, [code]);

  useEffect(() => {
    if (!loggedIn) {
      setCode({ key0: '', key1: '', key2: '', key3: '', key4: '', key5: '' });
    }
  }, [loggedIn]);

  const handleCode = (e: any) => {
    const newAccessCode = e.target.value.trim();
    const key = e.target.dataset.key;

    const match = newAccessCode.match(/[A-Za-z0-9]*/);
    if (newAccessCode.length > 1 || !match || match[0] !== newAccessCode) {
      return;
    }
    setCode({ ...code, [`key${key}`]: newAccessCode });

    if (newAccessCode) {
      nextInput(key);
    }
  };

  const nextInput = (key: string) => {
    let refInput;
    switch (String(key)) {
      case '0':
        refInput = inputRef1;
        break;
      case '1':
        refInput = inputRef2;
        break;
      case '2':
        refInput = inputRef3;
        break;
      case '3':
        refInput = inputRef4;
        break;
      case '4':
        refInput = inputRef5;
        break;
    }

    if (refInput?.current) {
      refInput.current.focus();
    }
  };

  const backInput = (key: string) => {
    let refInput;
    switch (String(key)) {
      case '1':
        refInput = inputRef0;
        break;
      case '2':
        refInput = inputRef1;
        break;
      case '3':
        refInput = inputRef2;
        break;
      case '4':
        refInput = inputRef3;
        break;
      case '5':
        refInput = inputRef4;
        break;
    }

    if (refInput?.current) {
      refInput.current.focus();
    }
  };

  const handleFocus = (e: any) => {
    e.target.select();
  };

  const handleKeyDown = (e: any) => {
    if (e.keyCode === 8) {
      const key = e.target.dataset.key;
      setCode({ ...code, [`key${key}`]: '' });
      backInput(key);
      return;
    }
  };

  const handlePaste = (e: any) => {
    const keys = e.clipboardData.getData('Text').split('');
    for (const key of keys) {
      const match = key.match(/[A-Za-z0-9]*/);
      if (!match || match[0] !== key) {
        return;
      }
    }
    setCode({
      key0: keys[0] || '',
      key1: keys[1] || '',
      key2: keys[2] || '',
      key3: keys[3] || '',
      key4: keys[4] || '',
      key5: keys[5] || '',
    });
  };

  return (
    <AccessCodeContainer className={className}>
      <StyledFormInputOneCharacter
        onChange={handleCode}
        onPaste={handlePaste}
        onFocus={handleFocus}
        onKeyDown={handleKeyDown}
        type={'text'}
        value={code && code.key0}
        ref={inputRef0}
        data-key={0}
        hasError={hasError}
        isTransparent={isTransparent}
      />
      <StyledFormInputOneCharacter
        onChange={handleCode}
        onPaste={handlePaste}
        onFocus={handleFocus}
        onKeyDown={handleKeyDown}
        type={'text'}
        value={code && code.key1}
        ref={inputRef1}
        data-key={1}
        hasError={hasError}
        isTransparent={isTransparent}
      />
      <StyledFormInputOneCharacter
        onChange={handleCode}
        onPaste={handlePaste}
        onFocus={handleFocus}
        onKeyDown={handleKeyDown}
        type={'text'}
        value={code && code.key2}
        ref={inputRef2}
        data-key={2}
        hasError={hasError}
        isTransparent={isTransparent}
      />
      <StyledFormInputOneCharacter
        onChange={handleCode}
        onPaste={handlePaste}
        onFocus={handleFocus}
        onKeyDown={handleKeyDown}
        type={'text'}
        value={code && code.key3}
        ref={inputRef3}
        data-key={3}
        hasError={hasError}
        isTransparent={isTransparent}
      />
      <StyledFormInputOneCharacter
        onChange={handleCode}
        onPaste={handlePaste}
        onFocus={handleFocus}
        onKeyDown={handleKeyDown}
        type={'text'}
        value={code && code.key4}
        ref={inputRef4}
        data-key={4}
        hasError={hasError}
        isTransparent={isTransparent}
      />
      <StyledFormInputOneCharacter
        onChange={handleCode}
        onPaste={handlePaste}
        onFocus={handleFocus}
        onKeyDown={handleKeyDown}
        type={'text'}
        value={code && code.key5}
        ref={inputRef5}
        data-key={5}
        hasError={hasError}
        isTransparent={isTransparent}
      />
    </AccessCodeContainer>
  );
};

export default AccessCode;
