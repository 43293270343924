import React, { useState, useEffect, useCallback } from 'react';
import ExternalLoginButton from 'components/modals/AuthModal/ExternalLoginButton';
import Checkbox from 'components/ui/Checkbox';
import {
  exponentialBackoffWaitMsg,
  exponentialBackoffMessageWaitMs,
  exponentialBackoffTotalWaitTimeMs,
} from 'utils';
import isEmpty from 'lodash/isEmpty';
import { TITLE, SUBTITLE, BUTTON } from 'injection-classes';
import EmailInput from './EmailInput';
import ConfirmedEmailInput from './ConfirmedEmailInput';
import PasswordInput from './PasswordInput';
import ProfileNameInput from './ProfileNameInput';
import TermsAndPolicy from './TermsAndPolicy';
import {
  TitleWrapper,
  MarketingOptinContainer,
  InputsWrapper,
} from './styles';
import {
  MaestroLoginContainer,
  FormBody,
  ExternalAuthsWrapper,
  OrText,
  Label,
  RegisterLoginWaitingMessageContainer,
  RegisterLoginSpinnerContainer,
  RegisterLoginSpinner,
  ModalTitle,
  ModalDescription,
  FormSubmit,
} from '../commonStyles';
import { DynamicTranslationType, useEndUserTranslation } from 'hooks/use-translation';

interface ICustomModal {
  privacy: string;
  terms: string;
  text: string;
}

interface IRegisterViewProps {
  customModal: ICustomModal;
  email: string;
  enableMaestroLogin: boolean;
  id: string;
  marketingOptin: boolean;
  marketingOptinLabel: string;
  onRegister: (email: string, name: string, password: string, marketingOptin: boolean) => void;
  registerError: string | null;
  validAuths: string[];
}

const RegisterView: React.FC<IRegisterViewProps> = ({
  customModal,
  email,
  enableMaestroLogin,
  id,
  marketingOptin,
  marketingOptinLabel,
  onRegister,
  registerError,
  validAuths,
}) => {
  const { endUserT } = useEndUserTranslation();
  const [canSubmit, setCanSubmit] = useState(false);
  const [confirmedEmail, setConfirmedEmail] = useState('');
  const [errors, setErrors] = useState<string[]>([]);
  const [hasAcceptedTermsAndPolicy, setHasAcceptedTermsAndPolicy] = useState(false);
  const [marketingOptinValue, setMarketingOptinValue] = useState(false);
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [registering, setRegistering] = useState(false);
  const [registeringResetTimer, setRegisteringResetTimer] = useState<NodeJS.Timeout | null>(null);
  const [registeringWaitingMessage, setRegisteringWaitingMessage] = useState('');
  const [
    registeringWaitingMessageTimer,
    setRegisteringWaitingMessageTimer,
  ] = useState<NodeJS.Timeout | null>(null);

  const clearRegisterTimeouts = useCallback(() => {
    if (registeringResetTimer) {
      clearTimeout(registeringResetTimer);
    }
    if (registeringWaitingMessageTimer) {
      clearTimeout(registeringWaitingMessageTimer);
    }
  }, [registeringResetTimer, registeringWaitingMessageTimer]);

  const clearRegisterTimeoutsState = useCallback(() => {
    setRegistering(false);
    setRegisteringResetTimer(null);
    setRegisteringWaitingMessage('');
    setRegisteringWaitingMessageTimer(null);
  }, []);

  useEffect(() => {
    if (!registerError) return;

    clearRegisterTimeouts();
    clearRegisterTimeoutsState();
  }, [registerError, clearRegisterTimeouts, clearRegisterTimeoutsState]);

  useEffect(() => {
    if (!hasAcceptedTermsAndPolicy) {
      setCanSubmit(false);
      return;
    }

    if (errors.length > 0) {
      setCanSubmit(false);
      return;
    }

    if (isEmpty(name) || isEmpty(password) || isEmpty(confirmedEmail) || isEmpty(email)) {
      setCanSubmit(false);
      return;
    }

    setCanSubmit(true);
  }, [hasAcceptedTermsAndPolicy, name, password, confirmedEmail, email, errors.length]);

  useEffect(() => {
    return () => {
      clearRegisterTimeouts();
    };
  }, []);

  const startRegisterTimeouts = useCallback(() => {
    const newRegisteringResetTimer = setTimeout(() => {
      clearRegisterTimeouts();
    }, exponentialBackoffTotalWaitTimeMs);

    const newRegisteringWaitingMessageTimer = setTimeout(() => {
      setRegisteringWaitingMessage(exponentialBackoffWaitMsg);
    }, exponentialBackoffMessageWaitMs);

    setRegistering(true);
    setRegisteringResetTimer(newRegisteringResetTimer);
    setRegisteringWaitingMessageTimer(newRegisteringWaitingMessageTimer);
  }, [clearRegisterTimeouts]);

  const onUpdateFormErrors = useCallback(
    (input: string, hasErrors: boolean) => {
      if (hasErrors) {
        setErrors(prevErrors => [...prevErrors, input]);
      } else {
        const updatedErrors = errors.filter(errorIntput => errorIntput !== input);
        setErrors(updatedErrors);
      }
    },
    [errors],
  );

  const onOptinChange = useCallback(() => {
    setMarketingOptinValue(prevValue => !prevValue);
  }, []);


  const onSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      onRegister(email.toLowerCase(), name, password, marketingOptinValue);
      setCanSubmit(false);
      startRegisterTimeouts();
    },
    [email, name, onRegister, password, marketingOptinValue, startRegisterTimeouts],
  );

  return (
    <FormBody id={id} onSubmit={onSubmit}>
      {enableMaestroLogin && (
        <MaestroLoginContainer>
          <TitleWrapper>
            <ModalTitle className={TITLE} data-testid="authModalRegisterViewTitle">
              {endUserT(
                [DynamicTranslationType.createAccountTitle],
                ['CREATE_ACCOUNT'],
               )}
            </ModalTitle>
            <ModalDescription className={SUBTITLE} data-testid="authModalRegisterViewDescription">
              {
                customModal.text.trim() ||
                  endUserT(
                    [DynamicTranslationType.createAccountDescription],
                    ['CREATE_ACCOUNT_MESSAGE'],
                  )
              }
            </ModalDescription>
          </TitleWrapper>
          <InputsWrapper>
            <EmailInput email={email} />
            <ConfirmedEmailInput
              confirmedEmail={confirmedEmail}
              email={email}
              onConfirmedEmailChange={setConfirmedEmail}
              onUpdateErrors={onUpdateFormErrors}
            />
            <PasswordInput
              onPasswordChange={setPassword}
              onUpdateErrors={onUpdateFormErrors}
              password={password}
            />
            <ProfileNameInput
              onProfileChange={setName}
              onUpdateErrors={onUpdateFormErrors}
              profileName={name}
            />
          </InputsWrapper>
          <TermsAndPolicy
            customModal={customModal}
            setAcceptedTermsAndPolicy={setHasAcceptedTermsAndPolicy}
          />
          {marketingOptin && marketingOptinLabel && (
            <MarketingOptinContainer data-testid="authModalRegisterViewMarketingOptin">
              <Label>
                <Checkbox
                  checked={marketingOptinValue}
                  data-testid="authModalRegisterViewMarketingOptinCheckbox"
                  onChange={onOptinChange}
                  size={16}
                />
                <span onClick={onOptinChange}>{marketingOptinLabel}</span>
              </Label>
            </MarketingOptinContainer>
          )}
          <FormSubmit
            className={BUTTON}
            data-testid="authModalRegisterViewSubmitButton"
            disabled={!canSubmit}
            type="submit"
          >
            {!registering ? (
                endUserT(
                  [DynamicTranslationType.createAccountSignupButton],
                  ['SIGN_UP'],
                )
            ) : (
              <RegisterLoginSpinnerContainer>
                {registeringWaitingMessage && (
                  <RegisterLoginWaitingMessageContainer>
                    {registeringWaitingMessage}
                  </RegisterLoginWaitingMessageContainer>
                )}
                <RegisterLoginSpinner />
              </RegisterLoginSpinnerContainer>
            )}
          </FormSubmit>
          {
            validAuths.length > 1 && (
              <OrText>
                {endUserT(
                  [DynamicTranslationType.globalFormLabelOr],
                  ['OR'],
                )}
             </OrText>
            )
          }
        </MaestroLoginContainer>
      )}
      <ExternalAuthsWrapper data-testid="authModalRegisterViewExternalAuthsWrapper">
        {validAuths.map((kind) => (
          <ExternalLoginButton key={kind} kind={kind} signUp={true} />
        ))}
      </ExternalAuthsWrapper>
    </FormBody>
  );
};

export default RegisterView;
