import React, { useState, useEffect } from 'react';
import isEqual from 'lodash/isEqual';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import {
  getApiLoading,
  isPasswordEntryValid,
  getFixedGateData,
} from 'services/gate/selectors';
import { submitChannelPassword } from 'services/gate/actions';
import {
  SUBMIT,
  FORM_LABEL,
  FORM_INPUT,
  DATE_TIME,
} from 'injection-classes';
import { GATE_PASSWORD_ID } from 'global-ids';
import Spinner from 'components/ui/Spinner';
import {
  Container,
  Button,
  Form,
  StyledDateAndTime,
} from './styles';
import { DynamicTranslationType, useAdminTranslation, useEndUserTranslation } from 'hooks/use-translation';
import FormInput from 'components/ui/v2/Inputs';
import { SURFACE_4 } from 'style/constants';
import GateTitle from './GateTitle';
import GateSubtitle from './GateSubtitle';

const spinnerStyle = {
  width: '30px',
};

const InputWrapper = styled(FormInput.Root)`
  width: 100%;
  max-width: 350px;
`;

const FieldSet = styled(FormInput.FieldSet)`
  background-color: ${SURFACE_4};
`;

interface IPasswordGateAccessControlElements {
  passwordValue?: string | null;
}

export const PasswordGateAccessControlElements = ({
  passwordValue,
}: IPasswordGateAccessControlElements) => {
  const loading = useSelector(getApiLoading);
  const passwordValid = useSelector(isPasswordEntryValid);
  const [password, setPassword] = useState(passwordValue || '');

  const dispatch = useDispatch();

  const { endUserT } = useEndUserTranslation();

  function handleSubmit(event: React.FormEvent) {
    event.preventDefault();
    dispatch(submitChannelPassword(password));
  }

  useEffect(() => {
    if (passwordValue) {
      setPassword(passwordValue);
    }
  }, [passwordValue]);

  return (
    <Form onSubmit={handleSubmit} data-testid="passwordGateSection">
      <InputWrapper error={passwordValid === false && !loading}>
        <FieldSet>
          <FormInput.Legend className={FORM_LABEL}>
            {endUserT([DynamicTranslationType.globalPassword], ['PASSWORD'])}
          </FormInput.Legend>
          <FormInput.PasswordInput
            onChange={setPassword}
            value={password}
            className={FORM_INPUT}
          />
        </FieldSet>
        {passwordValid === false && !loading && (
          <FormInput.SupportingText>
            {endUserT([DynamicTranslationType.gateInvalidPassword], ['SORRY_WRONG_PASSWORD'])}
          </FormInput.SupportingText>
        )}
      </InputWrapper>
      <Button
        className={SUBMIT}
        type="submit"
        disabled={loading}
        hasError={passwordValid === false && !loading}
      >
        {
          loading
            ? <Spinner style={spinnerStyle} />
            : endUserT([DynamicTranslationType.globalActionSubmit], ['SUBMIT'])
        }
      </Button>
    </Form>
  );
};

const PasswordGate = () => {
  const { t } = useAdminTranslation();
  const { timestamp } = useSelector(getFixedGateData(t), isEqual);

  return (
    <Container id={GATE_PASSWORD_ID} data-testid="password-gate">
      {timestamp && (
        <StyledDateAndTime className={DATE_TIME} timeStamp={timestamp} />
      )}
      <GateTitle />
      <GateSubtitle />
      <PasswordGateAccessControlElements />
    </Container>
  );
};

export default PasswordGate;
