import { CloseButtonNoToolTip } from 'components/admin2/ui/Button';
import Separator from 'components/panels/Separator';
import { ADMIN_SURFACE_4, ADMIN_SURFACE_3, ADMIN_SURFACE_5, ADMIN_TEXT_200 } from 'style/constants';
import { ADMIN_TEXT_LABEL_S_BOLD } from 'style/design-system/textStyles';
import { ADMIN_THIN_SCROLL_BAR } from 'style/mixins';
import styled from 'styled-components';
import Catalog from './Catalog';
import BackButton from 'components/admin2/NavControls/BackButton';

export const PANEL_HEADER_HEIGHT = 52;
export const PANEL_OFFSET = 175;

export const PanelsWrapper = styled.div<{ state: string }>`
  position: absolute;
  top: 0;
  bottom: 0;
  right: ${({ state }) => state === 'entered' ? '0px' : '-500px'};
  transition: 0.5s;
  width: 500px;
  height: 100vh;
  background: ${ADMIN_SURFACE_4};
`;

export const PanelHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 9px 10px 9px 20px;
  height: ${PANEL_HEADER_HEIGHT}px;
  background: ${ADMIN_SURFACE_3};
  border-bottom: 1px solid ${ADMIN_SURFACE_5};
`;

export const HeaderTitle = styled.p`
  color: ${ADMIN_TEXT_200};
  ${ADMIN_TEXT_LABEL_S_BOLD};
`;

export const HeaderActionsWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  gap: 9px;
`;

export const CloseButton = styled(CloseButtonNoToolTip)`
  background: transparent;
  width: 14px;
  height: 14px;
  cursor: pointer;
  &:hover {
    background: transparent;
  }
`;

export const PanelBody = styled.div``;

export const PanelCatalogWrapper  = styled.div`
  margin: 20px;
`;

export const StyledSeparator = styled(Separator)`
  margin: 20px 0px;
`;

export const CardCatalog = styled(Catalog)`
  max-height: 407px;
`;

export const PanelCatalog = styled(Catalog)<{ cardOffset: number }>`
  & .lists {
    max-height: calc(100vh - ${({ cardOffset }) => cardOffset }px);
    overflow: hidden auto;
    ${ADMIN_THIN_SCROLL_BAR}
  }
`;

export const PanelEditorWrapper = styled.div`
  min-height: calc(100vh - 150px);
  max-height: calc(100vh - 100px);
  overflow: hidden auto;
  ${ADMIN_THIN_SCROLL_BAR}
`;

export const StyledBackButton = styled(BackButton)`
  cursor: pointer;
`;
