import {
  DynamicTranslationType,
  TParamsForAdminKey,
  TTranslationParams,
  TTranslatorResult,
  TranslationKey,
  useAdminTranslation,
} from 'hooks/use-translation';
import React, { forwardRef, useCallback } from 'react';
import {
  useEndUserTranslation,
} from 'hooks/use-translation/endUser';
import { useSelector } from 'react-redux';
import { isEditMode } from 'services/admin';
import { WithExtraProps } from 'utils';

export type TEndUserConditionalTranslator = <
  TDynamicTranslationType extends DynamicTranslationType,
  TAdminTranslationKey extends TranslationKey | undefined
>(
  endUserArgs: [
    TDynamicTranslationType,
    ...(
      TTranslationParams<TDynamicTranslationType> extends undefined
        ? []
        : [TTranslationParams<TDynamicTranslationType>]
      )
  ],
  adminFallback: undefined | [
      TAdminTranslationKey,
      ...(
        TParamsForAdminKey<NonNullable<TAdminTranslationKey>> extends never
          ? [never?]
          : [TParamsForAdminKey<NonNullable<TAdminTranslationKey>>]
      )
  ],
  isEditing?: boolean,
  value?: string,
) => TAdminTranslationKey extends undefined
  ? string
  : TTranslatorResult<NonNullable<TAdminTranslationKey>>;

  export const useEndUserTranslationWithConditionalLocalization = () => {
    const { endUserT: originalEndUserT } = useEndUserTranslation();
    const { t: adminT } = useAdminTranslation();

    const endUserT = useCallback(
      ((endUserArgs, adminFallback, isEditing = false, value?: string) => {
        // Fallback when admin is editing
        if (isEditing) {
          const fallback = adminFallback?.[0]
            ? adminT(adminFallback[0], adminFallback[1])
            : '';
          return value ?? fallback;
        }

        if (adminFallback === undefined) {
          return originalEndUserT(endUserArgs) || '';
        }

        return originalEndUserT(endUserArgs, adminFallback);
      }) as TEndUserConditionalTranslator,
      [originalEndUserT, adminT],
    );

    return { endUserT };
  };

/**
 * When its needed to localize a class component, use this function to wrap it.
 */
export function withEndUserTranslationWithConditionalLocalization<T>(
  component: T,
) {
  return forwardRef((props, ref) => {
    const { endUserT } = useEndUserTranslationWithConditionalLocalization();
    const Component = component as any;

    return <Component ref={ref} endUserT={endUserT} {...props} />;
  }) as WithExtraProps<T, { endUserT: never }>;
}
