import React from 'react';
import { Description, StyledButton, StyledFormBody } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { useAdminTranslation } from 'hooks/use-translation';
import { getAdminSites, getGlobalAccount } from 'services/auth';
import { redirectGlobalAccountToSite } from 'services/auth/actions';
import { MaestroLoginContainer, ModalTitle } from '../commonStyles';
import { TITLE } from 'injection-classes';
import { EmailIcon, EmailIconWrapper, EmailLockedText, LockedEmailInput } from '../LoginView/styles';
import AdminSites from './AdminSites';

const SelectSiteModal: React.FC = () => {
  const account = useSelector(getGlobalAccount);
  const { t } = useAdminTranslation();
  const dispatch = useDispatch();
  const adminSites = useSelector(getAdminSites);
  const [selectedSite, setSelectedSite] = React.useState(adminSites[0]);

  const handleSubmit = React.useCallback((e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(redirectGlobalAccountToSite({
      siteId: selectedSite.siteId,
      siteSlug: selectedSite.slug,
    }));
  }, [selectedSite]);

  if (!account) {
    return null;
  }

  const userName = (account.name || '').replace(/ .*$/gi, '');

  return (
    <StyledFormBody onSubmit={handleSubmit} id="SelectSiteModal">
      <MaestroLoginContainer>
        <ModalTitle className={TITLE} data-testid="selectGlobalAccountSitesViewTitle">
          {t('LOGIN_WELCOME_BACK', { userName })}
        </ModalTitle>
        <LockedEmailInput>
          <EmailIconWrapper>
            <EmailIcon />
          </EmailIconWrapper>
          <EmailLockedText data-testid="selectGlobalAccountSitesViewLockedEmailText">
            {account.email}
          </EmailLockedText>
        </LockedEmailInput>
      </MaestroLoginContainer>
      <Description>{t('CHOOSE_SITE')}</Description>
      <AdminSites onChange={setSelectedSite} selectedSite={selectedSite} sites={adminSites} />
      <StyledButton type="submit">{t('GO_TO_SITE')}</StyledButton>
    </StyledFormBody>
  );
};

export default SelectSiteModal;
