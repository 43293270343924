import styled from 'styled-components';

import {
  SURFACE_3,
  TEXT_100,
  TEXT_200,
} from 'style/constants';

export const Container = styled.div`
  max-width: 400px;
  width: 100%;
  margin-bottom: 20px;

  background: ${(props) => SURFACE_3(props)};
  padding: 20px;
  border-radius: 10px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
`;

export const NameAndDescription = styled.div``;

export const Name = styled.strong`
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 15px;
  color: ${TEXT_100};
`;

export const Description = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: ${TEXT_200};
  word-break: break-word;
  margin-bottom: 20px;
`;
