import { connect } from 'react-redux';
import { trackAd } from 'services/insights';

import TazerDetection from './TazerDetection';

const mapDispatchToProps = dispatch => ({
  onTazeDetection: detected => dispatch(
    trackAd(detected ? 'enabled' : 'disabled', { kind: 'blocker' }),
  ),
});

export default connect(null, mapDispatchToProps)(TazerDetection);
