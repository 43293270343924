/* eslint react/destructuring-assignment: off */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { msIn } from 'shared/datetime-utils';

const UPDATE_INTERVAL = msIn.minute;

// TODO: use actual i18n lib and remove moment
export default class RelativeTimestamp extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    currentLocale: PropTypes.string,
    dataTestId: PropTypes.string,
    relativeTo: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.instanceOf(Date),
    ]),
    target: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.instanceOf(Date),
    ]).isRequired,
  };

  static defaultProps = {
    className: '',
    currentLocale: 'en',
    dataTestId: '',
    relativeTo: undefined,
  };

  state = {
    diffText: this.computeDiffText(),
  };

  componentDidMount() {
    // eslint-disable-next-line
    this.setState({
      diffText: this.computeDiffText(),
    });
    this.timer = setInterval(() => {
      this.setState({
        diffText: this.computeDiffText(),
      });
    }, UPDATE_INTERVAL);
  }

  // TODO: Evaluate if this is necessary
  /* eslint-disable react/no-did-update-set-state */
  componentDidUpdate(prevProps) {
    const shouldRestartInterval = (
      prevProps.relativeTo !== this.props.relativeTo ||
      prevProps.currentLocale !== this.props.currentLocale
    );

    if (shouldRestartInterval) {
      clearInterval(this.timer);
      this.setState({
        diffText: this.computeDiffText(),
      });
      this.timer = setInterval(() => {
        this.setState({
          diffText: this.computeDiffText(),
        });
      }, UPDATE_INTERVAL);
    }
  }
  /* eslint-enable */

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  computeDiffText() {
    const { relativeTo = Date.now(), target, currentLocale } = this.props;
    return moment(target).locale(currentLocale).from(moment(relativeTo));
  }

  render() {
    const { className, dataTestId } = this.props;
    const { diffText } = this.state;
    return (
      <div className={className} data-testid={dataTestId}>
        <span>{diffText}</span>
      </div>
    );
  }
}
