import Icon from 'components/ui/Icon';
import {
  ACCENT_PRIMARY,
  SPACING_X_SMALL,
  SURFACE_1,
  SURFACE_3,
  SURFACE_4,
  TEXT_100,
} from 'style/constants';
import styled, { css } from 'styled-components';
import { DefaultTheme } from 'styled-components';
import { ThemedStyledProps } from 'styled-components';

export const MOBILE_TAB_BAR_HEIGHT = 54;

export const TabWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: ${MOBILE_TAB_BAR_HEIGHT}px;
  background: ${SURFACE_1};
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  border-top: 1px solid ${SURFACE_4};
  z-index: 1;
`;

export const ProfileIcon = styled(Icon).attrs((props: iconProps) => ({
  color: props.isActive ? ACCENT_PRIMARY(props) : TEXT_100(props),
  name: 'mobileProfileFilled',
}))<iconProps>`
  & svg {
    width: 28px;
    height: 28px;
    & path {
      fill: ${(props) => (props.isActive ? ACCENT_PRIMARY(props) : 'none')};
      ${(props) =>
        props.isActive
          ? 'stroke: none;'
          : css`
              stroke: ${TEXT_100(props)}};
              stroke-width: 2px;
            `}
    }
  }
`;

export const HomeIcon = styled(Icon).attrs((props: iconProps) => ({
  color: props.isActive ? ACCENT_PRIMARY(props) : TEXT_100(props),
  name: 'mobileHomeOutline',
}))<iconProps>`
  & svg {
    width: 28px;
    height: 28px;
    & path {
      stroke: ${(props: iconProps) =>
        props.isActive ? ACCENT_PRIMARY(props) : TEXT_100(props)};
      fill: ${(props) => (props.isActive ? ACCENT_PRIMARY(props) : undefined)};
    }
  }
`;

type iconProps = ThemedStyledProps<{ isActive?: boolean }, DefaultTheme>;

export const SearchIcon = styled(Icon).attrs((props: iconProps) => ({
  name: props.isActive ? 'closeThin' : 'mobileSearch',
}))`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  & svg {
    width: ${(props: iconProps) => (props.isActive ? '16px' : '28px')};
    height: ${(props) => (props.isActive ? '16px' : '28px')};
    & path {
      fill: ${(props) =>
        props.isActive ? ACCENT_PRIMARY(props) : TEXT_100(props)};
    }
  }
`;

export const TabButtonWrapper = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  -webkit-tap-highlight-color: transparent;
`;

export const ProfilePicture = styled.div<{ isActive?: boolean; src: string }>`
  background: url(${(props) => props.src});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 28px;
  height: 28px;
  width: 28px;
  margin-left: ${SPACING_X_SMALL};
  position: relative;
  border: ${(props) =>
    props.isActive ? `2px solid ${ACCENT_PRIMARY(props)}` : 'none'};
`;
