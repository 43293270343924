// tslint:disable:jsx-boolean-value
import React, { ComponentType } from 'react';
import RealtimePage from 'components/core/RealtimePage';
import { IObject } from 'models';
import { ChannelPageProps, ScrollNavChanelPageProps } from './interfaces';
import { ArrowIcon, ChannelSelectItem, ChannelSelectWrapper } from './styles';

function ChannelPage({
  currentChannelId,
  goToNextPage,
  goToPrevPage,
  isCurrentPage,
  isFirstPage,
  isLastPage,
  isNextPage,
  isPrevPage,
  onEngage,
  pageContent: { image, page },
}: ChannelPageProps & ScrollNavChanelPageProps) {
  return (
    <RealtimePage id={page._id}>
      {(doc?: IObject) => {
        if (!doc) {
          return null;
        }
        const  engage = () => onEngage(doc, { action: 'select_channel', channel: page._id });
        return (
          <ChannelSelectWrapper isNextPage={isNextPage} isPreviousPage={isPrevPage}>
            <ChannelSelectItem
              active={page._id === currentChannelId}
              disabled={!isCurrentPage}
              image={image}
              legacyImage={image}
              onClick={engage}
              page={doc}
            />
            {isCurrentPage ? (
              <>
                {isFirstPage ?  null : <ArrowIcon onClick={goToPrevPage} />}
                {isLastPage ? null : <ArrowIcon onClick={goToNextPage} right />}
              </>
            ) : null}
          </ChannelSelectWrapper>
        );
      }}
    </RealtimePage>
  );
}

export default ChannelPage as ComponentType<ChannelPageProps>;
