import Button from 'components/ui/Button';
import { TEXT_300 } from 'style/constants';
import { TEXT_BODY_S_REGULAR } from 'style/design-system/textStyles';
import { SINGLE_LINE_ELLIPSIS, desktopOnly, mobileOnly } from 'style/mixins';
import styled from 'styled-components';
import { FormBody } from '../commonStyles';

export const Description = styled.h3`
  all: unset;
  ${TEXT_BODY_S_REGULAR}
  color: ${TEXT_300};
`;

export const StyledButton = styled(Button)`
  height: 44px;
  width: 100%;
`;

export const AdminSitesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-y: auto;
  margin-bottom: 20px;

  ${mobileOnly`max-height: calc(100vh - 300px);`}
  ${desktopOnly`max-height: calc(100vh - 500px);`}
`;

export const StyledFormBody = styled(FormBody)`
  gap: 10px;
`;

export const Slug = styled.span`
  flex: 1;
  ${SINGLE_LINE_ELLIPSIS}
`;
