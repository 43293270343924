/* eslint react/destructuring-assignment: off */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import qs from 'qs';
import Link from 'components/ui/Link';
import { STATIC_BASE_URL, UBISOFT_APP_ID, UBISOFT_GENOME_ID } from 'config';
import { getUbisoftNextUrl } from 'services/auth';
import BaseLoginButton from '../BaseLoginButton';

export const ImgIcon = styled.img`
  height: 22px;
  width: 22px;
`;

const createUplayUri = () => {
  const base = 'https://connect.ubisoft.com/login';

  const params = {
    appId: UBISOFT_APP_ID,
    genomeId: UBISOFT_GENOME_ID,
    lang: 'en-US',
    nextUrl: getUbisoftNextUrl(),
  };
  return `${base}?${qs.stringify(params)}`;
};

export default class UplayLoginButton extends React.Component {
  static propTypes = {
    signUp: PropTypes.bool.isRequired,
  };

  state = {
    redirectUri: createUplayUri(),
  };

  render() {
    const { redirectUri } = this.state;
    const { signUp } = this.props;
    return (
      <Link
        onClick={() => {
          window.open(redirectUri, 'width=480,height=720,toolbar=no,status=no');
        }}
        plain
      >
        <BaseLoginButton
          color="#3f7ec3"
          dataTestId="authModalUplayLoginButton"
          providerNameKey="UBISOFT"
          renderIcon={
            () => <ImgIcon src={`${STATIC_BASE_URL}/media/555f5b1d99b95b1255b8a350/5914ca9e12c6ab504141443a.png`} />
          }
          signUp={signUp}
        />
      </Link>
    );
  }
}
