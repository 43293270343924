import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'hooks';

import { logIn, OauthProvider } from 'services/auth';
import Link from 'components/ui/Link';
import BaseLoginButton from './BaseLoginButton';
import { MAESTRO_WHITE } from 'style/constants';

export const ImgIcon = styled.img`
  height: 22px;
  width: 22px;
`;

interface IProps {
  signUp: boolean;
}

// unfinished, unactive and untested oauth service
const BattlenetLoginButton = ({ signUp }: IProps) => {
  const dispatch = useDispatch();
  const triggerLogin = () => {
    dispatch(logIn({ provider: OauthProvider.BATTLENET, strategy: 'oauth' }));
  };

  return (
    <Link onClick={triggerLogin} target="_self">
      <BaseLoginButton
        color={MAESTRO_WHITE}
        dataTestId="authModalBattlenetLoginButton"
        providerNameKey="BATTLENET"
        // tslint:disable-next-line: jsx-no-lambda no-empty
        renderIcon={() => 'B'} // TODO: render battlenet icon
        signUp={signUp}
      />
    </Link>
  );
};

export default BattlenetLoginButton;
