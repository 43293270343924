import styled, { css } from 'styled-components';
import Icon from 'components/ui/Icon';
import ChannelSelector from 'components/ui/ChannelSelector';
import { BACKGROUND_ACTIVE, BACKGROUND_CONTENT, FLEX_CENTER } from 'style/mixins';
import { lightenDarken } from 'colors';
import { TEXT_100, HIGHLIGHT_PRIMARY, SURFACE_3, SURFACE_5, SURFACE_4 } from 'style/constants';

export interface StyleProps {
  disabled: boolean;
}

export const Wrapper = styled.div`
  ${FLEX_CENTER}
  width: 100%;
  height: 56.25vw;
  z-index: 1;
  background: ${props => lightenDarken({
    hex: SURFACE_3(props),
    maxBrightness: 0.1,
    percentage: 0.1,
  })};
  & > * {
    width: 100%;
  }
`;

export const ChannelSelectWrapper = styled.div<{isNextPage: boolean, isPreviousPage: boolean}>`
  position: relative;
  padding: 10px 0;
`;

export const ChannelSelectItem = styled(ChannelSelector)<StyleProps>`
  ${({ disabled }) => disabled && `
    pointer-events: none;
    opacity: 0.5;
  `}
`;

export const ArrowIcon = styled(Icon).attrs(({ right }: { right?: boolean }) => ({
  name: right ? 'arrowRight' : 'arrowLeft',
}))<{right?: boolean}>`
  ${FLEX_CENTER}
  position: absolute;
  height: 100%;
  padding: 0 10px;
  top: 0;
  z-index: 999;
  cursor: pointer;
  ${({ right }) => right ? css`
    right: 0;
  ` : css`
    left: 0;
  `}
  & svg {
    width: 10px;
    height: 18px;
  }
`;

export const ChannelSelectButtonWrapper = styled.div<{center?: boolean, padding?: string}>`
  background: ${SURFACE_4};
  ${FLEX_CENTER}
  ${({ padding }) => padding && css`padding: ${padding};`}
  ${({ center }) => center ? css`
    flex: 1;
  ` : css`
    border-right: 0.5px solid ${SURFACE_5};
  `}
`;

export const ChannelSelectButton = styled(Icon).attrs((props) => ({
  color: (props as any).isActive ? TEXT_100(props) : HIGHLIGHT_PRIMARY(props),
  name: 'channelSelector',
}))<{isActive?: boolean}>`
  ${FLEX_CENTER}
  ${({ isActive }) => isActive ? BACKGROUND_ACTIVE : BACKGROUND_CONTENT}
  border-radius: 5px;
  width: 48px;
  height: 34px;
  cursor: pointer;
`;
