import { connect } from 'react-redux';

import withErrorGuard from 'components/core/withErrorGuard';
import {
  channelSelectEnabled,
  getChannelSelectPages,
  getPageType,
  isChannelSelectorSidebarButtonVisible,
  getShowMobileChannelSelector,
} from 'services/app/selectors';
import {
  getCustomPanels,
} from 'services/custom-panels/selectors';
import { pageTypes } from 'services/app/constants';
import { shouldRenderChannelGate } from 'services/gate/selectors';
import { getVodIdFromQuery } from 'services/video';
import { isLandscape, isMobileLayout } from 'services/device';
import { isEmbed } from 'services/user-layout';
import { isWelcomeScreenActive } from 'services/welcome-screen';
import MobileBody from './view';

const mapStateToProps = state => ({
  channelSelectEnabled: Boolean(
    channelSelectEnabled(state) &&
    getChannelSelectPages(state)?.length,
  ),
  customPanels: getCustomPanels(state),
  isChannelGateOn: shouldRenderChannelGate(state),
  isChannelSelectorSidebarButtonVisible: isChannelSelectorSidebarButtonVisible(state),
  isEmbed: isEmbed(state),
  isLandingPage: getPageType(state) === pageTypes.LANDING,
  isLandscape: isLandscape(state),
  isMobileLayout: isMobileLayout(state),
  isWelcomeScreenActive: isWelcomeScreenActive(state),
  showMobileChannelSelector: getShowMobileChannelSelector(state),
  vodQueryParam: getVodIdFromQuery(state),
});

export default withErrorGuard()(
  connect(mapStateToProps)(MobileBody),
);
