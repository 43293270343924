import { connect } from 'react-redux';
import { getPageSlug, getRedirectUrl } from 'services/app/selectors';
import ExternalLoginButton from './view';

const mapStateToProps = state => ({
  redirectUrl: getRedirectUrl(state),
  slug: getPageSlug(state),
});

export default connect(mapStateToProps)(ExternalLoginButton);
