import React from 'react';
import { ClaimedIcon, CompletedIcon, Dot, FailedIcon, Pill, ProgressText, RedDot, YellowDot } from './styles';
import useQuestLifespan from 'hooks/use-quest-lifespan';
import { useAdminTranslation } from 'hooks/use-translation';

type QuestProgressBadgeProps = {
  available: boolean;
  claimed: boolean;
  completed: boolean;
  expired: boolean;
  failed: boolean;
  inProgress: boolean;
  timeMaximum?: number | null;
  timeMinimum?: number | null;
};

const QuestProgressBadge = ({ failed, available, inProgress, claimed, expired, timeMinimum, completed, timeMaximum }: QuestProgressBadgeProps) => {
  const { t } = useAdminTranslation();
  const { availableIn, expiredIn } = useQuestLifespan(timeMinimum, timeMaximum);

  const getIcon = () => {
    if (claimed) {
      return <ClaimedIcon />;
    }

    if (completed) {
      return <CompletedIcon />;
    }

    if (!available) {
      return <YellowDot />;
    }

    if (expired) {
      return <RedDot />;
    }

    if (failed) {
      return <FailedIcon />;
    }

    if (inProgress) {
      return <Dot />;
    }

    return null;
  };

  const getText = () => {
    if (claimed) {
      return t('CLAIMED');
    }

    if (completed) {
      return t('ADMIN_LABEL_COMPLETED');
    }

    if (!available) {
      return availableIn;
    }

    if (expired) {
      return expiredIn;
    }

    if (failed) {
      return t('FAILED');
    }

    if (inProgress) {
      return t('IN_PROGRESS');
    }

    return '';
  };

  const text = getText();
  if (!text) {
    return null;
  }

  return (
    <Pill>
      {getIcon()}
      <ProgressText>{text}</ProgressText>
    </Pill>
  );
};

export default QuestProgressBadge;
