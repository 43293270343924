import styled from 'styled-components';
import Icon from 'components/ui/Icon';
import { ACCENT_PRIMARY, HIGHLIGHT_PRIMARY, TEXT_200 } from 'style/constants';
import { TEXT_LABEL_M_MEDIUM } from 'style/design-system/textStyles';

export const ForgotPasswordButton = styled.button`
  ${TEXT_LABEL_M_MEDIUM}
  color: ${ACCENT_PRIMARY};
`;

export const LockedEmailInput = styled.div`
  display: flex;
  align-items: center;
  border: 0.5px solid ${HIGHLIGHT_PRIMARY};
  border-radius: 50px;
  box-sizing: border-box;
  padding: 10px 15px 10px 21px;
  margin-bottom: 10px;
`;

export const EmailIconWrapper = styled.div`
  margin-right: 12px;
`;

export const EmailIcon = styled(Icon).attrs(() => ({
  name: 'mail',
}))`
  & > svg {
    width: 18px;
    height: 18px;
    & > path {
      fill: ${HIGHLIGHT_PRIMARY};
    }
  }
`;

export const EmailLockedText = styled.div`
  ${TEXT_LABEL_M_MEDIUM};
  color: ${TEXT_200};
`;

export const ForgotPasswordContainer = styled.div`
  margin-top: 4px;
`;
