import INavigation from 'models/INavigation';
import React, { useCallback, useMemo } from 'react';
import {
  NavChevron,
  PrimaryLinkText,
  PrimaryNavLinkContainer,
  SecondaryLinkText,
  SecondaryNavLinkContainer,
} from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { replace } from 'services/app-router';
import { getSearchText } from 'services/navigationv2';

interface IParentNavigationProps {
  isLink?: boolean;
  onDismiss?: () => void;
  parent: INavigation['parents'][0];
}

const ParentNavigation: React.FC<IParentNavigationProps> = ({
  parent,
  onDismiss,
  isLink,
}) => {
  const dispatch = useDispatch();
  const isFolder = parent.type === 'folder';
  const href = isFolder ? undefined : parent.slug;
  const searchValue = useSelector(getSearchText);
  const isParentNameMatchingSearch = useMemo(
    () =>
      Boolean(
        parent.name.toLowerCase().match(new RegExp(searchValue.toLowerCase())),
      ),
    [parent.name, searchValue],
  );

  const navigate = useCallback(
    (path?: string) => {
      if (!path) return;
      if (onDismiss) onDismiss();
      if (isLink) {
        window.open(path, '_blank');
        return;
      } else {
        dispatch(replace({ path: `/${path}` }));
      }
      dispatch(replace({ path: `/${path}` }));
    },
    [dispatch, onDismiss, isLink],
  );

  const navigateToParent = useCallback(() => {
    navigate(href);
  }, [href, navigate]);

  const renderChildNavigation = useCallback(() => {
    if (isLink) return null;
    return parent.children.map((child, index) => {
      const navigateToChild = () => navigate(`${parent.slug}/${child.slug}`);
      const isChildNameMatchingSearch = Boolean(
        child.name.toLowerCase().match(new RegExp(searchValue.toLowerCase())),
      );
      return (
        <SecondaryNavLinkContainer
          key={`${child.id}-${index}`}
          onClick={navigateToChild}
          data-testid={
            isChildNameMatchingSearch
              ? 'mobile-child-navigation-item-matched'
              : 'mobile-child-navigation-item'
          }
        >
          <SecondaryLinkText
            isHighlighted={child.name.toLowerCase().includes(searchValue)}
          >
            {child.name}
          </SecondaryLinkText>
          <NavChevron />
        </SecondaryNavLinkContainer>
      );
    });
  }, [parent.children, navigate, searchValue, isLink]);

  return (
    <>
      <PrimaryNavLinkContainer
        onClick={navigateToParent}
        data-testid={
          isParentNameMatchingSearch
            ? 'mobile-parent-navigation-item-matched'
            : 'mobile-parent-navigation-item'
        }
      >
        <PrimaryLinkText>{parent.name}</PrimaryLinkText>
        {!isFolder && <NavChevron />}
      </PrimaryNavLinkContainer>
      {renderChildNavigation()}
    </>
  );
};

export default ParentNavigation;
