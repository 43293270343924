import React, { FC, useEffect } from 'react';
import ContentLayout from 'components/regions/ContentLayout';
import Video from 'components/Video/video-object';
import VideoMetadata from 'components/Video/video-object/VideoMetadata';
import { useCanIPlay } from 'services/caniplay/useCanIPlay';
import { PlayerSpot } from 'services/video';
import { usePageEditorContext } from 'contexts/PageEditorContext';
import { useDispatch, useSelector } from 'react-redux';
import { isLandingPageType } from 'services/app';
import { setOrientationLocked } from 'services/user-layout/actions';
import {
  usePlaylist,
  usePlaylistOrchestration,
  useTagPlaylist,
} from 'services/playlist/usePlaylist';
import { useSetShareVideoId } from 'contexts/GlobalVideoContext';
import { getIsOrientationLocked } from 'services/user-layout/selectors/common';

const VideoPage: FC<{
  playlistId: string | null;
  playlistTag: string | null;
  videoId: string | null;
}> = ({ videoId, playlistId, playlistTag }) => {
  const singleVideoResult = useCanIPlay(playlistId ? undefined : videoId);

  const playlist = usePlaylist(playlistTag ? undefined : playlistId);
  const tagPlaylist = useTagPlaylist(playlistTag);
  const [playlistVideoResult, playlistPlayNext] = usePlaylistOrchestration(
    tagPlaylist ?? playlist,
    videoId,
  );

  const isLanding = useSelector(isLandingPageType);
  const isOrientationLocked = useSelector(getIsOrientationLocked);
  const dispatch = useDispatch();

  const { setEditableVideo } = usePageEditorContext();

  if (isOrientationLocked) {
    dispatch(setOrientationLocked(false));
  }

  useEffect(() => {
    if (isLanding) {
      setEditableVideo(singleVideoResult?.video);
    }
  }, [singleVideoResult?.video, isLanding]);

  useEffect(() => {
    return () => {
      setEditableVideo(undefined);
    };
  }, []);

  const currentVideo = singleVideoResult?.video ?? playlistVideoResult?.video;

  useSetShareVideoId(currentVideo?._id);

  if (!currentVideo) {
    return null;
  }

  const playerSpot = PlayerSpot.video;

  return (
    <ContentLayout>
      <Video
        video={currentVideo}
        scheduledVideoStartTime={null}
        spot={playerSpot}
        onVideoComplete={playlistPlayNext}
      />
      <VideoMetadata spot={playerSpot} video={currentVideo} />
    </ContentLayout>
  );
};

export default VideoPage;
