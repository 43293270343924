import React from 'react';
import styled from 'styled-components';
import KeyedListMapper from 'components/core/KeyedListMapper';
import Quest from 'components/objects/Quest/Quest';
import { PanelTitle } from 'components/objects/Panel/styles';

interface QuestPreviewPanelProps {
  renderer: IRenderer;
}

interface IRenderer {
  panelName: string;
  quests: IPreviewQuest[];
}

interface IPreviewQuest {
  data: IQuestData
  progress?: IProgress
}

interface IQuestData {
  _id: string;
  actions?: IQuestActions[]
  claimedMessage: string;
  created: number;
  description: string;
  modified: number;
  rewardDescription: string;
  rewardType: string;
  siteId: string;
  title: string;
  webhookId?: string;
  webhookType?: string;
}

interface IQuestActions {
  arrayId: string;
  body: string;
  kind: string;
  valueRequired: number;
}

interface IProgressActions {
  [key: string]: {
    completed?: boolean;
    progress: number;
  }
}

interface IProgress {
  actions: IProgressActions;
  claimed?: boolean;
  completed?: boolean;
}

/* tslint:disable:no-empty */
const NO_OP = () => {};

const Container = styled.div`
  padding: 10px 12px 10px 13px;
`;

const QuestPreviewPanel: React.FC<QuestPreviewPanelProps> = ({ renderer }) => {
  const { quests, panelName } = renderer;

  return (
    <>
      <PanelTitle>{panelName}</PanelTitle>
      <Container>
        <KeyedListMapper
          keyField="_id"
          list={quests}
        >
          {(key, questDoc) => (
            <Quest
              key={key}
              claiming={false}
              claimQuest={NO_OP}
              data={questDoc.data}
              hidden={false}
              id=""
              onAdd={NO_OP}
              onRemove={NO_OP}
              progress={questDoc.progress}
              questEngage={NO_OP}
              refetchClaimStatus={NO_OP}
              showProfilePanel={NO_OP}
              showReward={NO_OP}
            />
          )}
        </KeyedListMapper>
      </Container>
    </>
  );
};

export default QuestPreviewPanel;
