import React from 'react';
import { TITLE } from 'injection-classes';
import { useSelector } from 'react-redux';
import { useAdminTranslation } from 'hooks/use-translation';
import { GATE_TITLE_MAX_LENGTH } from 'shared/constants';
import { getIsRichTextEditorV3Enabled } from 'services/feature-gate/selectors';
import GateTextSection from 'components/admin2/AccessGate/SharedComponents/GateTextSection';
import { isAccessControlModeActive } from 'services/admin/selectors';
import useEditGate from 'hooks/use-edit-gate';
import { PREDEFINED_TOOLBAR_BUTTONS } from 'components/ui/RichTextEditorV2/utils';

const GateTitle = () => {
  const { t } = useAdminTranslation();
  const isRichTextEditorV3Enabled = useSelector(getIsRichTextEditorV3Enabled);
  const isAccessGate = useSelector(isAccessControlModeActive);
  const { setGateTitleChanges, editingGateInformation } = useEditGate();

  const titleData = isRichTextEditorV3Enabled ? editingGateInformation.titleRawDataV2 : editingGateInformation.titleRawData;
  const handleTitleChange = (styledTitle: string) => {
    if (isAccessGate) {
      setGateTitleChanges(styledTitle);
    }
  };

  return (
    <GateTextSection
      codeInjectionClassName={TITLE}
      isAccessControlMode={isAccessGate}
      onChange={handleTitleChange}
      data={titleData}
      sectionTitle={t('ACCESS_GATE_TITLE_REGION_TITLE')}
      type="title"
      maxLength={GATE_TITLE_MAX_LENGTH}
      toolbarButtons={PREDEFINED_TOOLBAR_BUTTONS.titleEditor}
      useRawDataV2={isRichTextEditorV3Enabled}
    />
  );
};

export default GateTitle;
