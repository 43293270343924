import React from 'react';
import { useSelector } from 'react-redux';
import { HomeIcon, TabButtonWrapper, SearchIcon, ProfileIcon, ProfilePicture } from './styles';
import { isLoggedIn } from 'services/auth';
import { getUserAvatar } from 'services/user-profile';
import FloatingCartButton from 'components/Ecommerce/Cart/FloatingCartButton';

const TabButton = ({ mobileMenu, onTap, isActive }) => {
  const isUserLoggedIn = useSelector(isLoggedIn);
  const userAvatar = useSelector(getUserAvatar);

  const renderIcon = () => {
    switch (mobileMenu) {
      case 'searchMenu':
        return <SearchIcon isActive={isActive} />;
      case 'profileMenu':
        return isUserLoggedIn ? (
          <ProfilePicture src={userAvatar!} isActive={isActive} />
        ) : (
          <ProfileIcon isActive={isActive} />
        );
      case 'home':
        return <HomeIcon isActive={isActive} />;
      default:
        return null;
    }
  };

  if (mobileMenu === 'cartMenu') {
    return (
      <TabButtonWrapper onClick={onTap}>
        <FloatingCartButton mobile={true} />
      </TabButtonWrapper>
    );
  }

  return <TabButtonWrapper onClick={onTap}>{renderIcon()}</TabButtonWrapper>;
};

export default TabButton;
