import React, { useState, useEffect } from 'react';
import isEqual from 'lodash/isEqual';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { loginModal } from 'services/modals';
import TranslatedText from 'components/i18n/TranslatedText';
import { GATE_LOGIN_ID } from 'global-ids';
import {
  DATE_TIME,
  SUBMIT,
  FORM_LABEL,
  FORM_INPUT,
} from 'injection-classes';
import { isValidEmail } from 'utils';
import { getAccountFound } from 'services/app/selectors';
import { getFixedGateData } from 'services/gate/selectors';
import { getAuths } from 'services/app/selectors';
import { accountLookup } from 'services/auth';
import {
  Container,
  Button,
  OrContinueText,
  Form,
  StyledDateAndTime,
} from './styles';
import ExternalLoginButton from 'components/modals/AuthModal/ExternalLoginButton';
import { KIND_MAP } from 'components/modals/AuthModal/ExternalLoginButton/view';
import { mobileOnly } from 'style/mixins';
import { DynamicTranslationType, useAdminTranslation, useEndUserTranslation } from 'hooks/use-translation';
import FormInput from 'components/ui/v2/Inputs';
import GateTitle from './GateTitle';
import GateSubtitle from './GateSubtitle';

let maestroOauthEnabled: boolean;

const StyledInputWrapper = styled(FormInput.Root)`
  width: 300px;
  ${mobileOnly`
    width: 100%;
  `}
`;

const StyledButton = styled(Button as any)`
  ${mobileOnly`
    width: 100%;
  `}
  margin-bottom: 0;
`;

const ThirdPartyOauthsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
  ${mobileOnly`
    width: 100%;
  `}
`;

export const LoginGateAccessControlElements = () => {
  const accountFound = useSelector(getAccountFound);
  const auths = useSelector(getAuths);
  const [email, setEmail] = useState('');
  const [validEmail, setValidEmail] = useState<boolean | null>();
  const dispatch = useDispatch();
  const enabledAuths: string[] = [];
  const validOAuthButtons: string[] = [];

  const { endUserT } = useEndUserTranslation();

  Object.entries(auths).forEach((provider) => {
    const [providerName, providerStatus] = provider;
    if (providerStatus) {
      enabledAuths.push(providerName);

      if (providerName === 'maestro') maestroOauthEnabled = true;
    }
  });

  Object.entries(KIND_MAP).forEach(([provider]) => {
    validOAuthButtons.push(provider);
  });

  useEffect(() => {
    if (validEmail && accountFound?.isAccountFound) {
      dispatch(loginModal({ page: 'LOGIN', email }));
    } else if (validEmail && accountFound?.isAccountFound === false) {
      dispatch(loginModal({ page: 'SIGNUP', email }));
    }

    if (accountFound === null) setValidEmail(null);
  }, [accountFound]);

  function handleSubmit(event: React.FormEvent) {
    event.preventDefault();
    const checkValidEmail = isValidEmail(email);
    if (checkValidEmail) {
      setValidEmail(true);
      dispatch(accountLookup({ email: email.toLowerCase() }));
    } else {
      setValidEmail(true);
      setTimeout(() => {
        setValidEmail(false);
      }, 300);
    }
  }

  function renderMaestroOauth() {
    if (maestroOauthEnabled) {
      return (
        <Form onSubmit={handleSubmit} data-testid="loginGateSection">
          <StyledInputWrapper error={validEmail === false}>
            <FormInput.FieldSet>
              <FormInput.Legend className={FORM_LABEL}>
                {endUserT([DynamicTranslationType.globalFormLabelEnterEmailAddr], ['ENTER_YOUR_EMAIL_ADDRESS'])}
              </FormInput.Legend>
              <FormInput.InputIcon name="mail" />
              <FormInput.TextInput
                onChange={setEmail}
                type="email"
                value={email}
                className={FORM_INPUT}
                placeholder={endUserT(
                  [DynamicTranslationType.globalPlaceholderEmail],
                  ['EMAIL_PLACEHOLDER'],
                )}
              />
            </FormInput.FieldSet>

            {validEmail === false && (
              <FormInput.SupportingText>
                {endUserT([DynamicTranslationType.globalValidationInvalidEmail], ['EMAIL_IS_NOT_VALID'])}
              </FormInput.SupportingText>
            )}
          </StyledInputWrapper>
          <StyledButton
            className={SUBMIT}
            type="submit"
            hasError={validEmail === false && true}
          >
            {endUserT([DynamicTranslationType.globalActionNext], ['NEXT'])}
          </StyledButton>
        </Form>
      );
    }

    return null;
  }

  function renderThirdPartyOauths() {
    const onlyThirdPartyOauths: string[] = [];

    enabledAuths.forEach((providerName) => {
      if (providerName !== 'maestro') {
        if (validOAuthButtons.indexOf(providerName) !== -1) {
          onlyThirdPartyOauths.push(providerName);
        }
      }
    });

    if (onlyThirdPartyOauths.length > 0) {
      return (
        <ThirdPartyOauthsWrapper>
          {maestroOauthEnabled && (
            <OrContinueText>
              <TranslatedText stringKey="OR_CONTINUE_WITH" />
            </OrContinueText>
          )}
          {onlyThirdPartyOauths.map((providerName) => (
            <ExternalLoginButton
              kind={providerName}
              signUp={false}
              key={providerName}
            />
          ))}
        </ThirdPartyOauthsWrapper>
      );
    }

    return null;
  }

  return (
    <>
      {renderMaestroOauth()}
      {renderThirdPartyOauths()}
    </>
  );
};

const LoginGate = () => {
  const { t } = useAdminTranslation();
  const { timestamp } = useSelector(getFixedGateData(t), isEqual);

  return (
    <Container id={GATE_LOGIN_ID} data-testid="login-gate">
      {timestamp && (
        <StyledDateAndTime className={DATE_TIME} timeStamp={timestamp} />
      )}
      <GateTitle />
      <GateSubtitle />
      <LoginGateAccessControlElements />
    </Container>
  );
};

export default LoginGate;
