import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import InsightsViewable from 'components/insights/InsightsViewable';
import InstagramOverlay from 'components/overlays/InstagramOverlay';
import LowerThirdOverlay from 'components/overlays/LowerThirdOverlay';
import HighlightOverlay from 'components/overlays/HighlightOverlay';
import MessageOverlay from 'components/overlays/MessageOverlay';
import PollResultsOverlay from 'components/overlays/PollResultsOverlay';
import RaffleStartingOverlay from 'components/overlays/RaffleStartingOverlay';
import RaffleActiveOverlay from 'components/overlays/RaffleActiveOverlay';
import RafflePickingOverlay from 'components/overlays/RafflePickingOverlay';
import RaffleWinnerOverlay from 'components/overlays/RaffleWinnerOverlay';
import ShopifyOverlay from 'components/overlays/ShopifyOverlay';
import TweetOverlay from 'components/overlays/TweetOverlay';
import PollOverlay from 'components/overlays/PollOverlay';
import CommerceOverlay from 'components/overlays/CommerceOverlay';
import { computeOverlayRendererFromBroadcast } from 'services/renderer';
import { camelify } from 'shared/string-utils';
import { BREAK_WORD } from 'style/mixins';
import { OVERLAY_ID } from 'global-ids';
import { OVERLAY_CLASS } from 'injection-classes';

const OVERLAY_MAP = {
  commerce: CommerceOverlay,
  highlight: HighlightOverlay,
  instagram: InstagramOverlay,
  lower_third: LowerThirdOverlay,
  message: MessageOverlay,
  poll: PollOverlay,
  poll_results: PollResultsOverlay,
  prediction: PollOverlay,
  raffle: RaffleStartingOverlay,
  raffle_active: RaffleActiveOverlay,
  raffle_picking: RafflePickingOverlay,
  raffle_winner: RaffleWinnerOverlay,
  shopify: ShopifyOverlay,
  trivia: PollOverlay,
  tweet: TweetOverlay,
};

const DebugJson = styled.div`
  ${BREAK_WORD}
`;

const Container = styled.div`
  display: flex;
  flex-flow: row nowrap;
`;

const renderOverlayBody = (data, editing) => {
  const { broadcast, renderer = {} } = computeOverlayRendererFromBroadcast(data);
  const { overlayType = '' } = renderer;

  const Component = OVERLAY_MAP[overlayType];
  if (!Component) {
    if (process.env.NODE_ENV === 'development') {
      return <DebugJson>{ JSON.stringify(broadcast) }</DebugJson>;
    }
    return undefined;
  }
  const camelRenderer = camelify(renderer);
  if (overlayType === 'message') {
    return (
      <Component
        data={broadcast}
        editing={editing}
        overlayType={overlayType}
        renderer={camelRenderer}
      />
    );
  }

  return <Component data={broadcast} overlayType={overlayType} renderer={camelRenderer} />;
};

export default class Overlay extends React.Component {
  static propTypes = {
    data: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    editing: PropTypes.bool.isRequired,
  };

  render() {
    const { data, editing } = this.props;
    return (
      <Container className={OVERLAY_CLASS} data-testid="overlayContainer" id={`${OVERLAY_ID}-${data.payload.data.broadcastId}`}>
        { renderOverlayBody(data, editing) }
        <InsightsViewable
          doc={data}
          kingdom="overlay"
          visible
        />
      </Container>
    );
  }
}
