import React from 'react';
import { PanelTitle } from '../styles';
import { Container, MessagesContainer } from 'components/panels/ChatPanel/styles';
import MessageItem from 'components/panels/ChatPanel2/components/MessageItem';
import ChatForm from 'components/panels/ChatPanel/ChatForm/view';
import { useAdminTranslation } from 'hooks/use-translation';
interface IChat {
  image: string;
  name: string;
  text: string;
  time: string;
}
interface IRenderer {
  chat: IChat[];
  panelName: string;
}

interface ChatPreviewPanelProps {
  renderer: IRenderer;
}

/* tslint:disable:no-empty */
const NO_OP = () => {};

const ChatPreviewPanel: React.FC<ChatPreviewPanelProps> = ({ renderer: { panelName, chat = [] } }) => {
  const formattedName = panelName.substr(0, 60);
  const { t } = useAdminTranslation();

  const chatData = React.useMemo(() => {
    return chat.map((person, key) => {
      return (
        <MessageItem
          key={key}
          chatId=""
          filteredWordsList={[]}
          messageId=""
          avatar={person.image}
          content={person.text}
          userName={person.name}
          isFromAdmin={false}
        />
      );
    });
  }, [chat]);

  return (
    <Container keyboardOpen={false}>
      <PanelTitle>{formattedName}</PanelTitle>
      <MessagesContainer>
        {chatData}
      </MessagesContainer>
      <ChatForm
        androidKeyboardCallback={NO_OP}
        chatChannel=""
        checkUserName={NO_OP}
        clearEmoji={NO_OP}
        cooldownExpirationMs={0}
        emoji=""
        emojiPickerOpen={false}
        isChatModerator={false}
        isCurrentUserAdmin={false}
        isLoggedIn={false}
        isPreview={true}
        previewMessage={t('ADMIN_PANEL_CHAT_PREVIEW_MESSAGE')}
        isMobileLayout={false}
        onEmojiPickerClick={NO_OP}
        onEngage={NO_OP}
        onInvalidMessage={NO_OP}
        onSendInCooldown={NO_OP}
        onSendMessage={NO_OP}
        panelDoc={{}}
      />
    </Container>
  );
};

export default ChatPreviewPanel;
