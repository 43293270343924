import React from 'react';
import { SUBTITLE } from 'injection-classes';
import { useSelector } from 'react-redux';
import { useAdminTranslation } from 'hooks/use-translation';
import { GATE_SUBTITLE_MAX_LENGTH } from 'shared/constants';
import { getIsRichTextEditorV3Enabled } from 'services/feature-gate/selectors';
import GateTextSection from 'components/admin2/AccessGate/SharedComponents/GateTextSection';
import { isAccessControlModeActive } from 'services/admin/selectors';
import useEditGate from 'hooks/use-edit-gate';
import { PREDEFINED_TOOLBAR_BUTTONS } from 'components/ui/RichTextEditorV2/utils';

const GateSubtitle = () => {
  const { t } = useAdminTranslation();
  const isRichTextEditorV3Enabled = useSelector(getIsRichTextEditorV3Enabled);
  const isAccessGate = useSelector(isAccessControlModeActive);
  const { setGateSubtitleChanges, editingGateInformation } = useEditGate();

  const subtitleData = isRichTextEditorV3Enabled ? editingGateInformation.subtitleRawDataV2 : editingGateInformation.subtitleRawData;
  const handleSubtitleChange = (styledSubtitle: string) => {
    if (isAccessGate) {
      setGateSubtitleChanges(styledSubtitle);
    }
  };

  return (
    <GateTextSection
      codeInjectionClassName={SUBTITLE}
      isAccessControlMode={isAccessGate}
      onChange={handleSubtitleChange}
      data={subtitleData}
      sectionTitle={t('ACCESS_GATE_SUBTITLE_REGION_TITLE')}
      type="subtitle"
      maxLength={GATE_SUBTITLE_MAX_LENGTH}
      toolbarButtons={PREDEFINED_TOOLBAR_BUTTONS.bodyEditor}
      useRawDataV2={isRichTextEditorV3Enabled}
    />
  );
};

export default GateSubtitle;
