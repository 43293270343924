import { TEXT_LABEL3 } from 'style/mixins';
import { bw, rgba } from 'colors';
import React, { ReactNode } from 'react';
import { SPACING_SMALL } from 'style/constants';
import styled from 'styled-components';
import {
  useAdminTranslation,
  DynamicTranslationType,
  useEndUserTranslation,
  TranslationKey,
} from 'hooks/use-translation';

export const Container = styled.div`
  align-items: center;
  background-color: ${({ color }) => color};
  border-radius: 6.41361px;
  cursor: pointer;
  display: flex;
  flex: 1;
  height: 40px;
  margin-top: 13px;
`;

export const IconWrapper = styled.div<{ color: string }>`
  align-items: center;
  border-right: 1px solid ${({ color }) => rgba(bw(color), 0.1)};
  color: ${({ color }) => bw(color)};
  display: flex;
  height: 25px;
  justify-content: center;
  margin: 5px;
  padding-right: ${SPACING_SMALL};
  padding-left: 10px;
  border-top-left-radius: 6.41361px;
  border-bottom-left-radius: 6.41361px;
`;

export const TextWrapper = styled.div<{ color: string }>`
  ${TEXT_LABEL3}
  text-align: center;
  align-items: center;
  color: ${({ color }) => bw(color)};
  display: flex;
  flex: 1;
  justify-content: center;
  text-transform: uppercase;
`;

interface IProps {
  color: string;
  dataTestId: string;
  providerNameKey?: TranslationKey;
  renderIcon: () => ReactNode;
  signUp?: boolean;
}

export const BaseLoginButton = ({
  color,
  providerNameKey,
  signUp,
  renderIcon,
  dataTestId,
}: IProps) => {
  const { endUserT } = useEndUserTranslation();
  const { t } = useAdminTranslation();

  let loginText;
  if (providerNameKey) {
    const provider = t(providerNameKey);
    loginText = signUp
      ? endUserT(
          [DynamicTranslationType.externalSignupWith, { variables: { provider } }],
          ['SIGNUP_WITH', { provider }],
        )
      : endUserT(
        [DynamicTranslationType.externalLoginWith, { variables: { provider } }],
        ['LOG_IN_WITH', { provider }],
      );
  } else {
    loginText = endUserT([DynamicTranslationType.externalLoginOrSignup], ['LOG_IN_OR_SIGN_UP']);
  }

  return (
    <Container color={color} data-testid={dataTestId}>
      <IconWrapper color={color}>
        {renderIcon()}
      </IconWrapper>
      <TextWrapper color={color}>
      {loginText}
      </TextWrapper>
    </Container>
  );
};

export default BaseLoginButton;
