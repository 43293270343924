import React from 'react';
import { useSelector } from 'react-redux';

import SubscriptionButton from 'components/admin2/SubscriptionButton';
import ISubscription from 'models/ISubscription';
import { useEndUserTranslation, DynamicTranslationType } from 'hooks/use-translation';
import { getPageId } from 'services/app/selectors';

import {
  Container,
  NameAndDescription,
  Name,
  Description,
} from './styles';

export interface ITicketOrSub {
  handleSubscriptionButtonClick?: (selectedSubscription: ISubscription) => void;
  subscription: ISubscription;
}

const TicketOrSub: React.FC<ITicketOrSub> = ({ handleSubscriptionButtonClick, subscription }) => {
  const { endUserT } = useEndUserTranslation();
  const pageId = useSelector(getPageId);

  if (!subscription) return null;

  return (
    <Container>
      <NameAndDescription>
        <Name data-testid={`gateTicketOrSubName-${subscription._id}`}>
          {
            endUserT([
              DynamicTranslationType.gateEntitlementName,
              {
                pageId,
                subscriptionId: subscription._id!,
              },
            ]) || subscription.name
          }
        </Name>
        <Description data-testid={`gateTicketOrSubDescription-${subscription._id}`}>
          {
            endUserT([
              DynamicTranslationType.gateEntitlementDescription,
              {
                pageId,
                subscriptionId: subscription._id!,
              },
            ]) || subscription.description
          }
        </Description>
      </NameAndDescription>
      <SubscriptionButton
        subscription={subscription}
        handleSubscriptionButtonClick={handleSubscriptionButtonClick}
      />
    </Container>
  );
};

export default TicketOrSub;


