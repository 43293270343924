import React, { ReactNode, useState } from 'react';
import styled from 'styled-components';
import ModalPortal from 'components/modals/ModalPortal';
import Draggable from 'components/ui/Draggable';

interface IDraggableModalProps {
  children?: ReactNode;
  className?: string;
  initialPosition?: { x: number; y: number };
}

export default function({
  children,
  className,
  initialPosition = { x: 0, y: 0 },
}: IDraggableModalProps) {
  const [isDragging, setIsDragging] = useState(false);
  const [modalPosition, setModalPosition] = useState(initialPosition);

  const dragStart = () => setIsDragging(true);
  const dragStop = () => setIsDragging(false);

  const drag = (x: number, y: number) => {
    setModalPosition({ x, y });
  };

  const handleStopPropagation = (e?: any) => {
    e?.stopPropagation();
  };

  return (
    <ModalPortal>
      <DraggableModal
        className={className}
        isDragging={isDragging}
        onEnd={dragStop}
        onMove={drag}
        onStart={dragStart}
        x={modalPosition.x}
        y={modalPosition.y}
        onClick={handleStopPropagation}
      >
        {children}
      </DraggableModal>
    </ModalPortal>
  );
}

const DraggableModal = styled(Draggable)<{isDragging: boolean}>`
  display: flex;
  cursor: ${({ isDragging }) => isDragging ? 'grabbing' : 'grab'};
`;
