import React from 'react';
import styled, { css } from 'styled-components';
import Spinner from 'components/ui/Spinner';
import {
  TEXT_H5,
  TEXT_LABEL3,
  mobileOnly,
  desktopOnly,
  CONTENT_FONT,
  TEXT_LABEL1,
} from 'style/mixins';
import {
  SURFACE_2,
  SURFACE_5,
  TEXT_100,
  TEXT_200,
  TEXT_400,
} from 'style/constants';
import ModalWindow from 'components/modals/ModalWindow';
import { CloseButton, Button } from 'components/ui/Button';

export const MaestroLoginContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
`;

export const FormBody = styled.form`
  display: flex;
  flex-flow: column nowrap;
`;

export const ExternalAuthsWrapper = styled.div``;

export const OrText = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 16px;
  ${TEXT_LABEL3}
  flex-shrink: 0;
  padding: 0px 13px;
  font-weight: 400;
  line-height: 15px;
  color: ${TEXT_200};

  :before,
  :after {
    content: "";
    opacity: 0.2;
    border-top: 1px solid ${TEXT_400};
    width: 100%;
    transform: translateY(40%);
  }
`;

export const FormSubmit = styled(Button)`
  height: 46px;
  margin-top: 30px;
  font-size: 16px;
`;

export const Label = styled.div`
  ${TEXT_H5}
  font-size: 12px;
  display: flex;
  width: 100%;
  user-select: none;
  & > span:last-child {
    margin-left: 12px;
  }
`;

export const RegisterLoginSpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const spinnerStyle = {
  height: '40px',
  transform: 'scale(1.5)',
  width: '30px',
};

export const RegisterLoginSpinner = () => <Spinner style={spinnerStyle} />;

export const RegisterLoginWaitingMessageContainer = styled.span`
  ${TEXT_LABEL3}
  text-align: center;
  color: ${TEXT_100};
  margin: 0px 8px;
  font-weight: bold;
`;

export const StyledCloseButton = styled(CloseButton).attrs(() => ({
  containerCss: css`
    position: absolute;
    top: 20px;
    right: 20px;
  `,
}))``;

export const StyledModalWindow = styled(ModalWindow)`
  background-color: ${SURFACE_2};
  ${CONTENT_FONT}
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  padding: 30px 20px 20px;
  width: 370px;
  justify-content: start;
  align-items: center;
  border-radius: 10px;
  overflow: auto;
  ${desktopOnly`max-height: 90vh;`};
  ${mobileOnly`
    position: fixed;
    width: 100%;
    height: 100%;
    border-radius: 0px;
  `}
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

export const PageWrapper = styled.div``;

export const ModalTitle = styled.div`
  ${TEXT_LABEL1};
  color: ${TEXT_100};
  margin-bottom: 10px;
`;

export const ModalDescription = styled.div`
  ${TEXT_H5};
  color: ${TEXT_200};
  margin-bottom: 20px;
`;
