import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { BundleButton } from 'components/admin2/SubscriptionButton';
import IBundle from 'models/IBundle';
import { getProducts } from 'services/shopify/selectors';
import { getPageId } from 'services/app/selectors';
import { useEndUserTranslation, DynamicTranslationType } from 'hooks/use-translation';

import {
  Container,
  NameAndDescription,
  Name,
  Description,
  Footer,
  SoldOut,
  Image,
  Content,
} from './styles';
import { useAdminTranslation } from 'hooks/use-translation';

export interface IBundleComponent {
  bundle: IBundle;
  handleBundleButtonClick?: (bundle: IBundle) => void;
}

const Bundle: React.FC<IBundleComponent> = ({ bundle, handleBundleButtonClick }) => {
  const { endUserT } = useEndUserTranslation();
  const pageId = useSelector(getPageId);
  const products = useSelector(getProducts);

  const isAvailableForSale = useMemo(() => {
    const shopifyProductsInBundle = bundle.items.filter(({ type }) => type === 'shopify');

    const isEveryShopifyProductAvailable = shopifyProductsInBundle.every(({ id }) => {
      const product = products.find((shopifyProduct) => shopifyProduct.id === id);
      return product?.availableForSale;
    });

    return isEveryShopifyProductAvailable;
  }, [bundle, products]);

  if (!bundle) return null;

  const bundleDescriptionText = endUserT([
    DynamicTranslationType.gateBundleDescription,
    {
      pageId,
      bundleId: bundle._id!,
    },
  ]) || bundle.description;

  return (
    <Container>
      {bundle.image && <Image src={bundle.image}/>}
      <Content>
        <NameAndDescription>
          <Name data-testid={`gateBundleName-${bundle._id}`}>
            {
              endUserT([
                DynamicTranslationType.gateBundleName,
                {
                  pageId,
                  bundleId: bundle._id!,
                },
              ]) || bundle.name
            }
          </Name>
          {bundleDescriptionText && (
            <Description data-testid={`gateBundleDescription-${bundle._id}`}>
              {bundleDescriptionText.split('\n').map((line, index) => <p key={index}>{line}</p>)}
            </Description>
          )}
        </NameAndDescription>
        <Footer>
          <BundleButton
            bundle={bundle}
            disabled={!isAvailableForSale}
            onClick={handleBundleButtonClick}
          />
          {!isAvailableForSale && (
            <SoldOut>
              {
                endUserT(
                  [DynamicTranslationType.gateBundleSoldOut],
                  ['SOLD_OUT'],
                )
              }
            </SoldOut>
          )}
        </Footer>
      </Content>
    </Container>
  );
};

export default Bundle;


