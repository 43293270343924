import React from 'react';
import { ClaimedMessage, ClaimReward } from './styles';
import { useDispatch } from 'react-redux';
import { claimQuest } from 'services/quest';
import { RewardType } from 'models/IQuest';
import { DynamicTranslationType, useEndUserTranslation } from 'hooks/use-translation';
import { openPanel } from 'services/custom-panels';

type Props = {
  available: boolean;
  claimed: boolean;
  claimedMessage: string;
  claimRewardText: string;
  completed: boolean;
  expired: boolean;
  questId: string;
  rewardType: RewardType;
};

const QuestClaimInfo: React.FC<Props> = ({ questId, rewardType, available, claimed, claimedMessage, completed, expired , claimRewardText }) => {
  const dispatch = useDispatch();
  const { endUserT } = useEndUserTranslation();

  const claimPrize = () => {
    dispatch(claimQuest(questId));
  };

  const showProfilePanel = () => {
    dispatch(openPanel({ doc: { data: { initialPage: 'REWARDS', kind: 'profile' } } }));
  };

  const renderClaimedButton = () => {
    switch (rewardType) {
      case 'webhook':
        return <ClaimReward>{endUserT([DynamicTranslationType.questClaimed], ['CLAIMED'])}</ClaimReward>;
      case 'prize':
        return (
          <ClaimReward onClick={showProfilePanel}>
            {endUserT([DynamicTranslationType.questViewPrize], ['VIEW_YOUR_PRIZE'])}
          </ClaimReward>
        );
      default:
        return <ClaimReward>{endUserT([DynamicTranslationType.questEntrySubmitted], ['ENTRY_SUBMITTED'])}</ClaimReward>;
    }
  };

  return (
    <>
      {!claimed && completed && (
        <ClaimReward
          expired={!available || expired}
          onClick={claimPrize}
        >
          {claimRewardText}
        </ClaimReward>
      )}
      {claimed && renderClaimedButton()}
      {claimedMessage && claimed && <ClaimedMessage>{claimedMessage}</ClaimedMessage>}
    </>
  );
};

export default QuestClaimInfo;
