import { rgba } from 'colors';
import Button from 'components/ui/Button';
import Icon from 'components/ui/Icon';
import { ACCENT_PRIMARY, HIGHLIGHT_PRIMARY, OPTIONAL_DYNAMIC_THEME_COLOR, SURFACE_1, SURFACE_2, SURFACE_4, SURFACE_5, TEXT_100, TEXT_200 } from 'style/constants';
import { TEXT_BODY_XS_REGULAR, TEXT_LABEL_M_BOLD, TEXT_LABEL_S_BOLD, TEXT_LABEL_S_MEDIUM, TEXT_LABEL_XS_BOLD } from 'style/design-system/textStyles';
import { SCROLL_BAR } from 'style/mixins';
import styled from 'styled-components';
import { css } from 'styled-components';

export const CompletedByIcon = styled(Icon).attrs({
  name: 'people',
})`
  & svg {
    width: 16px;
    height: 16px;
    & path {
      fill: ${HIGHLIGHT_PRIMARY};
    }
  }
`;

export const CompletedBy = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  ${TEXT_LABEL_S_MEDIUM}
  color: ${TEXT_100};
`;

export const ClaimedMessage = styled.span`
  ${TEXT_BODY_XS_REGULAR}
  color: ${TEXT_100};
`;

export const ActionsWrapper = styled.ul`
  all: unset;
  display: flex;
  flex-direction: column;
  gap: 16px;
  & li:not(:last-child)::before {
    content: '';
    width: 1px;
    height: 100%;
    background-color: ${SURFACE_5};
    position: absolute;
    left: 14px;
    top: 23px;
  }
`;

export const RewardSection = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

export const RewardText = styled.span`
  ${TEXT_LABEL_S_BOLD}
  color: ${TEXT_100};
`;

export const RewardWord = styled.span`
  ${TEXT_LABEL_XS_BOLD}
  color: ${ACCENT_PRIMARY};
`;

export const RewardImage = styled.img`
  min-width: 50px;
  max-width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: ${SURFACE_2};
  object-fit: cover;
`;

export const Lifespan = styled.div`
  background-color: ${props => rgba(SURFACE_1(props), 0.5)};
  border-radius: 20px;
  ${TEXT_LABEL_S_MEDIUM}
  color: ${TEXT_200};
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 6px 12px 6px 8px;
  width: fit-content;
`;

export const Description = styled.p`
  all: unset;
  ${TEXT_BODY_XS_REGULAR}
  color: ${TEXT_200};
  overflow-wrap: anywhere;
`;

export const Content = styled.div<{ available: boolean; expired: boolean; failed: boolean; showMoreContent: boolean; }>`
  display: flex;
  flex-direction: column;
  padding: 16px 16px 20px 16px;
  gap: 20px;
  border-radius: 0 0 10px 10px;
  max-height: calc(100% - 130px);
  height: 100%;
  ${({ expired, available, failed }) => (expired || !available || failed) && css`
    pointer-events: none;
    opacity: 0.5;
  `}

  ${({ showMoreContent }) => showMoreContent && css`
    height: unset !important;
    padding-bottom: 100px;
  `}
`;

export const QuestTitle = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: auto;
  overflow-y: auto;
  & h3 {
    all: unset;
    ${TEXT_LABEL_M_BOLD}
    color: ${TEXT_100};
    overflow-wrap: anywhere;
  }
`;

export const CardBackground = styled.div<{ backgroundColor?: string; image?: string; }>`
  background-color: ${({ backgroundColor, ...props }) => backgroundColor ? OPTIONAL_DYNAMIC_THEME_COLOR(backgroundColor) : SURFACE_5(props)};
  ${({ image }) => image && css`
    background-image: url(${image});
    background-size: cover;
    background-position: 50% 50%;
  `};
  aspect-ratio: 21 / 9;
  border-radius: 10px 10px 0 0;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px;
`;

export const Wrapper = styled.section<{ showMoreContent: boolean; }>`
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background-color: ${SURFACE_4};
  position: relative;

  ${({ showMoreContent }) => !showMoreContent && css`contain: content;`}
`;

export const ShowMoreContent = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  border-radius: 5px;
  display: flex;
  align-items: flex-end;
  height: 100px;
  padding: 30px;
  background: linear-gradient(180deg, rgba(18, 20, 26, 0) 36.98%, ${SURFACE_4} 80%);
`;

export const ShowMoreButton = styled(Button)`
  height: 24px;
`;

export const ClaimReward = styled(Button)<{ expired?: boolean; }>`
  cursor: ${({ expired }) => expired ? 'default' : 'pointer'};
  height: 35px;
`;
