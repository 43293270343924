import React, { HTMLAttributes, PropsWithChildren } from 'react';
import { BackgroundLayer, Header, OverflowContent, StyledCloseButton, Title, Wrapper } from './styles';
import { TI18nKey } from 'components/i18n/TranslatedText/i18nKey';
import { useAdminTranslation } from 'hooks/use-translation';
import { useSelector } from 'react-redux';
import { isChannelPageType } from 'services/app/selectors';

interface IMobileModalBottomSheetProps extends HTMLAttributes<HTMLDivElement> {
  isOpen: boolean;
  onClose: () => void;
  titleKey?: TI18nKey;
}

const MobileModalBottomSheet: React.FC<PropsWithChildren<IMobileModalBottomSheetProps>> = ({ isOpen, onClose, children, titleKey, ...rest }) => {
  const isChannel = useSelector(isChannelPageType);
  const { t } = useAdminTranslation();

  return (
    <>
      {isOpen && <BackgroundLayer onClick={onClose} />}
      <Wrapper isChannel={isChannel} isOpen={isOpen} {...rest}>
        <Header>
          {titleKey && <Title>{t(titleKey)}</Title>}
        </Header>
        <StyledCloseButton onClick={onClose} />
        <OverflowContent>
          {children}
        </OverflowContent>
      </Wrapper>
    </>
  );
};

export default MobileModalBottomSheet;
