import styled, { css } from 'styled-components';
import withTooltip from 'components/core/withTooltip';
import Separator from 'components/panels/Separator';
import Icon from 'components/ui/Icon';
import { ADMIN_TEXT_100, ADMIN_TEXT_300 } from 'style/constants';
import { ADMIN_TEXT_LABEL_M_BOLD } from 'style/design-system/textStyles';

export const CatalogWrapper = styled.div``;

export const CatalogHeader = styled.div<{ isOpen: boolean }>`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ isOpen }) => (isOpen ? '23px' : '0px')}
`;

export const CatalogTitleWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: 10px;
`;

export const CatalogTitle = styled.p`
  color: ${ADMIN_TEXT_100};
  margin: 0px;
  ${ADMIN_TEXT_LABEL_M_BOLD};
`;

export const ArrowDropDownIcon = styled(Icon) <{ isOpen: boolean }>`
  cursor: pointer;
  width: 10px;
  transition: transform 0.2s;
  transform: rotate(${({ isOpen }) => (isOpen ? '180deg' : '0deg')});
`;

export const InfoIcon = styled(Icon).attrs({
  name: 'info',
})`
  cursor: pointer;
  width: 12px;
  display: flex;
  align-items: center;
  svg {
    path {
      fill: ${ADMIN_TEXT_300};
    }
  }
`;

export const InfoIconWithTooltip = withTooltip(InfoIcon, {
  admin: true,
  tooltipSeparation: 0,
  tooltipAlign: 'right',
  tooltipCss: css`
    max-width: 303px;
    white-space: normal;
    text-align: left;
    margin-bottom: 7.25px;
    margin-left: -20px;
  `,
  tooltipArrowCss: css`
    color: red;
    left: 26px !important;
  `,
});

export const CatalogBody = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledSeparator = styled(Separator)`
  margin: 20px 0px;
`;
