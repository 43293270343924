import React, { useCallback, useMemo } from 'react';
import hash from 'json-stable-stringify';
import { useSelector } from 'react-redux';
import { ICatalogListProps } from './CatalogList';
import useResizeObserver from 'hooks/use-resize-observer';
import {
  PanelsWrapper,
  PanelHeader,
  HeaderTitle,
  PanelBody,
  CardCatalog,
  PanelCatalog,
  StyledSeparator,
  PANEL_HEADER_HEIGHT,
  PANEL_OFFSET,
  HeaderActionsWrapper,
  CloseButton,
  PanelEditorWrapper,
  PanelCatalogWrapper,
  StyledBackButton,
} from './styles';
import PanelEditor from 'components/admin-bridge/SidebarEditSheet/PanelEditor';
import useSidebarCardPicker from 'hooks/use-sidebar-card-picker';
import CardEditor from 'components/admin-bridge/SidebarEditSheet/CardEditor';
import CmsNavHeader from 'components/admin2/CmsNavHeader';
import { getPreviewPanelRenderer } from 'services/admin/selectors';
import { TranslationKey, useAdminTranslation } from 'hooks/use-translation';
import useSidebarPanelPicker from 'hooks/use-sidebar-panel-picker';

interface IPanelsV2 {
  onStopEditing: () => void;
  state: string;
}

const PanelsV2: React.FC<IPanelsV2> = ({ onStopEditing, state }) => {
  const [cardCatalogRef, { height }] = useResizeObserver();
  const { t } = useAdminTranslation();
  const currentEditingRendererPanel = useSelector(getPreviewPanelRenderer);

  const {
    catalogActivePanels,
    catalogAvailablePanels,
    enablePanel,
    disablePanel,
    reorderPanel,
    openEditPanel,
    closeEditPanel,
    editPanel,
    duplicatePanel,
    deletePanel,
    previewPanel,
  } = useSidebarPanelPicker();

  const {
    editorCard,
    editorCardTitleKey,
    editorCardIcon,
    cardHasChanges,
    catalogCards,
    toggleCard,
    openCardEditor,
    closeCardEditor,
    editCard,
    saveCard,
  } = useSidebarCardPicker();

  const activePanelList: ICatalogListProps = useMemo(
    () => ({
      titleKey: 'ADMIN_PANEL_V2_PANELS_SECTION_ACTIVE_PANELS',
      items: catalogActivePanels,
      draggable: true,
      testId: 'activePanelsList',
    }),
    [hash(catalogActivePanels)],
  );

  const availablePanelList: ICatalogListProps = useMemo(
    () => ({
      titleKey: 'ADMIN_PANEL_V2_PANELS_SECTION_AVAILABLE_PANELS',
      items: catalogAvailablePanels,
      testId: 'availablePanelsList',
    }),
    [hash(catalogAvailablePanels)],
  );

  const onHandleToggle = useCallback(
    (kindId: string, checked: boolean) => {
      if (checked) {
        enablePanel(kindId);
      } else {
        disablePanel(kindId);
      }
    },
    [enablePanel, disablePanel],
  );

  const onHandleReorder = useCallback(
    (reorderedPanels) => {
      reorderPanel(reorderedPanels);
    },
    [reorderPanel],
  );

  const onHandleEdit = useCallback(
    (kindId: string) => {
      openEditPanel(kindId);
    },
    [openEditPanel],
  );

  const onHandleCloseEdit = useCallback(() => {
    closeEditPanel();
    closeCardEditor();
  }, [closeEditPanel]);

  const onHandleChangePanel = useCallback(
    (editingPanel) => {
      editPanel(editingPanel);
    },
    [editPanel],
  );

  const onHandleDuplicatePanel = useCallback((kind) => {
    duplicatePanel(kind);
  }, [duplicatePanel]);

  const onHandleDeletePanel = useCallback((kind) => {
    deletePanel(kind);
  }, [deletePanel]);

  const onHandlePreviewPanel = useCallback((kind) => {
    previewPanel(kind);
  }, [previewPanel]);

  const isEditingItem = Boolean(currentEditingRendererPanel || editorCard);

  return (
    <PanelsWrapper state={state}>
      <PanelHeader>
        <HeaderTitle>{t('ADMIN_SIDEBAR_SETTINGS')}</HeaderTitle>
        <HeaderActionsWrapper>
          {isEditingItem && (
            <StyledBackButton onClick={onHandleCloseEdit} />
          )}
          <CloseButton onClick={onStopEditing} />
        </HeaderActionsWrapper>
      </PanelHeader>
      <PanelBody>
        {currentEditingRendererPanel ? (
          <PanelEditorWrapper>
            <PanelEditor
              onChange={onHandleChangePanel}
              panelType={currentEditingRendererPanel.panelType}
              renderer={currentEditingRendererPanel}
            />
          </PanelEditorWrapper>
        ) : null}
        {editorCard && (
          <PanelEditorWrapper>
            <CmsNavHeader
              existing={true}
              iconName={editorCardIcon}
              onSave={cardHasChanges ? saveCard : null}
              titleKey={editorCardTitleKey as TranslationKey}
            />
            <CardEditor
              onChange={editCard}
              renderer={editorCard.renderer || editorCard.data}
            />
          </PanelEditorWrapper>
        )}
        {isEditingItem ? null : (
          <PanelCatalogWrapper>
            <CardCatalog
              titleKey="ADMIN_PANEL_V2_CARDS_SECTION_TITLE"
              infoKey="ADMIN_PANEL_V2_CARDS_SECTION_INFORMATION"
              catalogLists={[catalogCards]}
              isOpen={false}
              onEdit={openCardEditor}
              onToggle={toggleCard}
              parentRef={cardCatalogRef}
              testId="cardsCatalog"
            />
            <StyledSeparator />
            <PanelCatalog
              titleKey="ADMIN_SIDEBAR_SECTION_PANELS"
              infoKey="ADMIN_SIDEBAR_SECTION_PANELS_HELPER"
              catalogLists={[activePanelList, availablePanelList]}
              isOpen={true}
              cardOffset={height + PANEL_HEADER_HEIGHT + PANEL_OFFSET}
              onToggle={onHandleToggle}
              onReorder={onHandleReorder}
              onEdit={onHandleEdit}
              onDuplicate={onHandleDuplicatePanel}
              onDelete={onHandleDeletePanel}
              onPreview={onHandlePreviewPanel}
              testId="panelsCatalog"
            />
          </PanelCatalogWrapper>
        )}
      </PanelBody>
    </PanelsWrapper>
  );
};

export default PanelsV2;
