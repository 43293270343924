import GoToPage from 'components/ui/CTAButton/BehaviorAction/GoToPage';
import { useGlobalVideoContext } from 'contexts/GlobalVideoContext';
import { useGoToVideo } from 'hooks/use-go-to-video';
import IQuestAction, { ActionKinds } from 'models/IQuestAction';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPageId, setActivePanel } from 'services/app';
import { openPanel } from 'services/custom-panels';
import { getReferralUrl } from 'services/insights';
import { ACTION } from 'injection-classes';
import warning from 'warning';
import {
  Action,
  ActionActions,
  ActionButton,
  ActionCompletedValue,
  ActionCompletionValue,
  ActionInfo,
  ActionTitle,
  QuestActionClaimedIcon,
  QuestActionCompletedIcon,
  QuestActionFailedIcon,
  QuestActionOpenIcon,
  QuestActionOpenWrapper,
} from './styles';
import { QuestEngageMetadata } from '..';
import { TranslationKey, useAdminTranslation } from 'hooks/use-translation';
import { isChannelPageType } from 'services/app/selectors';
import { getMultipleChoicePanel } from 'services/user-layout/selectors';



type QuestActionProps = {
  action: IQuestAction;
  claimed: boolean;
  completed: boolean;
  failed: boolean;
  progress: number;
  questEngage: (extra: QuestEngageMetadata) => void;
};

const QuestAction = ({ action, claimed, completed, failed, progress, questEngage }: QuestActionProps) => {
  const dispatch = useDispatch();
  const pageId = useSelector(getPageId);
  const goToVideo = useGoToVideo();
  const isChannel = useSelector(isChannelPageType);
  const multipleChoicePanel = useSelector(getMultipleChoicePanel);
  const { shareId } = useGlobalVideoContext();
  const { t } = useAdminTranslation();

  const onChannel = action.channelId && action.channelId === pageId;
  const seconds = Number(action.valueRequired || 0) * 60;

  const goToPanel = () => {
    // An action that is channel specific must be completed on a specific channel
    if (!action.validationValue || !onChannel) {
      return;
    }
    dispatch(openPanel({ id: action.validationValue }));
  };


  const onGoToVideo = () => {
    // An action that is channel specific must be completed on a specific channel
    if (!action.validationValue || !onChannel) {
      return;
    }

    questEngage({
      action: 'play_video',
      video: action.validationValue,
    });
    goToVideo(action.validationValue);
  };

  const goToLink = () => {
    if (!action.validationValue) {
      return;
    }

    questEngage({ action: 'open_link', url: action.validationValue });
    window.open(action.validationValue, '_blank');
  };

  const goToPolls = () => {
    if (!multipleChoicePanel) {
      return;
    }

    dispatch(setActivePanel(multipleChoicePanel._id));
  };

  const goToTrivias = () => {
    if (!multipleChoicePanel) {
      return;
    }

    dispatch(setActivePanel(multipleChoicePanel._id));
  };

  const goToPredictions = () => {
    if (!multipleChoicePanel) {
      return;
    }

    dispatch(setActivePanel(multipleChoicePanel._id));
  };

  const openMultipleChoicePanel = (kind: 'poll' | 'trivia' | 'prediction') => () => {
    if (!action.validationValue || !onChannel) {
      return;
    }

    dispatch(openPanel({
      doc: {
        data: {
          kind: 'multiple_choice',
          questions: [
            {
              kind,
              [kind]: {
                _id: action.validationValue,
              },
            },
          ],
        },
      },
    }));
  };

  const renderChannelSpecificCta = () => {
    const channelId = action.kind === ActionKinds.VisitChannel ? action.validationValue : action.channelId;
    if (onChannel || !channelId || claimed || completed) {
      return null;
    }

    return (
      <GoToPage pageId={channelId}>
        <ActionButton>{t('ACTION_GO_TO_CHANNEL')}</ActionButton>
      </GoToPage>
    );
  };

  const renderProgress = (targetValue?: number) => {
    const value = typeof targetValue === 'number' ? targetValue : (action.valueRequired || 1);

    return (
      <ActionCompletionValue completed={completed} claimed={claimed}>
        <ActionCompletedValue>{progress}</ActionCompletedValue>
        /{value}
      </ActionCompletionValue>
    );
  };

  const renderActionButton = (fn: () => void, textKey: TranslationKey) => {
    if (claimed || completed) {
      return null;
    }

    return (
      <ActionButton onClick={fn}>{t(textKey)}</ActionButton>
    );
  };

  const renderAction = () => {
    switch(action.kind) {
      case ActionKinds.WatchSpecificVideo:
        return (
          <>
            <ActionTitle>{t('WATCH_SPECIFIC_VIDEO_FOR_X_SECONDS', { seconds })}</ActionTitle>
            <ActionActions>
              {renderChannelSpecificCta()}
              {renderActionButton(onGoToVideo, 'ADMIN_LABEL_WATCH')}
              {renderProgress(seconds)}
            </ActionActions>
          </>
        );
      case ActionKinds.VisitChannel:
        return (
          <>
            <ActionTitle>{t('ADMIN_ACTION_VISIT_CHANNEL')}</ActionTitle>
            <ActionActions>
              {renderChannelSpecificCta()}
              {renderProgress()}
            </ActionActions>
          </>
        );
      case ActionKinds.ViewPanel:
        return (
          <>
            <ActionTitle>{t('ADMIN_ACTION_VIEW_PANEL')}</ActionTitle>
            <ActionActions>
              {renderChannelSpecificCta()}
              {isChannel && renderActionButton(goToPanel, 'GO_TO_PANEL')}
              {renderProgress()}
            </ActionActions>
          </>
        );
      case ActionKinds.OpenLink:
        return (
          <>
            <ActionTitle>{t('VISIT_LINK')}</ActionTitle>
            <ActionActions>
              {renderChannelSpecificCta()}
              {renderActionButton(goToLink, 'ADMIN_ACTION_OPEN_LINK')}
              {renderProgress()}
            </ActionActions>
          </>
        );
      case ActionKinds.WatchVideo:
        return (
          <>
            <ActionTitle>{t('WATCH_ANY_VIDEO_FOR_X_SECONDS', { seconds })}</ActionTitle>
            <ActionActions>
              {renderChannelSpecificCta()}
              {renderProgress(seconds)}
            </ActionActions>
          </>
        );
      case ActionKinds.Impossible:
        return (
          <>
            <ActionTitle>{t('DO_THIS_IMPOSSIBLE_ACTION')}</ActionTitle>
            <ActionActions>
              {renderProgress()}
            </ActionActions>
          </>
        );
      case ActionKinds.Retweet:
        return (
          <>
            <ActionTitle>{t('RETWEET_A_STREAM')}</ActionTitle>
            <ActionActions>
              {renderProgress()}
            </ActionActions>
          </>
        );
      case ActionKinds.ShareFacebookVideo:
        return (
          <>
            <ActionTitle>{t('POST_ON_FACEBOOK')}</ActionTitle>
            <ActionActions>
              {renderChannelSpecificCta()}
              {shareId && !claimed && !completed && <ActionButton as="a" href={getReferralUrl(shareId)}>{t('SHARE')}</ActionButton>}
              {renderProgress()}
            </ActionActions>
          </>
        );
      case ActionKinds.VisitTime:
        let startDate = '';
        let endDate = '';
        if (action.timeMinimum) {
          const date = new Date(action.timeMinimum);
          startDate = `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
        }

        if (action.timeMaximum) {
          const date = new Date(action.timeMaximum);
          endDate = `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
        }

        return (
          <>
            <ActionTitle>{t('ADMIN_ACTION_VISIT_PAGE')}{startDate && endDate ? ` between ${startDate} - ${endDate}` : ''}</ActionTitle>
            <ActionActions>
              {renderChannelSpecificCta()}
              {renderProgress()}
            </ActionActions>
          </>
        );
      case ActionKinds.AnswerAnyPoll:
        return (
          <>
            <ActionTitle>{t('ADMIN_ACTION_ANSWER_ANY_POLL')}</ActionTitle>
            <ActionActions>
              {renderChannelSpecificCta()}
              {isChannel && renderActionButton(goToPolls, 'GO_TO_POLLS')}
              {renderProgress()}
            </ActionActions>
          </>
        );
        case ActionKinds.AnswerSpecificPoll:
          return (
            <>
              <ActionTitle>{t('ANSWER_SPECIFIC_POLL')}</ActionTitle>
              <ActionActions>
                {renderChannelSpecificCta()}
                {isChannel && renderActionButton(openMultipleChoicePanel('poll'), 'GO_TO_POLL')}
                {renderProgress()}
              </ActionActions>
            </>
          );
      case ActionKinds.AnswerAnyTrivia:
        return (
          <>
            <ActionTitle>{t('ADMIN_ACTION_ANSWER_ANY_TRIVIA')}</ActionTitle>
            <ActionActions>
              {renderChannelSpecificCta()}
              {isChannel && renderActionButton(goToTrivias, 'GO_TO_TRIVIAS')}
              {renderProgress()}
            </ActionActions>
          </>
        );
        case ActionKinds.AnswerSpecificTrivia:
          return (
            <>
              <ActionTitle>{t('ANSWER_SPECIFIC_TRIVIA')}</ActionTitle>
              <ActionActions>
                {renderChannelSpecificCta()}
                {isChannel && renderActionButton(openMultipleChoicePanel('trivia'), 'GO_TO_TRIVIA')}
                {renderProgress()}
              </ActionActions>
            </>
          );
        case ActionKinds.AnswerSpecificTriviaCorrectly:
          return (
            <>
              <ActionTitle>{t('ANSWER_SPECIFIC_TRIVIA_CORRECTLY')}</ActionTitle>
              <ActionActions>
                {renderChannelSpecificCta()}
                {isChannel && renderActionButton(openMultipleChoicePanel('trivia'), 'GO_TO_TRIVIA')}
                {renderProgress()}
              </ActionActions>
            </>
          );
        case ActionKinds.AnswerAnyTriviaCorrectly:
          return (
            <>
              <ActionTitle>{t('ADMIN_ACTION_ANSWER_ANY_TRIVIA_CORRECTLY')}</ActionTitle>
              <ActionActions>
                {renderChannelSpecificCta()}
                {isChannel && renderActionButton(goToTrivias, 'GO_TO_TRIVIAS')}
                {renderProgress()}
              </ActionActions>
            </>
          );
        case ActionKinds.AnswerAnyPrediction:
          return (
            <>
              <ActionTitle>{t('ADMIN_ACTION_ANSWER_ANY_PREDICTION')}</ActionTitle>
              <ActionActions>
                {renderChannelSpecificCta()}
                {isChannel && renderActionButton(goToPredictions, 'GO_TO_PREDICTIONS')}
                {renderProgress()}
              </ActionActions>
            </>
          );
        case ActionKinds.AnswerAnyPredictionCorrectly:
          return (
            <>
              <ActionTitle>{t('ADMIN_ACTION_ANSWER_ANY_PREDICTION_CORRECTLY')}</ActionTitle>
              <ActionActions>
                {renderChannelSpecificCta()}
                {isChannel && renderActionButton(goToPredictions, 'GO_TO_PREDICTIONS')}
                {renderProgress()}
              </ActionActions>
            </>
          );
        case ActionKinds.AnswerSpecificPrediction:
          return (
            <>
              <ActionTitle>{t('ANSWER_SPECIFIC_PREDICTION')}</ActionTitle>
              <ActionActions>
                {renderChannelSpecificCta()}
                {isChannel && renderActionButton(openMultipleChoicePanel('prediction'), 'GO_TO_PREDICTION')}
                {renderProgress()}
              </ActionActions>
            </>
          );
        case ActionKinds.AnswerSpecificPredictionCorrectly:
          return (
            <>
              <ActionTitle>{t('ANSWER_SPECIFIC_PREDICTION_CORRECTLY')}</ActionTitle>
              <ActionActions>
                {renderChannelSpecificCta()}
                {isChannel && renderActionButton(openMultipleChoicePanel('prediction'), 'GO_TO_PREDICTION')}
                {renderProgress()}
              </ActionActions>
            </>
          );
      default:
        warning(false, `Unsupported quest action kind: ${action.kind}`);
        return null;
    }
  };

  const renderIcon = () => {
    const count = (action.valueRequired || 1);
    const gotCompleted = progress >= count || completed;

    if (gotCompleted) {
      return <QuestActionCompletedIcon />;
    }

    if (claimed) {
      return <QuestActionClaimedIcon />;
    }

    if (failed) {
      return <QuestActionOpenWrapper><QuestActionFailedIcon /></QuestActionOpenWrapper>;
    }

    return <QuestActionOpenWrapper><QuestActionOpenIcon /></QuestActionOpenWrapper>;
  };

  return (
    <Action className={ACTION}>
      {renderIcon()}
      <ActionInfo>
        {renderAction()}
      </ActionInfo>
    </Action>
  );
};

export default QuestAction;
