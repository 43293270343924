import React, { FC } from 'react';
import { isChromaKeyMode } from 'services/user-layout';
import { setActiveAction } from 'services/admin';
import { getChannelOfflineImage } from 'services/admin/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { getShowMobileChannelSelector } from 'services/app/selectors';
import { ActionKey } from 'services/admin/constants';
import { StyledImage, StyledQuickEditOverlay, SvgContainer } from './styles';
import { getAdminChannelOfflineThumbnail } from 'services/themes';
import defaultChannelOfflineThumbnail from 'assets/icons/default-channel-offline-thumbnail.svg';
import AspectRatio from 'components/Video/AspectRatioVideo';
import styled from 'styled-components';

export const StyledAspectRatio = styled(AspectRatio)`
  width: 100%;
`;

const Image: FC = () => {
  const dispatch = useDispatch();
  const showMobileChannelSelector = useSelector(getShowMobileChannelSelector);
  const channelOfflineImage = useSelector(getChannelOfflineImage);
  const isChromaKey = useSelector(isChromaKeyMode);
  const adminThemeChannelOfflineThumbnail = useSelector(
    getAdminChannelOfflineThumbnail,
  );

  const image = channelOfflineImage || adminThemeChannelOfflineThumbnail;

  const onEditImage = React.useCallback(() => {
    dispatch(setActiveAction(ActionKey.content));
  }, []);

  const renderImage = React.useCallback(() => {
    if (!image) {
      return (
        <SvgContainer
          dangerouslySetInnerHTML={{ __html: defaultChannelOfflineThumbnail }}
        />
      );
    }

    return <StyledImage alt="Channel offline content image" src={image} />;
  }, [image]);

  if (showMobileChannelSelector) {
    return null;
  }

  return (
    <StyledQuickEditOverlay
      testId="offlineImageQuickEditOverlay"
      isChromaKeyModeActive={isChromaKey}
      titleKey="ADMIN_IMAGE_OFFLINE_IMAGE"
      onEdit={onEditImage}
    >
      <StyledAspectRatio>{renderImage()}</StyledAspectRatio>
    </StyledQuickEditOverlay>
  );
};

export default Image;
