import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import IState from 'services/state';
import { dismissModal, showModal } from 'services/modals';
import { ModalKinds } from 'services/modals/types';
import {
  accountLookup,
  logIn,
  registerUser,
  dismissLoginError,
  dismissRegisterError,
  setAccountFound,
  IEmailPasswordInfo,
  OauthProvider,
  IRegisterUserInfo,
  resetGlobalAccount,
} from 'services/auth/actions';
import {
  getRegisterError,
  isUserLoggedIn,
  getMaestroLoginFailed,
  getGlobalAccountAdminSites,
} from 'services/auth/selectors';
import {
  getAuths,
  isMaestroLoginEnabled,
  getAccountFound,
  getCustomTextModal,
  getMarketingOptin,
  getMarketingOptinLabel,
} from 'services/app/selectors';
import AuthModal from './view';
import { IS_MARKETING_SITE } from 'config';

const mapStateToProps = (state: IState) => ({
  accountFound: getAccountFound(state),
  auths: getAuths(state),
  customModal: getCustomTextModal(state),
  enableMaestroLogin: isMaestroLoginEnabled(state),
  loggedIn: isUserLoggedIn(state),
  loginFailed: getMaestroLoginFailed(state),
  marketingOptin: getMarketingOptin(state),
  marketingOptinLabel: getMarketingOptinLabel(state),
  registerError: getRegisterError(state) || '',
  globalAccountAdminSites: getGlobalAccountAdminSites(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  clearAccountLookup: () => dispatch(setAccountFound(null)),
  cleanGlobalAccount: () => dispatch(resetGlobalAccount()),
  dismissError: () => {
    dispatch(dismissLoginError());
    dispatch(dismissRegisterError());
  },
  onAccountLookup: ({ email }: { email: string }) => dispatch(accountLookup({ email, globalAccountAuth: IS_MARKETING_SITE })),
  onDismiss: () => dispatch(dismissModal()),
  onEmailLogin: ({ email, password, provider, subscriptionCheckoutData }: IEmailPasswordInfo) => {
    return dispatch(logIn({
      email,
      password,
      provider,
      strategy: 'email',
      subscriptionCheckoutData,
      globalAccountAuth: IS_MARKETING_SITE,
    }));
  },
  onForgotPassword: () => dispatch(showModal({ kind: ModalKinds.forgotPassword })),
  onOauthLogin: (provider: OauthProvider) => dispatch(logIn({ provider, strategy: 'oauth' })),
  onRegister: (creds: IRegisterUserInfo) => dispatch(registerUser(creds)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AuthModal);
