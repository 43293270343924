import styled from 'styled-components';
import Icon from 'components/ui/Icon';
import {
  SPACING_X_SMALL,
  TEXT_100,
  ACCENT_PRIMARY,
} from 'style/constants';
import { TEXT_H5, TEXT_H6_BOLD } from 'style/mixins';
import { css } from 'styled-components';

export const Container = styled.div`
  height: 44px;
  width: 100%;
  display: flex;
  padding: 10px 20px;
  z-index: 1;
  position: fixed;
`;

export const Blur = styled.div<{
  betweenFramesAmount?: number;
  displayBlur?: boolean;
}>`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
${({ betweenFramesAmount = 0 }) =>
  betweenFramesAmount &&
  css`
    // Animation to make it appear gradually.
    height: ${Math.min(betweenFramesAmount, 44)}px;
    animation: fadeIn 0.5s;
    background: rgba(58, 58, 58, 0.32);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(11.6px);
    -webkit-backdrop-filter: blur(11.6px);
    border: 1px solid rgba(58, 58, 58, 0.02);
  `}}
`;

export const Logo = styled.img`
  height: 24px;
  width: auto;
  position: fixed;
  top: 10px;
  left: 20px;
`;

export const Right = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

export const Button = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
`;

export const MenuIcon = styled(Icon).attrs((props) => ({
  color: TEXT_100(props),
  name: 'menu',
}))`
  display: flex;
`;

export const LoginText = styled.div`
  ${TEXT_H6_BOLD}
  text-transform: uppercase;
  color: ${TEXT_100};
`;

export const LogoText = styled.div`
  ${TEXT_H5}
`;

export const ProfPic = styled.div<{ src: string }>`
  background: url(${(props) => props.src});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 24px;
  height: 24px;
  width: 24px;
  margin-left: ${SPACING_X_SMALL};
  position: relative;
`;

export const ProfPicEllipse = styled(Icon).attrs((props) => ({
  color: ACCENT_PRIMARY(props),
  name: 'panelsProfPicEllipse',
}))`
  position: absolute;
  left: 0;
  top: -0.5px;
  & svg {
    width: 26.5px;
    height: 26px;
  }
`;
