export const momentFormats = {
  ampm: 'A',
  compactDate: 'MMM Do, YYYY',
  compactDatetime: 'MMM Do, YYYY h:mma',
  compactPrettydate: 'dddd, MMMM D',
  compactTime: 'h:mm',
  date: 'MMMM Do, YYYY',
  datetime: 'MMM Do, YYYY h:mma',
  md: 'MMM D',
  mdy: 'MMM D, YYYY',
  prettydate: 'dddd, MMMM D, YYYY',
  time: 'h:mm A',
};

export const localizedFormats = {
  compactDate: 'll',
  compactDatetime: 'lll',
  compactTime: 'LT',
  date: 'LL',
  datetime: 'LLL',
  mdy: 'LL',
  prettydate: 'LLLL',
  time: 'LT',
};

export const dateLocales = {
  ar: 'ar-EG',
  cn: 'zh-CN',
  de: 'de-DE',
  en: 'en-US',
  'es-es': 'es-ES',
  'es-mx': 'es-MX',
  fr: 'fr-FR',
  in: 'hi-IN',
  it: 'it-IT',
  ja: 'ja-JP',
  jp: 'ja-JP',
  ko: 'ko-KR',
  kr: 'ko-KR',
  pl: 'pl-PL',
  'pt-br': 'pt-BR',
  ru: 'ru-RU',
  th: 'th-TH',
  tr: 'tr-TR',
  tw: 'zh-TW',
  vi: 'vi-VN',
};

export const dateOptions = {
  day: { day: 'numeric' },
  month: { month: 'long' },
  prettydate: { day: 'numeric', month: 'long', weekday: 'long' },
  shortMonth: { month: 'short' },
  timezone: { timeZoneName: 'short' },
  weekday: { weekday: 'long' },
  year: { year: 'numeric' },
};
