import React, { CSSProperties, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { isFullscreenActive } from 'services/user-layout/selectors';
import {
  ModalWindowStyleProps,
  ModalWindowContainer,
} from './styles';

const stopPropagation = (e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation();

export interface ModalWindowStyle extends Omit<Partial<ModalWindowStyleProps>, 'anchorDimensions'> {
  getDimensions?: () => DOMRect;
}

export interface ModalWindowProps extends ModalWindowStyle {
  children: React.ReactNode;
  className?: string;
  'data-testid'?: string;
  id?: string;
  onClick?: () => void;
  style?: CSSProperties;
}

export default function ModalWindow({
  getDimensions,
  children= null,
  className= '',
  position,
  separation = 5,
  style= {},
  onClick,
  'data-testid': testId,
}: ModalWindowProps) {
  const [anchorDimensions, setAnchorDimensions] = useState(new DOMRect());
  const isFullscreen = useSelector(isFullscreenActive);

  useEffect(() => {
    const dimensions = getDimensions?.();
    if (dimensions) {
      setAnchorDimensions(dimensions);
    }
  }, [getDimensions, isFullscreen]);

  return (
    <ModalWindowContainer
      className={className}
      data-testid={testId}
      anchorDimensions={anchorDimensions}
      onClick={onClick || stopPropagation}
      position={position}
      separation={separation}
      style={style}
    >
      {children}
    </ModalWindowContainer>
  );
}
