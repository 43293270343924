import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'hooks';

import { logIn, OauthProvider } from 'services/auth';
import Link from 'components/ui/Link';
import epicLogo from 'assets/epic-games-logo.png';
import BaseLoginButton from './BaseLoginButton';
import { MAESTRO_WHITE } from 'style/constants';

export const ImgIcon = styled.img`
  border-radius: 50%;
  border: 3px solid ${MAESTRO_WHITE};
  background: ${MAESTRO_WHITE};
  height: 25px;
  width: 25px;
`;

interface IProps {
  signUp: boolean;
}

// active and working oauth service
const EpicLoginButton = ({ signUp }: IProps) => {
  const dispatch = useDispatch();
  const triggerLogin = () => {
    dispatch(logIn({ provider: OauthProvider.EPIC, strategy: 'oauth' }));
  };

  return (
    <Link onClick={triggerLogin} target="_self">
      <BaseLoginButton
        color="white"
        dataTestId="authModalEpicLoginButton"
        providerNameKey="EPIC"
        renderIcon={ // tslint:disable-next-line: jsx-no-lambda no-empty
          () => <ImgIcon src={epicLogo} />
        }
        signUp={signUp}
      />
    </Link>
  );
};

export default EpicLoginButton;
