import { connect } from 'react-redux';

import { getActive as isInsightsActive, trackAction } from 'services/insights';
import { isEditMode } from 'services/admin';

import InsightsViewable from './InsightsViewable';

const mapStateToProps = state => ({
  enableTracking: !isEditMode(state),
  insightsActive: Boolean(isInsightsActive(state)),
});

const mapDispatchToProps = dispatch => ({
  fire: (kingdom, action, doc, extra) => dispatch(trackAction(kingdom, action, doc, extra)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InsightsViewable);
