import React, { ReactNode } from 'react';
import { Portal } from 'react-portal';
import { MODAL_PORTAL } from 'global-ids';
import styled from 'styled-components';

interface IModalPortalProps {
  children: ReactNode;
}

export default function ModalPortal({
  children,
}: IModalPortalProps) {
  return (
    <Portal node={document && document.getElementById(MODAL_PORTAL)}>
      {children}
    </Portal>
  );
}

export const ModalPortalContainer = styled.div.attrs({ id: MODAL_PORTAL })`
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1000;
`;
