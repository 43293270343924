import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'hooks';

import { logIn, OauthProvider } from 'services/auth';
import Link from 'components/ui/Link';
import googleIcon from 'assets/google-icon-small.png';
import BaseLoginButton from './BaseLoginButton';
import { MAESTRO_WHITE } from 'style/constants';

export const ImgIcon = styled.img`
  border-radius: 50%;
  border: 3px solid ${MAESTRO_WHITE};
  background: ${MAESTRO_WHITE};
  height: 25px;
  width: 25px;
`;

interface IProps {
  signUp: boolean;
}

// active and working oauth service
const GoogleLoginButton = ({ signUp }: IProps) => {
  const dispatch = useDispatch();
  const triggerLogin = () => {
    dispatch(logIn({ provider: OauthProvider.GOOGLE, strategy: 'oauth' }));
  };

  return (
    <Link onClick={triggerLogin} target="_self">
      <BaseLoginButton
        color="#0076FE"
        dataTestId="authModalGoogleLoginButton"
        providerNameKey="GOOGLE"
        renderIcon={ // tslint:disable-next-line: jsx-no-lambda no-empty
          () => <ImgIcon src={googleIcon} />
        }
        signUp={signUp}
      />
    </Link>
  );
};

export default GoogleLoginButton;
