import { useRealtimeDocument } from 'hooks';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getHomeId,
  getSiteDomain,
} from 'services/app';
import MobileHeader from '../MobileHeader';
import Skeleton from 'components/ui/Skeleton';
import MobileTabBar from '../MobileTabBar';
import { MobileNavContent, Wrapper } from './styles';
import useMobileLayout from './use-mobile-layout';
import MobileNavigation from '../MobileNavigation';
import MobileUserProfile from '../MobileUserProfile';
import { trackHeader } from 'services/insights';
import AuthModal from 'components/modals/AuthModal';

const MobileLayout: React.FC = () => {
  const homeId = useSelector(getHomeId);
  const [_, homePageLoaded] = useRealtimeDocument('objects', homeId);
  const { activeMobileMenu, resetMobileMenu } = useMobileLayout();
  const siteDomain = useSelector(getSiteDomain);
  const dispatch = useDispatch();

  const renderMobileTabBar = useCallback(() => {
    return homePageLoaded ? (
    <MobileTabBar />
    ) : (
    <Skeleton height="54px" type="mobileHeader" />
    );
  }, [homePageLoaded]);

  const onTrackNav = (doc, extra) =>
    dispatch(trackHeader('engage', doc, extra));

  const renderMobileNavItem = useCallback(() => {
    switch (activeMobileMenu) {
      case 'searchMenu':
        return <MobileNavigation onClose={resetMobileMenu} />;
      case 'loginMenu':
        return <AuthModal dismissOverride={resetMobileMenu} />;
      case 'profileMenu':
        return <MobileUserProfile onClose={resetMobileMenu} />;
      default:
        return null;
    }
  }, [activeMobileMenu, resetMobileMenu, siteDomain, onTrackNav]);

  return (
    <Wrapper>
      <MobileHeader />
      <MobileNavContent>{renderMobileNavItem()}</MobileNavContent>
      {renderMobileTabBar()}
    </Wrapper>
  );
};

export default MobileLayout;
