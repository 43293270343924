/* eslint react/destructuring-assignment: off */
import PropTypes from 'prop-types';
import React from 'react';
import warning from 'warning';

import { VIEW_KINGDOMS } from 'services/insights';

export default class InsightsViewable extends React.Component {
  static propTypes = {
    action: PropTypes.string,
    children: PropTypes.node,
    doc: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    enableTracking: PropTypes.bool,
    extra: PropTypes.any, // eslint-disable-line react/forbid-prop-types
    fire: PropTypes.func.isRequired,
    insightsActive: PropTypes.bool.isRequired,
    kingdom: PropTypes.oneOfType([
      PropTypes.func.isRequired, // This should really only be used for debugging
      PropTypes.oneOf(VIEW_KINGDOMS),
    ]).isRequired,
    // eslint-disable-next-line react/forbid-prop-types, react/no-unused-prop-types
    refreshKey: PropTypes.any,
    visible: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    action: 'view',
    children: null,
    doc: null,
    enableTracking: true,
    extra: undefined,
    refreshKey: undefined,
  };

  insightsActiveOnLastView = true;

  componentDidMount() {
    if (this.props.visible && this.props.enableTracking) {
      this.fireViewEvent();
    }
  }

  componentDidUpdate(prevProps) {
    if (!this.props.visible) {
      return;
    }
    const becameVisible = !prevProps.visible;
    const sessionStarted = !prevProps.insightsActive && this.props.insightsActive;
    const refreshed = prevProps.refreshKey !== this.props.refreshKey;
    // Only trigger view event if:
    // - visible changes from false to true
    // - insights session starts AND visible is true
    const shouldFire = (
      becameVisible || sessionStarted || refreshed
    ) && this.props.enableTracking;
    if (shouldFire) {
      // If this event was triggered by a new Insights session ONLY, but Insights was NOT active on
      // the previous view event, then we have already fired a view event.
      if (!becameVisible && sessionStarted && !this.insightsActiveOnLastView) {
        this.insightsActiveOnLastView = true;
        return;
      }
      this.fireViewEvent();
    }
  }

  fireViewEvent() {
    const { action, doc, extra, fire, insightsActive, kingdom } = this.props;
    if (typeof kingdom === 'string') {
      fire(kingdom, action, doc, extra);
    } else if (typeof kingdom === 'function') {
      kingdom(action, doc, extra);
    } else {
      warning(
        false,
        `Expected kingdom to be a string or function but received: ${
          JSON.stringify(kingdom, null, 2)}`,
      );
    }
    this.insightsActiveOnLastView = insightsActive;
  }

  render() {
    return this.props.children;
  }
}
