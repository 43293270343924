import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import isEqual from 'lodash/isEqual';
import { useDispatch, useSelector } from 'react-redux';
import { isUserLoggedIn } from 'services/auth';
import { checkGate, refreshUser } from 'services/auth/actions';
import { loginModal } from 'services/modals/actions';
import {
  getApiLoading,
  getFixedGateData,
  userHasSubscriptions,
  isAccessCodeEntryValid,
} from 'services/gate/selectors';
import {
  submitAccessCode,
  setAccessCodeEntryValid,
} from 'services/gate/actions';
import { bw } from 'colors';
import { GATE_ACCESS_CODE_ID } from 'global-ids';
import {
  DATE_TIME,
  SUBMIT,
  FORM_LABEL,
  FORM_INPUT,
} from 'injection-classes';
import Spinner from 'components/ui/Spinner';
import Icon from 'components/ui/Icon';
import AccessCode from './AccessCode';
import {
  Container,
  Label,
  Button,
  StyledDateAndTime,
  Form,
} from './styles';
import { SURFACE_3 } from 'style/constants';
import { DynamicTranslationType, useAdminTranslation, useEndUserTranslation } from 'hooks/use-translation';
import GateTitle from './GateTitle';
import GateSubtitle from './GateSubtitle';

const spinnerStyle = {
  width: '30px',
};

interface IProps {
  handleBack?: () => void;
  isTransparent?: boolean;
}

const BackLink = styled.div`
  cursor: pointer;
  display: inline-block;
  text-transform: uppercase;
  background: ${SURFACE_3};
  color: ${(props) => bw(SURFACE_3(props))};
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
  padding: 6px 5px;
  border-radius: 4px;
  text-align: center;
  margin-bottom: 20px;

  span {
    margin-left: 3px;
  }
`;

const BackArrow = styled(Icon).attrs({
  name: 'left-open',
})`
  &::before {
    margin: 0;
  }
`;

interface IAccessCodeGateAccessControlElements {
  isTransparent?: boolean;
}

export const AccessCodeGateAccessControlElements = ({
  isTransparent,
}: IAccessCodeGateAccessControlElements) => {
  const { t } = useAdminTranslation();
  const loading = useSelector(getApiLoading);
  const [accessCode, setAccessCode] = useState<string>('');
  const validAccessCode = useSelector(isAccessCodeEntryValid);
  const hasAccessCodeError = useMemo(
    () => validAccessCode === false && !loading,
    [validAccessCode, loading],
  );

  const dispatch = useDispatch();
  const loggedIn = useSelector(isUserLoggedIn);
  const hasAccess = useSelector(userHasSubscriptions(t));

  const { endUserT } = useEndUserTranslation();

  useEffect(() => {
    if (loggedIn && accessCode) {
      dispatch(submitAccessCode({ accessCode }));
    } else if (!loggedIn) {
      dispatch(setAccessCodeEntryValid(null));
    }
  }, [loggedIn]);

  useEffect(() => {
    if (hasAccess) {
      dispatch(checkGate());
    }
  }, [hasAccess]);

  function handleSubmit(event: React.FormEvent) {
    event.preventDefault();

    if (loggedIn) dispatch(submitAccessCode({ accessCode }));
    else dispatch(loginModal());
  }

  return (
    <Form onSubmit={handleSubmit} data-testid="accessCodeGateSection">
      <Label className={FORM_LABEL}>
        {endUserT([DynamicTranslationType.gateEnterCode], ['ENTER_CODE'])}
      </Label>
      <AccessCode
        className={FORM_INPUT}
        setAccessCode={setAccessCode}
        hasError={hasAccessCodeError}
        loggedIn={loggedIn}
        isTransparent={isTransparent}
      />
      {hasAccessCodeError && (
        endUserT([DynamicTranslationType.gateInvalidAccessCode], ['INVALID_ACCESS_CODE'])
      )}
      <Button
        className={SUBMIT}
        type="submit"
        disabled={loading}
        hasError={hasAccessCodeError}
      >
        {
          loading
            ? <Spinner style={spinnerStyle} />
            : endUserT([DynamicTranslationType.globalActionSubmit], ['SUBMIT'])
        }
      </Button>
    </Form>
  );
};

const AccessTokenGate = ({ handleBack, isTransparent }: IProps) => {
  const { t } = useAdminTranslation();
  const { endUserT } = useEndUserTranslation();
  const { timestamp } = useSelector(getFixedGateData(t), isEqual);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(refreshUser());
  }, []);

  return (
    <Container id={GATE_ACCESS_CODE_ID} data-testid="access-token-gate">
      {handleBack && (
        <BackLink onClick={handleBack}>
          <BackArrow />
          {endUserT([DynamicTranslationType.globalActionBack], ['BACK'])}
        </BackLink>
      )}
      {timestamp && <StyledDateAndTime className={DATE_TIME} timeStamp={timestamp} />}
      <GateTitle />
      <GateSubtitle />
      <AccessCodeGateAccessControlElements isTransparent={isTransparent} />
    </Container>
  );
};

export default AccessTokenGate;
