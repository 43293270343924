import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import InsightsViewable from 'components/insights/InsightsViewable';
import { trackHeader } from 'services/insights';
import { showModal } from 'services/modals';
import { useSelector, useDispatch } from 'hooks';
import { getParentOrigin } from 'services/iframe';

import {
  getCountdownData,
  getTimeOffset,
} from 'services/app/selectors';
import { TEXT_100 } from 'style/constants';
import Icon from 'components/ui/Icon';
import { CountdownNumberContainer } from 'components/ui/Countdown/view';
import { computeTimeLeft } from 'components/ui/Countdown/CountdownProvider';
import { useAdminTranslation } from 'hooks/use-translation';
import {
  CountdownEndDate,
  CountdownWrapper,
  FormattedDate,
  FormattedMonth,
  FlexWrapper,
  Message,
  MobileCountdownContainer,
} from './mobileStyles';

const CloseIcon = styled(Icon).attrs({
  name: 'cancel-thin',
})`
  color: ${TEXT_100};
  position: absolute;
  right: 8px;
  top: 8px;
`;

const formatDateTime = (time) => {
  const fullTime = new Date(time);
  const shortHour = fullTime.getHours() % 12 || 12;
  const period = fullTime.getHours() < 12 ? 'AM' : 'PM';
  const minutes = fullTime.getMinutes().toString().padStart(2, '0');

  return `${shortHour}:${minutes} ${period}`; // 2:33 PM
};

const MobileOverlayCountdown = ({ className, onCancelClick }) => {
  const { startTime, title: message } = useSelector(getCountdownData);
  const [showCountdown, setshowCountdown] = useState(true);
  const serverOffset = useSelector(getTimeOffset);
  const dispatch = useDispatch();
  const onAddToCalendar = data => dispatch(showModal({ data, kind: 'addToCalendar' }));
  const onEngage = data => dispatch(trackHeader('engage', data, {}));
  const href = useSelector(getParentOrigin) || window.location.href;
  const { t } = useAdminTranslation();

  const handleCalendarClick = useCallback(() => {
    onAddToCalendar({
      description: t('ADMIN_OVERLAY_COUNTDOWN_AT_X', { x: href }),
      endTime: formatDateTime(Date.parse(startTime) + 1000 * 60 * 60),
      engagementTrackingAction: trackHeader(
        'engaged',
        { kind: 'countdown', title: message },
        { action: 'add to calendar' },
      ),
      location: href,
      startTime,
      title: message,
    });
    onEngage({
      kind: 'countdown',
      title: message,
    });
  }, [href, onAddToCalendar, onEngage, message, startTime]);

  if (!computeTimeLeft(startTime, serverOffset) || !showCountdown) {
    return null;
  }

  return (
    <MobileCountdownContainer>
      <InsightsViewable
        doc={{ kind: 'countdown', title: message }}
        kingdom="header"
        visible
      />
      <CloseIcon
        onClick={onCancelClick}
      />
      <Message>
        {message}
      </Message>
      <CountdownWrapper
        className={className}
        onClick={() => handleCalendarClick({
          href,
          message,
          onAddToCalendar,
          onEngage,
          startTime,
        })}
      >
        <CountdownEndDate>
          <FormattedMonth show="month" timestamp={new Date(startTime)} />
          <FormattedDate show="day" timestamp={new Date(startTime)} />
        </CountdownEndDate>
        <FlexWrapper>
          <CountdownNumberContainer
            className={className}
            rerenderCallback={setshowCountdown}
            serverOffset={serverOffset}
            startTime={startTime}
          />
        </FlexWrapper>
      </CountdownWrapper>
    </MobileCountdownContainer>
  );
};

MobileOverlayCountdown.propTypes = {
  className: PropTypes.string,
  onCancelClick: PropTypes.func.isRequired,
};

MobileOverlayCountdown.defaultProps = {
  className: '',
};

export default MobileOverlayCountdown;
