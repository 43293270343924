import React, { useEffect, useState } from 'react';
import Link from 'components/ui/Link';
import { useSelector } from 'hooks';
import {
  getLogoImage,
  getLogoLink,
  getLogoTitle,
  getGateLogo,
  getGateLogoLink,
  getGateLogoTitle,
  isLandingPageType,
} from 'services/app/selectors';
import { shouldRenderChannelGate } from 'services/gate/selectors/common';
import { MOBILE_HEADER_ID } from 'global-ids';
import { Blur, Container, Logo, LogoText } from './styles';
import { ID_MOBILE_CONTENT } from 'services/renderer/ids';

const MobileHeader: React.FC = () => {
  const channelLogoImageUrl = useSelector(getLogoImage);
  const channelLogoText = useSelector(getLogoTitle);
  const channelLogoLink = useSelector(getLogoLink);
  const gateLogoImageUrl = useSelector(getGateLogo);
  const gateLogoText = useSelector(getGateLogoTitle);
  const gateLogoLink = useSelector(getGateLogoLink);
  const isChannelGateOn = useSelector(shouldRenderChannelGate);
  const logoImageUrl = isChannelGateOn ? gateLogoImageUrl : channelLogoImageUrl;
  const logoLink = isChannelGateOn ? gateLogoLink : channelLogoLink;
  const logoText = isChannelGateOn ? gateLogoText : channelLogoText;
  const isInALandingPage = useSelector(isLandingPageType);
  const [pageContentScrollTop, setPageContentScrollTop] = useState(0);

  const hasImageSrc = logoImageUrl && logoImageUrl !== 'noimage';
  const LinkComponent = logoLink ? Link : React.Fragment;
  const linkProps = logoLink ? { logoLink, plain: true } : {};

  useEffect(() => {
    const pageScrollableContainer = document.getElementById(ID_MOBILE_CONTENT);
    const handleScroll = () => {
      if (pageScrollableContainer) {
        setPageContentScrollTop(pageScrollableContainer.scrollTop);
      }
    };

    if (pageScrollableContainer) {
      pageScrollableContainer.addEventListener('scroll', handleScroll);

      return () => {
        pageScrollableContainer.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);

  /**
   * If we're not in a landing page, we don't want to render the header.
   */
  if (!isInALandingPage || (!logoText && !hasImageSrc)) {
    return null;
  }

  return (
    <Container id={MOBILE_HEADER_ID}>
      <Blur betweenFramesAmount={pageContentScrollTop} />
      <LinkComponent {...linkProps}>
        {hasImageSrc ? (
          <Logo src={logoImageUrl} />
        ) : (
          <LogoText>{logoText}</LogoText>
        )}
      </LinkComponent>
    </Container>
  );
};

export default MobileHeader;
