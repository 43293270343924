import QuickEditOverlay from 'components/admin-bridge/QuickEditOverlay';
import { CHROMA_KEY_COLOR } from 'style/constants';
import { THEME_PADDING, USE_SITE_THEME_COLORS_ON_SVG, desktopOnly, mobileOnly } from 'style/mixins';
import styled from 'styled-components';
import { css } from 'styled-components';

const IMAGE_CSS = css`
  aspect-ratio: 16 / 9;
  height: 100%;
  width: 100%;
  ${desktopOnly`border-radius: 5px;`}
`;

export const SvgContainer = styled.div`
  ${IMAGE_CSS}
  display: flex;
  justify-content: center;
  align-items: center;

  & svg {
    height: 100%;
    ${desktopOnly`border-radius: 5px;`}
    ${USE_SITE_THEME_COLORS_ON_SVG}
  }
`;

export const StyledImage = styled.img`
  ${IMAGE_CSS}
`;

export const StyledQuickEditOverlay = styled(QuickEditOverlay)<{ isChromaKeyModeActive: boolean; }>`
  width: 100%;
  display: flex;
  ${THEME_PADDING}
  padding-top: 0;
  padding-bottom: 13px;
  justify-content: center;
  background: ${({ isChromaKeyModeActive }) => isChromaKeyModeActive ? CHROMA_KEY_COLOR : 'transparent'};
  ${mobileOnly`
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0;
  `}
`;
