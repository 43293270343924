import React from 'react';
import { useAdminTranslation } from 'hooks/use-translation';
import RelativeTimestamp from './view';

export default (props) => {
  const { currentLocale } = useAdminTranslation();
  return (
    <RelativeTimestamp
      {...props}
      locale={currentLocale}
    />
  );
};
