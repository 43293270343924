import { connect } from 'react-redux';
import { openPanel } from 'services/custom-panels';
import { getObjectId } from 'services/app/selectors';
import QuestAction from './view';

const mapStateToProps = state => ({
  pageId: getObjectId(state),
});

const mapDispatchToProps = dispatch => ({
  openPanel: doc => dispatch(openPanel(doc)),
});

export default connect(mapStateToProps, mapDispatchToProps)(QuestAction);
