import { isUndefined } from 'lodash';

interface IPanel {
    arrayId?: string,
    id?: string,
    isActive?: boolean,
}

export const isActivePanel = (panel: IPanel): boolean => {
    if (!panel) return false;
    return !isUndefined(panel.isActive) ? panel.isActive : true;
};
