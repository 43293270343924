/* eslint react/destructuring-assignment: off */
import React from 'react';
import PropTypes from 'prop-types';
import RendererProvider, {
  ID_MOBILE_CONTENT,
  ID_SIDEBAR,
} from 'components/core/RendererProvider';
import Sidebar from 'components/regions/Sidebar';
import styled from 'styled-components';
import Background from 'components/regions/Content/Background';
import { SURFACE_1 } from 'style/constants';
import { isActivePanel } from 'components/objects/PanelV2/utils';
import LandingPageGrid from 'components/regions/LandingPageGrid';

const StyledBackground = styled(Background)`
  z-index: 0;
  top: 0;
  left: 0;
  position: fixed;
`;

const Container = styled.div`
  display: ${({ hidden }) => hidden ? 'none' : 'flex'};
  flex: 1;
  z-index: 1;
  flex-direction: column;
  overflow-y: auto;
  background-color: ${props => {
    return props.isOverlayEmbed ? 'transparent' : SURFACE_1(props);
  }};
`;

const StyledSidebar = styled(Sidebar)`
  flex: 1;
  width: 100%;
`;

const Content = styled.div`
  width: 100%;
  max-height: ${({ sidebarVisible }) => sidebarVisible ? '56.25vw' : '100%'};
  &:not(:empty) {
    height: 100%;
  }
  position: relative;
  overflow: hidden auto;
`;

export default class MobileBody extends React.Component {
  static propTypes = {
    isChannelGateOn: PropTypes.bool.isRequired,
    isChannelSelectorSidebarButtonVisible: PropTypes.bool.isRequired,
    isEmbed: PropTypes.bool.isRequired,
    isLandingPage: PropTypes.bool.isRequired,
    isLandscape: PropTypes.bool.isRequired,
    isMobileLayout: PropTypes.bool.isRequired,
    isWelcomeScreenActive: PropTypes.bool.isRequired,
    showMobileChannelSelector: PropTypes.bool.isRequired,
    vodQueryParam: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
  };

  static defaultProps = {
    vodQueryParam: null,
  };

  state = {};

  render() {
    const {
      isEmbed,
      isLandscape,
      isMobileLayout,
      isWelcomeScreenActive,
      vodQueryParam,
      isLandingPage,
      isChannelSelectorSidebarButtonVisible,
      showMobileChannelSelector,
      isChannelGateOn,
    } = this.props;

    const visible = isMobileLayout && !isLandscape && !isWelcomeScreenActive && !isEmbed;
    const shouldShowGrid = isLandingPage && !vodQueryParam;

    return (
      <Container hidden={!visible}>
        {!showMobileChannelSelector && !isChannelSelectorSidebarButtonVisible && (
          <Content id={ID_MOBILE_CONTENT} sidebarVisible={isChannelSelectorSidebarButtonVisible}>
            {shouldShowGrid && (
              <>
                {!isChannelGateOn && <StyledBackground channelLoaded />}
                <LandingPageGrid />
              </>
            )}
          </Content>
        )}
        <RendererProvider id={ID_SIDEBAR}>
          {
            (renderer) => {
              const activePanels = renderer?.items?.filter(item => isActivePanel(item)) || [];
              return activePanels?.length > 0 || isChannelSelectorSidebarButtonVisible ? (
                <StyledSidebar
                  hidden={!isChannelSelectorSidebarButtonVisible && shouldShowGrid}
                  renderer={renderer}
                />
              ) : null;
            }
        }
        </RendererProvider>
      </Container>
    );
  }
}
