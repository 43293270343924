const videoLibraryModalTypes = {
  ADMIN_LIBRARY: 'adminLibrary',
  EDIT_MODE: 'editModeVideoPlaylistSelect',
  LIVESTREAM: 'twitchLivestreamSelect',
  OFFLINE_CONTENT: 'offlineContentVideoSelect',
  PLAYLIST_EDIT: 'playlistVideoSelect',
  QUEST_EDIT: 'questVideoSelect',
  SCHEDULE_EDIT: 'scheduleVideoPlaylistSelect',
} as const;

export default videoLibraryModalTypes;

export type TVideoLibraryModalTypes = typeof videoLibraryModalTypes[
  keyof typeof videoLibraryModalTypes
];
