import React from 'react';
import styled from 'styled-components';
import { BroadcastOverlay, Overlay } from 'components/Video/video-content/Overlays';
import userOverlay from 'hooks/use-overlays';

const Container = styled.div`
  width: 1280px;
  height: 720px;
  background-color: transparent;
  display: block;
`;

const OverlayContainer = styled.div`
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
`;

const CompositePage = () => {
  const { activeOverlay, overlayStatusClass, targetOverlay } = userOverlay();

  return (
    <Container>
      <OverlayContainer>
        {activeOverlay && <Overlay overlay={activeOverlay} statusClass={overlayStatusClass} />}
        {targetOverlay && <BroadcastOverlay overlay={targetOverlay} />}
      </OverlayContainer>
    </Container>
  );
};

export default CompositePage;
