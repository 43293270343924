import { connect } from 'react-redux';
import { pendingEmailVerification } from 'services/auth';
import HeaderNotification from './view';

const mapStateToProps = state => ({
  futureVerificationNotice: false,
  showEmailVerificationNotice: pendingEmailVerification(state),
});

export default connect(mapStateToProps)(HeaderNotification);

