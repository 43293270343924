import Link from 'components/ui/Link';
import useDispatch from 'hooks/use-dispatch';
import React from 'react';
import { logIn, OauthProvider } from 'services/auth';
import { MAESTRO_BLACK } from 'style/constants';
import styled from 'styled-components';
import BaseLoginButton from './BaseLoginButton';

export const ImgIconContainer = styled.div`
  height: 30px;
  overflow: hidden;
  position: relative;
  width: 50px;
`;

export const ImgIcon = styled.img`
  background: ${MAESTRO_BLACK};
  height: 50px;
  left: 0;
  position: absolute;
  top: -10px;
  width: 50px;
`;

enum OneFcLogos {
  whiteOnBlack = 'http://static.maestro.io/media/5c4a79fc0f4e4a006144adff/6202ea9dabedf900a7e579a1.png',
  blackOnWhite = 'http://static.maestro.io/media/5c4a79fc0f4e4a006144adff/6202ea4d049fc500a60e248b.png',
}

// active and working oauth service
const OneFcLoginButton = ({ signUp }: { signUp: boolean }) => {
  const dispatch = useDispatch();
  const triggerLogin = () => {
    dispatch(logIn({ provider: OauthProvider.ONE_FC, strategy: 'oauth' }));
  };

  return (
    <Link onClick={triggerLogin} target="_self">
      <BaseLoginButton
        color={MAESTRO_BLACK}
        dataTestId="authModalOneFcLoginButton"
        renderIcon={
          // tslint:disable-next-line:jsx-no-lambda
          () => (
            <ImgIconContainer>
              <ImgIcon src={OneFcLogos.whiteOnBlack} />
            </ImgIconContainer>
          )}
      />
    </Link>
  );
};

export default OneFcLoginButton;
