import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { BACKGROUND_CONTENT } from 'style/mixins';
import UserProfilePanel from 'components/panels/ProfilePanel';

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 3;
  width: 100vw;
  display: flex;
  flex-flow: column nowrap;
  ${BACKGROUND_CONTENT};
`;

const MobileUserProfile = ({ onClose }) => {
  const trackerDoc = {
    data: {
      kind: 'profile',
    },
  };

  return (
    <Container>
      <UserProfilePanel doc={trackerDoc} onClosePanelMobile={onClose} />
    </Container>
  );
};

MobileUserProfile.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default MobileUserProfile;
