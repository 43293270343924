import React, { useState } from 'react';
import Expandable from 'components/ui/Expandable';
import CatalogList, { ICatalogListProps as ICatalogList } from '../CatalogList';
import {
  CatalogWrapper,
  CatalogHeader,
  CatalogTitleWrapper,
  CatalogTitle,
  InfoIconWithTooltip,
  ArrowDropDownIcon,
  CatalogBody,
  StyledSeparator,
} from './styles';
import { useAdminTranslation, TranslationKey } from 'hooks/use-translation';

export interface ICatalogProps {
  catalogLists: ICatalogList[];
  className?: string;
  infoKey: TranslationKey;
  isOpen?: boolean;
  onDelete?: (kindId: string) => void;
  onDuplicate?: (kindId: string) => void;
  onEdit?: (kindId: string) => void;
  onPreview?: (kindId: string) => void;
  onReorder?: (reorderedItems: any[]) => void;
  onToggle?: (kindId: string, checked: boolean) => void;
  parentRef?: any;
  testId?: string;
  titleKey: TranslationKey;
}

const Catalog: React.FC<ICatalogProps> = ({
  titleKey,
  infoKey,
  isOpen: initialIsOpen = true,
  catalogLists,
  className,
  parentRef,
  onToggle,
  onReorder,
  onEdit,
  onDuplicate,
  onDelete,
  onPreview,
  testId,
}) => {
  const { t } = useAdminTranslation();
  const [isOpen, setIsOpen] = useState(initialIsOpen);

  const handleDrowpDownButton = () => {
    setIsOpen(!isOpen);
  };

  return (
    <CatalogWrapper
      ref={parentRef}
      className={className}
      data-testid={testId ? testId : 'catalog'}
    >
      <CatalogHeader isOpen={isOpen}>
        <CatalogTitleWrapper>
          <CatalogTitle>{t(titleKey)}</CatalogTitle>
          <InfoIconWithTooltip name="info" tooltipKey={infoKey} />
        </CatalogTitleWrapper>
        <ArrowDropDownIcon
          name="arrowDropDownDown"
          isOpen={isOpen}
          onClick={handleDrowpDownButton}
        />
      </CatalogHeader>
      <Expandable isExpanded={isOpen}>
        <CatalogBody className="lists">
          {catalogLists.map((catalogListProps, index) => (
            <div key={index}>
              <CatalogList
                {...catalogListProps}
                onToggle={onToggle}
                onReorder={onReorder}
                onEdit={onEdit}
                onDuplicate={onDuplicate}
                onDelete={onDelete}
                onPreview={onPreview}
              />
              {index !== catalogLists.length - 1 && <StyledSeparator />}
            </div>
          ))}
        </CatalogBody>
      </Expandable>
    </CatalogWrapper>
  );
};

export default React.memo(Catalog);
