import styled from 'styled-components';
import CreatableSelect from 'react-select/creatable';
import {
  ADMIN_SURFACE_1, ADMIN_SURFACE_3,
  ADMIN_TEXT_300,
  ADMIN_TEXT_200,
  ADMIN_TEXT_100,
  ADMIN_TEXT_400,
  ADMIN_ACCENT_PRIMARY,
  ADMIN_SURFACE_4,
} from 'style/constants';
import { ADMIN_SCROLL_BAR } from 'style/mixins';
import { UploadZoneWithoutPadding } from 'components/admin2/UploadZone';
import { Button as AdminButton } from 'components/admin2/ui/Button';
import { TTranslator } from 'hooks/use-translation';

export const Button = styled(AdminButton)`
  font-size: 13px;
  padding: 13px 62px;
  line-height: 1;
  height: auto;
`;

export const Container = styled.form`
  padding: 30px;
  box-sizing: border-box;
  background: ${ADMIN_SURFACE_4};
  height: 100%;
  ${ADMIN_SCROLL_BAR}
  overflow: auto;
`;

export const Header = styled.div`
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
`;

export const Subtitle = styled.div`
  font-weight: 400;
  font-size: 15px;
  line-height: 1;
  color: ${ADMIN_TEXT_200};
  padding-top: 15px;
  margin-bottom: 30px;
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

export const FormNameDescSection = styled.div`
  flex: 1;
  padding-right: 20px;
`;

export const FormField = styled.input`
  font-size: 14.5px;
  line-height: 20px;
  font-weight: 400;
  padding: 0;
  border: 12px solid ${ADMIN_SURFACE_3};
  background: ${ADMIN_SURFACE_3};
  border-radius: 4px;
  font-family: inherit;
  color: ${ADMIN_TEXT_100};

  &:is(textarea) {
    resize: none;
    height: 195px;
    ${ADMIN_SCROLL_BAR}
    overflow-y: auto;
  }

  &.embed-code {
    font-family: monospace;
  }
`;

export const FormLabel = styled.label`
  flex: 1;
  font-size: 14px;
  line-height: 1;
  display: flex;
  flex-direction: column;
  font-weight: 500;

  & + & {
    margin-top: 20px;
  }

  & ${FormField} {
    margin-top: 10px;
  }

  i {
    font-size: 14px;
    line-height: 19px;
    color: ${ADMIN_TEXT_300};
    margin-top: 10px;
    font-style: normal;
  }
`;

export const UploadZone = styled(UploadZoneWithoutPadding)`
  margin-top: 10px;
  width: 320px;
  aspect-ratio: 16 / 9;
`;

export const Footer = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: right;
`;


export const TagsSelect = styled(CreatableSelect).attrs<{ t: TTranslator }>(
  props => ({
    styles: {
      control: (base) => ({
        ...base,
        border: 'none',
        outline: 'none',
        height: 40,
        borderRadius: 4,
        paddingLeft: 10,
        background: ADMIN_SURFACE_3(props),
        boxShadow: 'none',
      }),
      clearIndicator: () => ({
        display: 'none',
      }),
      indicatorsContainer: (base) => ({
        ...base,
        height: 40,
        cursor: 'pointer',
      }),
      indicatorSeparator: () => ({
        display: 'none',
      }),
      placeholder: (base) => ({
        ...base,
        color: ADMIN_TEXT_400(props),
        cursor: 'text',
      }),
      valueContainer: (base) => ({
        ...base,
        padding: 0,
      }),
      menu: (base) => ({
        ...base,
        background: ADMIN_SURFACE_3(props),
      }),
      option: (base, selectProps) => ({
        ...base,
        background: selectProps.isFocused ? ADMIN_ACCENT_PRIMARY(props) : 'none',
        cursor: 'pointer',
      }),
    },
    noOptionsMessage: () => props.t('ADMIN_MULTI_SELECTOR_NO_OPTIONS_AVAILABLE'),
    maxMenuHeight: 250,
  }),
)`
  margin-top: 10px;
`;

export const TagsList = styled.ul`
  list-style: none;
  margin: 10px 0 0;
  padding: 0;

  li {
    display: inline-flex;
    align-items: center;
    background: ${ADMIN_SURFACE_1};
    color: ${ADMIN_TEXT_100};
    font-size: 11px;
    line-height: 15px;
    font-weight: 400;
    padding: 0 10px 0 0;
    border-radius: 10px;
    line-height: 21px;
    margin: 0 5px 5px 0;

    button {
      line-height: inherit;
      padding: 0 10px;

      span {
        display: contents;
      }

      svg {
        width: 9px;
        height: 9px;
      }
    }
  }
`;

export const BulkListWrapper = styled.div``;

export const BulkListTitle = styled.p`
  margin: 0px auto 10px;
  color: ${ADMIN_TEXT_200};
  font-size: 14px;
  line-height: 14px;
`;

export const BulkList = styled.ol`
  padding-left: 20px;
  margin-bottom: 20px;
  color: ${ADMIN_TEXT_100};
  opacity: 0.5;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
`;
