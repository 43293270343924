/* eslint react/destructuring-assignment: off */
import memoizeOne from 'memoize-one';
import React, { lazy, Suspense, useMemo } from 'react';
import warning from 'warning';
import { TITLE } from 'injection-classes';
import IFramePanel from 'components/panels/IFramePanel';
import { computePanelRendererFromDoc } from 'services/renderer';
import { DebugJson, PanelTitle, PanelWrapper, StyledPanel } from './styles';
import {
  SOCIAL_ID,
  CHAT_ID,
  PEOPLE_ID,
  IMAGE_V2_ID,
  CHANNEL_SELECT_ID,
  MULTIPLE_CHOICE_ID,
  PLAYLIST_ID,
  QUEST_ID,
  SCHEDULE_ID,
  TIPS_ID,
  TWITCH_CHAT_ID,
  LEADERBOARD_ID,
  FORTNITE_DUOS_MATCH_ID,
  FORTNITE_PLAY_BY_PLAY_ID,
  FORTNITE_SINGLES_MATCH_ID,
  PLAY_BY_PLAY_ID,
  PUBG_MATCH_ID,
  SHOPIFY_BLOCK_PANEL_ID,
  WELCOME_PANEL_ID,
  FORTNITE_LEADERBOARD_BLOCK_PANEL_ID,
  QUESTS_BLOCK_PANEL_ID,
} from '../PanelV2/constants';
import Skeleton from 'components/ui/Skeleton';
import withRealtimeDocument from 'components/core/withRealtimeDocument';
import withErrorGuard from 'components/core/withErrorGuard';
import { useSelector } from 'react-redux';
import {
  getPreviewPanelRenderer,
  isEditMode as isEditModeSelector,
} from 'services/admin';
import { isMobileLayout as isMobileLayoutSelector } from 'services/device/selectors';
import IState from 'services/state';
import hash from 'json-stable-stringify';
import { getPendingPagePanelsChanges } from 'services/admin/selectors';
import IPanel from 'models/IPanel';
import { getPageId } from 'services/app/selectors';
import { useLocalizedPanelTitle } from 'hooks/localization/useLocalizedPanelTitle';

export interface PanelProps {
  className?: string;
  doc: any;
  fade?: boolean;
  hidden?: boolean;
  id?: string;
  isEditMode?: boolean;
}

// panel_id (type) to component
const PANEL_MAP = {
  [LEADERBOARD_ID]: () => import('components/panels/LeaderboardPanel'),
  [CHANNEL_SELECT_ID]: () => import('components/panels/ChannelSelectPanel'),
  [CHAT_ID]: () => import('components/panels/ChatPanel'),
  [FORTNITE_DUOS_MATCH_ID]: () => import('components/panels/FortniteDuosMatchPanel'),
  [FORTNITE_PLAY_BY_PLAY_ID]: () => import('components/panels/FortnitePlayByPlayPanel'),
  [FORTNITE_SINGLES_MATCH_ID]: () =>
    import('components/panels/FortniteSinglesMatchPanel'),
  [IMAGE_V2_ID]: () => import('components/panels/ImagePanel'),
  [MULTIPLE_CHOICE_ID]: () => import('components/panels/MultipleChoicePanel'),
  [PEOPLE_ID]: () => import('components/panels/PeopleGroupsPanel'),
  [PLAY_BY_PLAY_ID]: () => import('components/panels/PlayByPlayPanel'),
  [PLAYLIST_ID]: () => import('components/panels/PlaylistPanel'),
  profile: () => import('components/panels/ProfilePanel'),
  [PUBG_MATCH_ID]: () => import('components/panels/PubgMatchPanel'),
  [QUEST_ID]: () => import('components/panels/QuestPanel'),
  [SCHEDULE_ID]: () => import('components/panels/SchedulePanel'),
  [SOCIAL_ID]: () => import('components/panels/SocialPanel'),
  [TIPS_ID]: () => import('components/panels/TippingPanel'),
  [TWITCH_CHAT_ID]: () => import('components/panels/TwitchChatPanel'),
  [WELCOME_PANEL_ID]: () => import('components/panels/SSUWelcomePanel'),
  [SHOPIFY_BLOCK_PANEL_ID]: () => import('components/page-block-panel/ShopifyBlockPanel'),
  [FORTNITE_LEADERBOARD_BLOCK_PANEL_ID]: () => import('components/page-block-panel/FortniteLeaderboardBlockPanel'),
  [QUESTS_BLOCK_PANEL_ID]: () => import('components/page-block-panel/QuestsBlockPanel'),
};

interface IPanelBody {
  data: any;
  doc: IPanel<{}>;
  hidden: boolean;
  renderer: IPanel<{}>['renderer'];
}

type IFramePanelType = IPanel<{
  iframeTag: string;
  url: string;
}>;

const PanelBody: React.FC<IPanelBody> = ({ data, hidden, doc, renderer }) => {
  const panelType = renderer?.panelType || data?.kind || '';

  const Component = React.useMemo(() => {
    const loader = (PANEL_MAP as any)[panelType];
    if (!loader) {
      return null;
    }

    return lazy(loader);
  }, [panelType]);

  // iframe cannot be loaded lazily or it will reset everytime we switch
  // as it leverages React.memo
  if (panelType === 'iframe') {
    return (
      <IFramePanel
        doc={doc}
        hidden={false}
        renderer={(renderer as IFramePanelType['renderer'])!}
      />
    );
  }

  if (!Component) {
    // TODO: This should be an error eventually
    return <DebugJson>{JSON.stringify(data)}</DebugJson>;
  }

  return <Component data={data} doc={doc} hidden={hidden} renderer={renderer} />;
};

const renderPanelTitle = (title: string) => {
  const formattedTitle = title.length > 60 ? title.substr(0, 60) : title;

  return (
    <PanelWrapper>
      <PanelTitle
        className={TITLE}
        data-testid="livePanelItemTitle"
        title={formattedTitle}
      >
        <span>{formattedTitle}</span>
      </PanelTitle>
    </PanelWrapper>
  );
};

export const PanelView: React.FC<PanelProps> = ({ id, doc, className, fade, hidden }) => {
  const pageId = useSelector(getPageId);
  const isEditMode = useSelector(isEditModeSelector);
  const isMobileLayout = useSelector(isMobileLayoutSelector);
  const panelPreviewRenderer = useSelector(getPreviewPanelRenderer);
  const pendingPagePanels = useSelector(getPendingPagePanelsChanges);

  const computeRenderer = memoizeOne(computePanelRendererFromDoc);

  const renderer = useMemo(
    () =>
      panelPreviewRenderer ||
      doc.renderer ||
      computeRenderer(doc),
    [
      hash(doc),
      hash(panelPreviewRenderer),
      computeRenderer,
    ],
  );

  const { data, renderer: panelRenderer } = doc;
  const kind = panelRenderer ? panelRenderer.panelType : data?.kind;
  const isTipping = kind === TIPS_ID;
  const isShopifyBlock = kind === SHOPIFY_BLOCK_PANEL_ID;
  const isFortniteLeaderboardV2Block = kind === FORTNITE_LEADERBOARD_BLOCK_PANEL_ID;
  const isQuestsBlock = kind === QUESTS_BLOCK_PANEL_ID;
  const isChat = kind === CHAT_ID;
  const isPlayByPlay = kind === PLAY_BY_PLAY_ID;
  const isMobileChat = isMobileLayout && isChat;

  const publishedTitle = renderer ? renderer.panelName : data?.title;
  const kindId = doc.numCopy ? `${kind}-${doc.numCopy}` : kind;
  const pendingPanelRenderer = pendingPagePanels
    ? pendingPagePanels[kindId]
    : null;

  const title = isEditMode
    ? pendingPanelRenderer?.panelName || publishedTitle
    : publishedTitle;

  const params = {
    pageId,
    panelId: id!,
    panelKind: kind,
    title,
  };

  const [ localeTitle, adminTitle ] = useLocalizedPanelTitle(params);
  const panelTitle = !isEditMode
    ? localeTitle || adminTitle
    : adminTitle;

  const toDisplayRenderer = isEditMode
    ? pendingPanelRenderer || renderer
    : renderer;

  if (!doc) {
    warning(false, `Non-existent panel: id=${id}`);
    return null;
  }

  const shouldRenderPanelTitle = panelTitle && !isMobileChat && !isPlayByPlay && !isTipping && !isShopifyBlock && !isFortniteLeaderboardV2Block && !isQuestsBlock;

  return (
    <Suspense fallback={null}>
      <StyledPanel
        className={className}
        fade={fade}
        hidden={hidden}
        isEditMode={isEditMode}
      >
        {shouldRenderPanelTitle && renderPanelTitle(panelTitle)}
        <PanelBody
          data={data}
          doc={doc}
          hidden={Boolean(hidden)}
          renderer={toDisplayRenderer}
        />
      </StyledPanel>
    </Suspense>
  );
};

const RealtimePanel = withRealtimeDocument({
  collection: 'objects',
  component: PanelView,
  loading: Skeleton,
  mapper: (doc, docLoaded, id) => ({ doc, docLoaded, id }),
});

export default withErrorGuard()(RealtimePanel);
