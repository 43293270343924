import React from 'react';
import IQuest from 'models/IQuest';
import { Section } from 'components/admin-bridge/EditorModal/ModalComponents/Section';
import { ACTION_CONTAINER, HEADER, NAME, QUEST } from 'injection-classes';
import { QUEST_ID } from 'global-ids';
import QuestAction from './QuestAction';
import { ActionsWrapper, CardBackground, Content, Description, QuestTitle, RewardImage, RewardSection, RewardText, RewardWord, ShowMoreButton, ShowMoreContent, Wrapper } from './styles';
import QuestProgressBadge from './QuestStatusBadge';
import QuestCompletedBy from './QuestCompletedBy';
import QuestExpiration from './QuestExpirationBadge';
import useQuestStatus from 'hooks/use-quest-status';
import InsightsViewable from 'components/insights/InsightsViewable';
import { useDispatch } from 'react-redux';
import { showModal } from 'services/modals';
import { ModalKinds } from 'services/modals/types';
import useQuestEndUserTranslationTexts from 'hooks/use-quest-end-user-translation-texts';
import { DynamicTranslationType, useAdminTranslation, useEndUserTranslation } from 'hooks/use-translation';
import QuestClaimInfo from './QuestClaimInfo';
import { IBlockRegion } from 'services/app/models/ILandingPageContent';
import useIsOverflowing from 'hooks/use-is-overflowing';
import useToggle from 'hooks/use-toggle';

export type QuestEngageMetadata = {
  action: 'open_link';
  url: string;
} | {
  action: 'play_video';
  video: string;
} | {
  action: 'inspect reward';
  image: string;
  quest: string;
};

type Props = {
  enableInsights: boolean;
  item: IQuest;
  questEngage: (extra: QuestEngageMetadata) => void;
  region: IBlockRegion;
} & React.HTMLAttributes<HTMLDivElement>;

const QuestCard = ({ item, questEngage, enableInsights, region, ...props }: Props) => {
  const dispatch = useDispatch();
  const ref = React.useRef<HTMLDivElement>(null);
  const [showMoreContent, onShowMoreContentToggle] = useToggle(false);
  const isOverflowing = useIsOverflowing(ref);
  const { t } = useAdminTranslation();
  const { endUserT } = useEndUserTranslation();
  const { inProgress, claimed, available, expired, refreshKey, userQuestActions, completed, failed } = useQuestStatus(item);
  const { description, rewardDescription, rewardText, title } = useQuestEndUserTranslationTexts(item);

  const onClickRewardImage = () => {
    if (!item.rewardImage || !item._id) {
      return;
    }

    questEngage({
      action: 'inspect reward',
      image: item.rewardImage,
      quest: item._id,
    });
    dispatch(showModal({ data: { src: item.rewardImage }, kind: ModalKinds.image }));
  };

  const claimRewardText = React.useMemo(() => {
    if (!available) {
      return endUserT(
        [DynamicTranslationType.questNotAvailable],
        ['NOT_AVAILABLE'],
      );
    }

    if (expired) {
      return endUserT(
        [DynamicTranslationType.questNotClaimed],
        ['DID_NOT_CLAIM'],
      );
    }

    if (item.rewardType === 'raffle') {
      return endUserT(
        [DynamicTranslationType.questSubmitEntry],
        ['ACTION_ENTER_RAFFLE'],
      );
    }

    return endUserT(
      [DynamicTranslationType.questClaimPrize],
      ['CLAIM_YOUR_PRIZE'],
    );
  }, [available, expired, item.rewardType]);

  return (
    <InsightsViewable
      doc={item}
      extra={{ class: region, family: item.title, order: item._id }}
      kingdom="quest"
      refreshKey={refreshKey}
      visible={enableInsights}
    >
      <Wrapper showMoreContent={showMoreContent} ref={ref} className={QUEST} id={`${QUEST_ID}-${item._id}`} {...props}>
        <CardBackground className={HEADER} image={item.background?.type === 'image' ? item.background?.image : undefined} backgroundColor={item.background?.color}>
          <QuestProgressBadge
            inProgress={inProgress}
            claimed={claimed}
            expired={expired}
            timeMaximum={item.timeMaximum}
            timeMinimum={item.timeMinimum}
            available={available}
            completed={completed}
            failed={failed}
          />
          <QuestTitle>
            <h3 className={NAME}>{title}</h3>
            {item._id && <QuestCompletedBy questId={item._id} />}
          </QuestTitle>
        </CardBackground>
        <Content showMoreContent={showMoreContent} expired={expired} available={available} failed={failed} className="quest-content" ref={ref}>
          <Section gap={16}>
            <Section gap={8}>
              {description && <Description className={NAME}>{description}</Description>}
              <QuestExpiration timeMaximum={item.timeMaximum} timeMinimum={item.timeMinimum} />
            </Section>
            <RewardSection>
              <RewardImage src={item.rewardImage} onClick={onClickRewardImage} as={item.rewardImage ? 'img' : 'div'} alt="Quest Reward Image" />
              <Section gap={4}>
                <RewardWord>{rewardText}</RewardWord>
                {rewardDescription && <RewardText>{rewardDescription}</RewardText>}
              </Section>
            </RewardSection>
          </Section>
          <ActionsWrapper className={ACTION_CONTAINER}>
            {userQuestActions.map(action => (
              <QuestAction
                key={action.data.arrayId}
                action={action.data}
                progress={action.progress}
                failed={action.failed}
                claimed={claimed}
                completed={completed}
                questEngage={questEngage}
              />
            ))}
          </ActionsWrapper>
          <QuestClaimInfo
            available={available}
            questId={item._id!}
            rewardType={item.rewardType}
            claimed={claimed}
            claimedMessage={item.claimedMessage}
            claimRewardText={claimRewardText}
            completed={completed}
            expired={expired}
          />
          {(isOverflowing || showMoreContent) && (
            <ShowMoreContent>
              <ShowMoreButton onClick={onShowMoreContentToggle}>{showMoreContent ? t('SHOW_LESS') : t('SHOW_MORE')}</ShowMoreButton>
            </ShowMoreContent>
          )}
        </Content>
        <InsightsViewable
          doc={item}
          extra={{ class: 'block', family: item.title, order: item._id }}
          kingdom="quest"
          refreshKey={refreshKey}
        />
      </Wrapper>
    </InsightsViewable>
  );
};


export default QuestCard;
