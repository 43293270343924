/* eslint react/destructuring-assignment: off */
import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';
import warning from 'warning';
import { FacebookShareButton } from 'react-share';
import { getReferralUrl } from 'services/insights';
import Icon from 'components/ui/Icon';
import Link from 'components/ui/Link';
import LoadingSpinner from 'components/ui/LoadingSpinner';
import PageLink from 'components/ui/PageLink';
import { ACCENT_PRIMARY, SPACING_SMALL, TEXT_100 } from 'style/constants';
import { BREAK_WORD, TEXT_P3 } from 'style/mixins';
import VideoLink from 'components/ui/VideoLink';
import { useGlobalVideoContext } from 'contexts/GlobalVideoContext';
import { withEndUserT, DynamicTranslationType } from 'hooks/use-translation';
import {
  ICON,
  TEXT,
  ACTION,
  PROGRESS_COMPLETE,
  PROGRESS_INCOMPLETE,
} from 'injection-classes';

import RealtimePage from 'components/core/RealtimePage';
import {
  ANSWER_ANY_POLL,
  ANSWER_ANY_PREDICTION,
  ANSWER_ANY_PREDICTION_CORRECTLY,
  ANSWER_ANY_TRIVIA,
  ANSWER_ANY_TRIVIA_CORRECTLY,
  ANSWER_SPECIFIC_POLL,
  ANSWER_SPECIFIC_PREDICTION,
  ANSWER_SPECIFIC_PREDICTION_CORRECTLY,
  ANSWER_SPECIFIC_TRIVIA,
  ANSWER_SPECIFIC_TRIVIA_CORRECTLY,
  IMPOSSIBLE,
  OPEN_LINK,
  RETWEET,
  SEA_OF_THIEVES_KILL_PLAYER_ID,
  SEA_OF_THIEVES_KILL_SKELETON_ID,
  SEA_OF_THIEVES_VISIT_ISLAND_ID,
  SHARE_FACEBOOK,
  VIEW_PANEL,
  VISIT_CHANNEL,
  VISIT_TIME,
  WATCH_ANY_VIDEO,
  WATCH_SPECIFIC_VIDEO,
} from './constants';

const MULTIPLE_CHOICE = 'multiple_choice';

const Progress = styled.div`
  font-size: 12px;
  color: ${ACCENT_PRIMARY};
`;

const DISABLED = css`
  user-select: none;
  color: ${TEXT_100};
`;

const Space = styled.span`
  &:after {
    content: " ";
  }
`;

const Active = styled.span`
  cursor: pointer;
  ${(props) => (
    props.disabled ? DISABLED : css`color: ${ACCENT_PRIMARY};`
  )}
`;

const ActiveLink = styled(Link)`
  color: ${ACCENT_PRIMARY};
  && {
    ${props => (props.disabled ? DISABLED : '')}
  }
`;

const ActiveVideoLink = styled(VideoLink)`
  && {
    ${props => (props.disabled ? DISABLED : '')}
  }
`;

const QuestActionIcon = styled(Icon)`
  padding-right: ${SPACING_SMALL};
  color: ${ACCENT_PRIMARY};

  padding-left: ${props => props.name === 'circledCheckmarkUnfilled' ? '3px' : '0'};

  & > svg {
    width: 13px;
    height: 13px;
  }
`;

const QuestActionContainer = styled.div`
  display: flex;
  ${TEXT_P3};
  font-weight: 600;
  flex-flow: row nowrap;
  ${BREAK_WORD}
`;

class QuestAction extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    data: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    endUserInterpolator: PropTypes.func.isRequired,
    endUserT: PropTypes.func.isRequired,
    failed: PropTypes.bool,
    openPanel: PropTypes.func.isRequired,
    pageId: PropTypes.string.isRequired,
    progress: PropTypes.number.isRequired,
    questEngage: PropTypes.func.isRequired,
    questId: PropTypes.string.isRequired,
    userId: PropTypes.string,
    video: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  };

  static defaultProps = {
    className: '',
    failed: false,
    userId: null,
    video: null,
  };

  renderIcon = (data, progress, questFailed) => {
    const count = (data.valueRequired || 1);
    const completed = progress >= count;

    let icon;
    let className = ICON;

    if (questFailed) {
      icon = 'circle-x';
      className += ` ${PROGRESS_INCOMPLETE}`;
    } if (completed) {
      icon = 'circledCheckmarkUnfilled';
      className += ` ${PROGRESS_COMPLETE}`;
    } else {
      icon = 'circle-empty';
      className += ` ${PROGRESS_INCOMPLETE}`;
    }
    return (
      <QuestActionIcon
        className={className}
        name={icon}
      />
    );
  };

  renderChannelSpecific = (data) => {
    const { endUserInterpolator } = this.props;
    const channelId = data.kind === VISIT_CHANNEL ? data.validationValue : data.channelId;
    if (!channelId) {
      return null;
    }
    return (
      <RealtimePage id={channelId}>
        {
          (doc, loaded) => {
            if (!loaded) {
              return (<LoadingSpinner />);
            }

            const { slug } = doc || {};
            if (!slug) {
              return null;
            }

            const slugLink = (
              <PageLink plain={false} slug={slug}>
                {slug}
              </PageLink>
            );

            return (
              <span>
                {
                  endUserInterpolator(
                    [DynamicTranslationType.questOnChannel, { variables: { slug: slugLink } }],
                    ['ON_CHANNEL_SLUG', { slug: slugLink }],
                  )
                }
              </span>
            );
          }
        }
      </RealtimePage>
    );
  };

  renderProgress = (data, progress) => {
    const count = (data.valueRequired || 1);
    return <Progress> { progress } / { count } </Progress>;
  };

  renderAction = (data, progress) => {
    const { endUserT, questEngage, questId } = this.props;
    const {
      arrayId,
      callToAction,
      channelId,
      body,
      kind,
      validationValue,
    } = data;
    const onChannel = channelId ? channelId === this.props.pageId : true;

    const linkText = endUserT([
      DynamicTranslationType.questActionCta,
      { actionArrayId: arrayId, questId },
    ]) || callToAction || '';

    const text = endUserT([
      DynamicTranslationType.questActionText,
      { actionArrayId: arrayId, questId },
    ]) || body || '';

    switch (kind) {
      case OPEN_LINK:
        return (
          <span>
            <span>{text}</span>
            <Space />
            <ActiveLink
              disabled={!onChannel}
              href={validationValue}
              onClick={(e, href) => questEngage({ action: 'open_link', url: href })}
            >
              <span>{linkText}</span>
              <Space />
            </ActiveLink>
            { this.renderChannelSpecific(data) }
            { this.renderProgress(data, progress) }
          </span>
        );

      case VISIT_TIME:
        return (
          <div>
            <span>
              <span>{text}</span>
              <Space />
            </span>
            { this.renderChannelSpecific(data) }
          </div>
        );

      case ANSWER_ANY_TRIVIA:
      case ANSWER_ANY_TRIVIA_CORRECTLY:
      case ANSWER_SPECIFIC_TRIVIA:
      case ANSWER_SPECIFIC_TRIVIA_CORRECTLY:
        return (
          <div>
            <span>
              <span>{text}</span>
              <Space />
            </span>
            { kind !== ANSWER_ANY_TRIVIA && validationValue && (
              <Active
                disabled={!onChannel}
                onClick={() => this.props.openPanel({
                  doc: {
                    data: {
                      kind: data.multipleChoice || MULTIPLE_CHOICE,
                      questions: [
                        {
                          kind: 'trivia',
                          trivia: {
                            _id: validationValue,
                          },
                        },
                      ],
                    },
                  },
                })}
              >
                <span>{linkText}</span>
                <Space />
              </Active>
            )}
            { this.renderChannelSpecific(data) }
            { this.renderProgress(data, progress) }
          </div>
        );

      case ANSWER_ANY_PREDICTION:
      case ANSWER_SPECIFIC_PREDICTION:
      case ANSWER_SPECIFIC_PREDICTION_CORRECTLY:
      case ANSWER_ANY_PREDICTION_CORRECTLY:
        return (
          <div>
            <span>
              <span>{text}</span>
              <Space />
            </span>
            { kind !== ANSWER_ANY_PREDICTION && validationValue && (
              <Active
                disabled={!onChannel}
                onClick={() => this.props.openPanel({
                  doc: {
                    data: {
                      kind: data.multipleChoice || MULTIPLE_CHOICE,
                      questions: [
                        {
                          kind: 'prediction',
                          prediction: {
                            _id: validationValue,
                          },
                        },
                      ],
                    },
                  },
                })}
              >
                <span>{linkText}</span>
                <Space />
              </Active>
            )}
            { this.renderChannelSpecific(data) }
            { this.renderProgress(data, progress) }
          </div>
        );

      case ANSWER_ANY_POLL:
      case ANSWER_SPECIFIC_POLL:
        return (
          <div>
            <span>
              <span>{text}</span>
              <Space />
            </span>
            { kind !== ANSWER_ANY_POLL && validationValue && (
              <Active
                disabled={!onChannel}
                onClick={() => this.props.openPanel({
                  doc: {
                    data: {
                      kind: data.multipleChoice || MULTIPLE_CHOICE,
                      questions: [
                        {
                          kind: 'poll',
                          poll: {
                            _id: validationValue,
                          },
                        },
                      ],
                    },
                  },
                })}
              >
                <span>{linkText}</span>
                <Space />
              </Active>
            )}
            { this.renderChannelSpecific(data) }
            { this.renderProgress(data, progress) }
          </div>
        );

      case SEA_OF_THIEVES_KILL_SKELETON_ID:
      case SEA_OF_THIEVES_KILL_PLAYER_ID:
      case SEA_OF_THIEVES_VISIT_ISLAND_ID:
      case RETWEET:
        return (
          <div>
            <span>{text}</span>
            { this.renderProgress(data, progress) }
          </div>
        );
      case SHARE_FACEBOOK:
        return (
          <span>
            <span>{text}</span>
            <Space />
            <FacebookShareAction
              disabled={!onChannel}
              linkText={linkText}
            />
            { this.renderChannelSpecific(data) }
            { this.renderProgress(data, progress) }
          </span>
        );
      case WATCH_ANY_VIDEO:
      case WATCH_SPECIFIC_VIDEO: {
        const hasVideoId = validationValue;
        const Parent = hasVideoId ? ActiveVideoLink : Link;
        const videoLinkProps = {
          disabled: !onChannel,
          onClick: () => (
            questEngage({
              action: 'play_video',
              video: validationValue,
            })
          ),
          tag: `v=${validationValue}`,
        };
        const parentProps = hasVideoId ? videoLinkProps : {};
        return (
          <span>
            <span>{text}</span>
            <Space />
            {
              kind === WATCH_SPECIFIC_VIDEO && validationValue && (
                <Parent {...parentProps}>
                  <span>{linkText}</span>
                  <Space />
                </Parent>
              )
            }
            { this.renderChannelSpecific(data) }
            { this.renderProgress(data, progress) }
          </span>
        );
      }

      case VISIT_CHANNEL:
        return (
          <span>
            <span>
              <span>{text}</span>
              <Space />
            </span>
            { this.renderChannelSpecific(data) }
            { this.renderProgress(data, progress) }
          </span>
        );

      case VIEW_PANEL:
        return (
          <span>
            <span>
              <span>{text}</span>
              <Space />
            </span>
            { validationValue && (
              <Active
                disabled={!onChannel}
                onClick={() => this.props.openPanel({
                  id: validationValue,
                })}
              >
                <span>{linkText}</span>
                <Space />
              </Active>
            ) }
            { this.renderChannelSpecific(data) }
            { this.renderProgress(data, progress) }
          </span>
        );

      case IMPOSSIBLE:
        return (
          <span>{text}</span>
        );

      default:
        // TODO: This should become an error eventually.
        warning(false, `Unsupported quest action kind: ${kind}`);
        return process.env.NODE_ENV === 'production' ? null : (
          <span>{ JSON.stringify(data) }</span>
        );
    }
  };

  render() {
    const { className, data, progress, failed } = this.props;
    return (
      <QuestActionContainer className={`${className} ${ACTION}`}>
        { this.renderIcon(data, progress, failed) }
        <div className={TEXT}>
          { this.renderAction(data, progress) }
        </div>
      </QuestActionContainer>
    );
  }
}

const FacebookShareAction = ({ disabled, linkText }) => {
  const { shareId } = useGlobalVideoContext();

  if (!shareId) {
    return null;
  }

  return (
    <Active disabled={disabled}>
      <FacebookShareButton url={getReferralUrl(shareId)}>
        <span alt={getReferralUrl(shareId)}>{linkText}</span>
        <Space />
      </FacebookShareButton>
    </Active>
  );
};

export default withEndUserT(QuestAction);
