import React, { useEffect, useMemo } from 'react';
import isNil from 'lodash/isNil';
import { useDispatch, useSelector } from 'react-redux';
import { isMobileLayout } from 'services/device/selectors';
import { getShowMobileChannelSelector, isChannelSelectorSidebarButtonVisible } from 'services/app/selectors';
import { setCloseChannelSelectOnOutsideClick, toggleMobileChannelSelector } from 'services/app/actions';
import { CHANNEL_SELECT } from 'injection-classes';
import {
  ChannelSelectButtonWrapper,
  ChannelSelectButton,
} from './styles';

interface ChannelSelectButtonProps {
  center?: boolean;
  className?: string;
  closeChannelSelectOnOutsideClick?: boolean;
  isVisible?: boolean;
  padding?: string;
}

export default function MobileChannelSelectButton({ center, className, closeChannelSelectOnOutsideClick, isVisible, padding }: ChannelSelectButtonProps) {
  const dispatch = useDispatch();
  const isMobile = useSelector(isMobileLayout);
  const channelSelectorIsVisible = useSelector(isChannelSelectorSidebarButtonVisible);
  const showMobileChannelSelector = useSelector(getShowMobileChannelSelector);

  const shouldRender = useMemo(() => isMobile && (
    isNil(isVisible) ? channelSelectorIsVisible : isVisible
  ), [isVisible, channelSelectorIsVisible]);

  const onClick = () => {
    dispatch(toggleMobileChannelSelector());
  };

  useEffect(() => {
    let timeoutId = 0;
    if (showMobileChannelSelector && closeChannelSelectOnOutsideClick && shouldRender) {
      timeoutId = setTimeout(() => {
        dispatch(setCloseChannelSelectOnOutsideClick(true));
      }, 100);
    }
    return () => {
      clearTimeout(timeoutId);
      dispatch(setCloseChannelSelectOnOutsideClick(false));
    };
  }, [closeChannelSelectOnOutsideClick, shouldRender, showMobileChannelSelector]);

  return shouldRender ? (
    <ChannelSelectButtonWrapper center={center} className={className} padding={padding}>
      <ChannelSelectButton
        className={CHANNEL_SELECT}
        isActive={showMobileChannelSelector}
        onClick={onClick}
      />
    </ChannelSelectButtonWrapper>
  ) : null;
}
