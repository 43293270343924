import Icon from 'components/admin2/Icon';
import Link from 'components/ui/Link';
import { LIGHT_MODE_TEXT_COLORS } from 'services/themes';
import { CLASSIC_THEMES } from 'services/themes/constants';
import {
  TEXT_LABEL_S_MEDIUM,
  TEXT_TITLE_S,
} from 'style/design-system/textStyles';
import styled, { css } from 'styled-components';
import { MOBILE_TAB_BAR_HEIGHT } from '../MobileTabBar/styles';

interface IContainerProps {
  isSearchOpen?: boolean;
  topPosition?: number;
}

export const Container = styled.div.attrs(
  ({ topPosition = 0 }: IContainerProps) => ({
    style: {
      top: `${topPosition}px`,
    },
  }),
)<IContainerProps>`
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-flow: column nowrap;
  animation: fadeIn 0.5s;
  background: rgba(20, 20, 20, ${({ isSearchOpen }) =>
    isSearchOpen ? 0.8 : 0.6});
  20px, 20px, 0px, 0px

  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
  border-top: 1px solid ${CLASSIC_THEMES[0].colors.text300};
  z-index: 2;
  border-radius: 20px 20px 0px 0px;
  margin-bottom: ${MOBILE_TAB_BAR_HEIGHT}px;
`;

export const CloseRegion = styled.div`
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CloseMark = styled.div<{ isActive?: boolean }>`
  height: 4px;
  width: 72px;
  background-color: ${({ isActive }) =>
    isActive
      ? CLASSIC_THEMES[0].colors.highlightPrimary
      : CLASSIC_THEMES[0].colors.highlightSecondary};
`;

export const NavContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  padding-inline: 20px;
  height: 100%;
  overflow-y: auto;
`;

const BASE_NAV_LINK_STYLES = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  &:focus,
  &:active {
    outline: none;
    border: none;
  }
`;

export const PrimaryNavLinkContainer = styled(Link)`
  ${BASE_NAV_LINK_STYLES}
  height: 44px;
`;

export const SecondaryNavLinkContainer = styled(Link)`
  ${BASE_NAV_LINK_STYLES}
  padding-left: 30px;
  min-height: 40px;
`;

export const NavChevron = styled(Icon).attrs(() => ({
  name: 'arrowRight',
  color: CLASSIC_THEMES[0].colors.text300,
}))``;

export const PrimaryLinkText = styled.div`
  width: 100%;
  ${TEXT_TITLE_S}
  color: ${CLASSIC_THEMES[0].colors.text100};
`;

export const SecondaryLinkText = styled(PrimaryLinkText)<{
  isHighlighted?: boolean;
}>`
  width: 100%;
  ${TEXT_LABEL_S_MEDIUM}
  color: ${LIGHT_MODE_TEXT_COLORS.text300}
    ${({ isHighlighted }) =>
    isHighlighted &&
    css`
      color: ${CLASSIC_THEMES[0].colors.text100};
    `}
`;

export const SearchWrapper = styled.div`
  margin: 0px 20px 25px 20px;
  div:first-child {
    div:first-child {
      background-color: ${CLASSIC_THEMES[0].colors.surface4};
    }
  }
`;
