import invariant from 'invariant';
import React, { lazy, useMemo, useState, useEffect, ReactNode, ComponentType, CSSProperties } from 'react';
import styled from 'styled-components';
import { Portal } from 'react-portal';
import { ADMIN_ERROR_MODAL_ROOT, SIDEBAR_ID } from 'global-ids';
import useFeatureGateEnabled, { Feature } from 'hooks/use-feature-gate-enabled';
import { CROSS_CHANNEL_VALIDATION_MODAL } from 'shared/constants';
import { desktopOnly } from 'style/mixins';
import { promptStringKeys } from 'services/modals';
import { ModalKinds } from 'services/modals/types';
import { getHasAdminModal } from 'services/admin/selectors';
import { useSelector } from 'react-redux';
import { isUserAdmin } from 'services/auth';

const KIND_MAP = {
  [CROSS_CHANNEL_VALIDATION_MODAL]: lazy(() => import('../CrossChannelEditWarningModal')),
  [ModalKinds.addToCalendar]: lazy(() => import('../AddToCalendarModal')),
  [ModalKinds.addUserRole]: lazy(() => import('../AddUserRoleModal')),
  [ModalKinds.adminConfirmation]: lazy(() => import('../AdminConfirmationModal')),
  [ModalKinds.adminError]: lazy(() => import('../AdminErrorModal')),
  [ModalKinds.adminSuccess]: lazy(() => import('../AdminSuccessModal')),
  [ModalKinds.chatPromptLogin]: lazy(() => import('../PromptLoginModal')),
  [ModalKinds.checkEmail]: lazy(() => import('../CheckEmailModal')),
  [ModalKinds.confirmation]: lazy(() => import('../ConfirmationModal')),
  [ModalKinds.countdown]: lazy(() => import('../CountdownModal')),
  [ModalKinds.deviceLimit]: lazy(() => import('../DeviceLimitModal')),
  [ModalKinds.emailVerification]: lazy(() => import('../EmailVerificationModal')),
  [ModalKinds.errorModal]: lazy(() => import('../ErrorModal')),
  [ModalKinds.forgotPassword]: lazy(() => import('../ForgotPasswordModal')),
  [ModalKinds.gdpr]: lazy(() => import('../GDPR')),
  [ModalKinds.image]: lazy(() => import('../ImageModal')),
  [ModalKinds.imageLibrary]: lazy(() => import('../ImageLibraryModal')),
  [ModalKinds.instagram]: lazy(() => import('../InstagramModal')),
  [ModalKinds.invite]: lazy(() => import('../InviteLinkInvalidModal')),
  [ModalKinds.login]: lazy(() => import('../AuthModal')),
  [ModalKinds.purchasePlan]: lazy(() => import('../PurchasePlanModal')),
  [ModalKinds.bundlePurchase]: lazy(() => import('../BundlePurchaseModal')),
  [ModalKinds.questClaim]: lazy(() => import('../QuestClaimModal')),
  [ModalKinds.receiptModal]: lazy(() => import('../ReceiptModal')),
  [ModalKinds.resetPassword]: lazy(() => import('../ResetPasswordModal')),
  [ModalKinds.resetPasswordSuccess]: lazy(() => import('../SetPasswordSuccessModal')),
  [ModalKinds.selectableText]: lazy(() => import('../SelectableTextModal')),
  [ModalKinds.shopify]: lazy(() => import('../ShopifyModal')),
  [ModalKinds.supportContact]: lazy(() => import('../SupportContactInfoModal')),
  [ModalKinds.text]: lazy(() => import('../TextModal')),
  [ModalKinds.twitter]: lazy(() => import('../TwitterModal')),
  [ModalKinds.twitterPanel]: lazy(() => import('../TwitterPanelModal')),
  [ModalKinds.streamEncoderState]: lazy(() => import('../StreamEncoderStateModal').then(m => ({ default: m.StreamEncoderStateModal }))),
  [ModalKinds.activeOnPageModal]: lazy(() => import('../ActiveOnPageModal')),
  [ModalKinds.haltGate]: lazy(() => import('../HaltGateModal')),
  [ModalKinds.wizardMain]: lazy(() => import('../WizardModal')),
  [ModalKinds.calendarBlockEventSettings]: lazy(() => import('components/page-blocks/EventCalendar/EventSettingsModal')),
  [ModalKinds.whatTypeOfCreatorAreYou]: lazy(() => import('../WhatTypeOfCreatorAreYouModal')),
  [ModalKinds.wizardEnd]: lazy(() => import('../WizardModal/SubModals/WizardEndModal')),
  [ModalKinds.wizardBeholdTheGate]: lazy(() => import('../WizardModal/SubModals/BeholdTheGateModal')),
  [ModalKinds.activateThemeConfirmation]: lazy(() => import('../ActivateThemeConfirmationModal')),
  [ModalKinds.subscriptionPurchase]: lazy(() => import('../SubscriptionPurchaseModal')),
  [ModalKinds.videoMetadataV2]: lazy(() => import('../VideoMetadataModal').then(m => ({ default: m.VideoMetadataModalV2 }))),
  [ModalKinds.videoLibraryV2]: lazy(() => import('components/admin2/videoPanels/VideoLibraryModal/VideoLibraryModalV2').then(m => ({ default: m.VideoLibraryModalV2 }))),
  [ModalKinds.upgradePlan]: lazy(() => import('../UpgradePlanModal')),
  [ModalKinds.upgradePlanSuccess]: lazy(() => import('../UpgradePlanSuccess')),
  [ModalKinds.studioInviteReceived]: lazy(() => import('../StudioInviteReceivedModal').then(m => ({ default: m.StudioInviteReceivedModal }))),
  [ModalKinds.setUpAccess]: lazy(() => import('../SetUpAccessModal').then(m => ({ default: m.SetUpAccessModal }))),
  [ModalKinds.selfServiceSignup]: lazy(() => import('../SelfServiceSignupModal')),
  [ModalKinds.settingUpSelfServiceSite]: lazy(() => import('../SettingUpSelfServiceSiteModal')),
  [ModalKinds.chooseSelfServicePlan]: lazy(() => import('../ChooseSelfServicePlanModal')),
  [ModalKinds.tvAppsLogin]: lazy(() => import('../TVAppsLoginModal')),
};

type ModalBackgroundStyleProps = {
  blur?: boolean;
  panelModalPosition?: number | false;
  transparentBackground?: boolean;
};

const ModalBackground = styled.div<ModalBackgroundStyleProps>`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ transparentBackground }) => transparentBackground ? 'transparent' : 'rgba(0, 0, 0, 0.5)'};
  ${({ blur }) => blur && 'backdrop-filter: blur(2px);'}
  ${desktopOnly`
    left: ${({ panelModalPosition }: ModalBackgroundStyleProps) => panelModalPosition ? `${panelModalPosition}px` : 0};
  `}
`;

export type ModalData<T = any> =  {
  data: T;
  onDismiss: () => unknown;
  style?: CSSProperties;
};

type ModalProps<T = any> = Omit<ModalData<T>, 'data'> & {
  backgroundStyle?: CSSProperties;
  data?: ModalData<T>['data'];
  kind: ModalKinds;
};

const Modal = (
  {
    backgroundStyle = {},
    kind,
    data = {},
    onDismiss,
    style,
  }: ModalProps,
) => {
  const shouldBlur = useSelector(getHasAdminModal);
  const isAdmin = useSelector(isUserAdmin);
  const Component = KIND_MAP[kind] as ComponentType<{
    data?: any;
    onDismiss: () => unknown;
    role: 'dialog';
    style?: CSSProperties;
  }>;
  invariant(Component, `Modal expected valid kind but received ${kind}`);

  const shouldHideGDPR = useFeatureGateEnabled({
    feature: Feature.HIDE_GDPR,
    type: 'feature',
  });
  const [GDPRComponent, setGDPRComponent] = useState<ReactNode>(null);

  useEffect(() => {
    const timeout = setTimeout(
      () => {
        setGDPRComponent(shouldHideGDPR ? null : (
          <Component data={data} onDismiss={onDismiss} role="dialog" style={style} />
        ));
      },
      3000,
    );

    return () => {
      clearTimeout(timeout);
    };
  }, [shouldHideGDPR, GDPRComponent]);

  const portalNode = useMemo(() => {
    const modalRoot = document && document.getElementById(ADMIN_ERROR_MODAL_ROOT);
    return modalRoot;
  }, []);
  const shouldPortal = portalNode && kind === ModalKinds.adminError;
  const Wrapper = shouldPortal ? Portal : React.Fragment;
  const wrapperProps = shouldPortal ? { node: portalNode } : {};

  const getLeftPosition = document &&
    document.getElementById(SIDEBAR_ID)?.getBoundingClientRect().left;
  const panelModalPosition = promptStringKeys.includes(data?.promptStringKey) ?
    getLeftPosition :
    false;

  if (
    [
      ModalKinds.adminError,
      ModalKinds.adminConfirmation,
      ModalKinds.adminSuccess,
      ModalKinds.videoLibraryV2,
    ].includes(kind)
  ) {
    return (
      <React.Suspense fallback={null}>
        <Component data={data} onDismiss={onDismiss} role="dialog" style={style} />
      </React.Suspense>
    );
  }

  const content = () => {
    if (kind === ModalKinds.gdpr) {
      return GDPRComponent;
    }
    return (
      <Wrapper {...wrapperProps}>
        <ModalBackground
          blur={shouldBlur}
          onClick={data?.locked ? undefined : onDismiss}
          panelModalPosition={!isAdmin ? panelModalPosition : false}
          style={{
            ...backgroundStyle,
            ...data?.backgroundStyle,
          }}
          transparentBackground={data?.transparentBackground}
        >
          <Component data={data} onDismiss={onDismiss} role="dialog" style={style} />
        </ModalBackground>
      </Wrapper>
    );
  };

  return (
    <React.Suspense fallback={null}>
      {content()}
    </React.Suspense>
  );
};

export default Modal;
