import React from 'react';
import { useDispatch } from 'hooks';
import styled from 'styled-components';

import { amazonLogIn, logIn, OauthProvider } from 'services/auth';
import Link from 'components/ui/Link';
import amazonLogo from 'assets/amazon-logo.png';
import { AMAZON_ORANGE } from 'style/constants';
import BaseLoginButton from './BaseLoginButton';
import Icon from 'components/ui/Icon';

export const ImgIcon = styled.img`
  height: 22px;
  width: 22px;
`;

const AmazonLoginButton = () => {
  const dispatch = useDispatch();
  const triggerLogin = () => {
    dispatch(amazonLogIn());
  };

  return (
    <Link onClick={triggerLogin} plain={true} >
      <BaseLoginButton
        color={AMAZON_ORANGE}
        dataTestId="authModalAmazonLoginButton"
        providerNameKey="AMAZON"
        renderIcon={ // tslint:disable-next-line: jsx-no-lambda no-empty
          () => <ImgIcon src={amazonLogo} />
        }
      />
    </Link>
  );
};

export default AmazonLoginButton;
