import React from 'react';
import styled from 'styled-components';
import Icon from 'components/ui/Icon';
import { ADMIN_SURFACE_4 } from 'style/constants';

interface RoundedIconProps {
    className?: string;
    iconKey: string;
    onClick?: (e?:React.MouseEvent ) => void;
    testId?: string;
}

const IconWrapper = styled.div`
  width: 40px;
  height: 40px;
  padding: 10px;
  // panel icons have 20*20 dimensions
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  background: ${ADMIN_SURFACE_4};
`;

const StyledIcon = styled(Icon)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const RoundedIcon: React.FC<RoundedIconProps> = ({ iconKey, className, onClick, testId }) => {
    return (
        <IconWrapper className={className} onClick={onClick} data-testid={testId}>
            <StyledIcon name={iconKey} />
        </IconWrapper>
    );
};

export default RoundedIcon;