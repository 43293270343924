/* eslint react/destructuring-assignment: off */
import PropTypes from 'prop-types';
import React from 'react';

import qs from 'qs';

import Icon from 'components/ui/Icon';
import Link from 'components/ui/Link';
import { TWITCH_CLIENT_ID } from 'config';
import { TWITCH_PURPLE } from 'style/constants';
import BaseLoginButton from 'components/modals/AuthModal/BaseLoginButton';

const scope = [
  'channel_check_subscription',
  'user_follows_edit',
  'user_read',
  'user_subscriptions',
  'openid',
].join(' ');

/* eslint-ignore camelcase */
const createTwitchRedirect = (clientId) => {
  const baseUrl = 'https://id.twitch.tv/oauth2/authorize';
  const params = {
    client_id: clientId,
    redirect_uri: `${window.location.protocol}//${window.location.host}/pkg/lt3-api/4.0/api/twitch/oauth`,
    response_type: 'token id_token',
    scope,
  };
  return `${baseUrl}?${qs.stringify(params)}`;
};

export default class TwitchLoginButton extends React.Component {
  static propTypes = {
    onTwitchCallback: PropTypes.func.isRequired,
    signUp: PropTypes.bool.isRequired,
  };

  state = {
    redirectHref: createTwitchRedirect(TWITCH_CLIENT_ID),
  };

  componentDidMount() {
    // eslint-disable-next-line no-underscore-dangle
    window.__twitchCallback = this.props.onTwitchCallback;
  }

  render() {
    const { redirectHref } = this.state;
    const { signUp } = this.props;
    return (
      <Link href={redirectHref} rel="">
        <BaseLoginButton
          color={TWITCH_PURPLE}
          dataTestId="authModalTwitchLoginButton"
          providerNameKey="TWITCH"
          renderIcon={
            () => <Icon name="twitch" />
          }
          signUp={signUp}
        />
      </Link>
    );
  }
}
