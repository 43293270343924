import styled from 'styled-components';
import { bw, rgba } from 'colors';
import { ACCENT_PRIMARY, SURFACE_3, SURFACE_2, SPACING_SMALL, TEXT_100, TEXT_CONTRAST_ON_ACCENT_PRIMARY } from 'style/constants';
import { BACKGROUND_ACTIVE, BREAK_WORD, TEXT_P3, TEXT_H2, TEXT_H5, TEXT_H6, TEXT_LABEL7 } from 'style/mixins';

import Icon from 'components/ui/Icon';
import LoadingSpinner from 'components/ui/LoadingSpinner';
import QuestAction from './QuestAction';

export const ClaimReward = styled.div`
  background-color: ${ACCENT_PRIMARY};
  color: ${TEXT_CONTRAST_ON_ACCENT_PRIMARY};
  cursor: ${({ expired }) => expired ? 'default' : 'pointer'};
  height: 35px;
  font-size: 16px;
  margin: 0 16px;
  font-weight: 700;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ClaimedReward = styled.button`
${TEXT_H5}
background-color: gray;
color: ${TEXT_100};
padding: 10px;
width: 100%;
text-align: center;
`;

// '&&' is to override opacity passed down from Container
export const ViewPrize = styled.button`
  ${BACKGROUND_ACTIVE}
  ${TEXT_H5}
  padding: 10px;
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  && {
    opacity: 1;
  }
`;

export const CoverSpinner = styled(LoadingSpinner)`
  background-color: ${props => rgba(SURFACE_3(props), 0.9)};
  height: 105%;
  left: 0;
  position: absolute;
  top: -10px;
  width: 100%;
  z-index: 100;
`;

export const Header = styled.div`
  padding: 20px;
  ${props => props.showCompletedCount && 'padding-top: 30px;'}
`;

export const HeaderContainer = styled.div`
  position: relative;
  z-index: 0;
  display: block;
  flex-direction: column;
`;

export const SubHeader = styled.div`
  align-items: center;
  display: flex;
  padding: 20px;
`;

export const AwardTitle = styled.div`
  ${TEXT_H5};
  padding-top: 10px;
`;

export const ProgressBarBackground = styled.div`
  position: relative;
  background-color: ${props => rgba(TEXT_100(props), 0.3)};
  width: 100%;
  height: 3px;
`;

export const ProgressBar = styled.div`
  width: ${props => props.percent}%;
  background-color: ${ACCENT_PRIMARY};
  height: 100%;
  position: absolute;
`;

// Need to apply opacity to children, not this component
// so we can set our ViewPrize opacity to 1.
export const Container = styled.div`
  margin-bottom: 18px;
  position: relative;
  z-index: 0;
  background-color: ${SURFACE_2};
  border-radius: 5px;
  & > * {
    ${props => props.faded ? 'opacity: 0.5;' : ''}
  }
`;

export const CountContainer = styled.div`
position: absolute;
text-align: center;
width: 100%;
align-items: center;
display: block;
height: 25px;
z-index: 1;
top: -10px;
`;

export const BadgeWrap = styled.div`
margin: 0 auto;
position: relative;
text-align: center;
`;

export const TriangleCSS = `
clip-path: polygon(50% 0,0 50%,50% 100%);
content: "";
position: absolute;
width: 15px;
height: 25px;
margin: 0;
`;

export const BadgeCSS = `
display: inline-block;
height: 25px;
padding: 0 15px 0 15px;
border-radius: 2px;
margin: 0 auto;
text-align: center;
position: relative;
`;

export const TrianglePosition = `
&:before {
  left: -7px;
  top: 0px;
}
&:after {
  right: -7px;
  top: 0px;
  transform: rotate(180deg)
}
`;

export const CompletedCountBadge = styled.div`
${BadgeCSS}
z-index: 2;
${props => props.faded ? 'opacity: 0.5' : ''};
background-color: ${TEXT_100};

&:before, &:after {
  background-color: ${TEXT_100};
  ${TriangleCSS}
}
${TrianglePosition}
& > * {
  ${TEXT_H6}
  color: ${props => bw(TEXT_100(props))};
  vertical-align: top;
}
`;

export const CountText = styled.span`
width: 100%;
height: 100%;
display: flex;
align-items: center;
white-space: nowrap;
`;

export const BadgeBackground = styled.div`
${BadgeCSS}
background-color: ${SURFACE_3};
opacity: 1 !important;

&:before, &:after {
  opacity: 1 !important;
  background-color: ${SURFACE_3};
  ${TriangleCSS}
}
${TrianglePosition}
& > * {
  ${TEXT_H6}
  color: ${SURFACE_3};
}
`;

export const HeaderIcon = styled(Icon)`
  align-items: center;
  color: ${ACCENT_PRIMARY};
  display: flex;
  ${TEXT_H2}
  margin: 0;
  vertical-align: middle;
  margin-right: 10px;


  & > svg {
    width: 24px;
    height: 24px;
  }
`;

export const HeaderText = styled.div`
  align-items: center;
  display: flex;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  ${BREAK_WORD}
`;

export const RewardAvailable = styled.div`
`;

export const RewardImage = styled.div`
flex: 0 0 50px;
height: 50px;
margin-right: ${SPACING_SMALL};
background-position: center center;
background-image: url(${props => props.background});
background-size: contain;
background-repeat: no-repeat;
cursor: pointer;
`;

export const RewardTitle = styled.div`
  ${TEXT_LABEL7};
  font-weight: 600;
  text-transform: uppercase;
`;

export const RewardDesc = styled.div`
  ${TEXT_P3};
  font-weight: 600;
  color: ${ACCENT_PRIMARY};
`;

export const Actions = styled.div`
  border-top: none;
  padding: 12px 25px 30px 25px;
  > *:not(:first-child) {
    margin-top: ${SPACING_SMALL};
  }
`;

export const TrophyIcon = styled(Icon)`
  padding-right: ${SPACING_SMALL};
  color: ${ACCENT_PRIMARY};
  & > svg {
    width: 21px;
    height: 23px;
  }
`;

export const StyledQuestAction = styled(QuestAction)`
  pointer-events: ${({ available, expired }) => (expired || !available ? 'none' : 'initial')};
`;
