import Dragger from 'components/admin2/LegacyDragger';
import React from 'react';
import { useAdminTranslation, TranslationKey } from 'hooks/use-translation';
import {
  ADMIN_SURFACE_3,
  ADMIN_TEXT_100,
  ADMIN_TEXT_300,
} from 'style/constants';
import {
  ADMIN_TEXT_LABEL_M_MEDIUM,
  ADMIN_TEXT_LABEL_S_MEDIUM,
} from 'style/design-system/textStyles';
import styled from 'styled-components';
import CatalogItem, { ICatalogItemProps as ICatalogItem } from '../CatalogItem';

export interface ICatalogListProps {
  draggable?: boolean;
  items: ICatalogItem[];
  onDelete?: (kindId: string) => void;
  onDuplicate?: (kindId: string) => void;
  onEdit?: (kindId: string) => void;
  onPreview?: (kindId: string) => void;
  onReorder?: (reorderedItems: any[]) => void;
  onToggle?: (kindId: string, checked: boolean) => void;
  testId?: string;
  titleKey?: TranslationKey;
}

const List: React.FC<ICatalogListProps> = ({
  draggable,
  items: cards,
  onToggle,
  onReorder,
  onEdit,
  onDuplicate,
  onDelete,
  onPreview,
}) => {
  const { t } = useAdminTranslation();

  const emptyListMessage = draggable
    ? 'ADMIN_PANEL_V2_PANELS_SECTION_ACTIVE_PANELS_EMPTY'
    : 'ADMIN_PANEL_V2_PANELS_SECTION_AVAILABLE_PANELS_EMPTY';

  if (cards.length === 0) {
    return (
      <EmptyListWrapper>
        <EmptyListMessage>
          {t(emptyListMessage)}
        </EmptyListMessage>
      </EmptyListWrapper>
    );
  }

  if (draggable) {
    return (
      <Dragger
        direction="vertical"
        keyField="arrayId"
        list={cards}
        innerDivStyles={{
          display: 'flex',
          'flex-direction': 'column',
          gap: '6px',
        }}
        onDragEnd={onReorder}
      >
        {(card: ICatalogItem) => (
          <CatalogItem
            {...card}
            draggable={draggable}
            onToggle={onToggle}
            onEdit={onEdit}
            onDuplicate={onDuplicate}
            onDelete={onDelete}
          />
        )}
      </Dragger>
    );
  }

  return (
    <ListWrapper>
      {cards.map((card) => (
        <CatalogItem
          key={card.position}
          {...card}
          onToggle={onToggle}
          onEdit={onEdit}
          onDuplicate={onDuplicate}
          onDelete={onDelete}
          onPreview={onPreview}
        />
      ))}
    </ListWrapper>
  );
};

const CatalogList: React.FC<ICatalogListProps> = ({
  titleKey,
  items: cards,
  draggable = false,
  onToggle,
  onReorder,
  onEdit,
  onDuplicate,
  onDelete,
  onPreview,
  testId,
}) => {
  const { t } = useAdminTranslation();

  return (
    <CatalogListWrapper data-testid={testId ? testId : 'catalog-list'}>
      {titleKey && <CatalogListTitle>{t(titleKey)}</CatalogListTitle>}
      <List
        draggable={draggable}
        items={cards}
        onToggle={onToggle}
        onReorder={onReorder}
        onEdit={onEdit}
        onDuplicate={onDuplicate}
        onDelete={onDelete}
        onPreview={onPreview}
      />
    </CatalogListWrapper>
  );
};

export default React.memo(CatalogList);

const CatalogListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const CatalogListTitle = styled.p`
  color: ${ADMIN_TEXT_100};
  margin: 0;
  ${ADMIN_TEXT_LABEL_M_MEDIUM};
`;

const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

const EmptyListWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 16px 10px 12px;
  border-radius: 4px;
  background: ${ADMIN_SURFACE_3};
  height: 68px;
`;

const EmptyListMessage = styled.p`
  color: ${ADMIN_TEXT_300};
  ${ADMIN_TEXT_LABEL_S_MEDIUM};
`;
