import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { DynamicTranslationType, useEndUserTranslation } from 'hooks/use-translation';
import FormInput from 'components/ui/v2/Inputs';

const ConfirmedEmailInput = ({ password, onPasswordChange, onUpdateErrors }) => {
  const [passwordError, setPasswordError] = useState('');
  const { endUserT } = useEndUserTranslation();

  const isARightPassword = 8 <= password.length && password.length <= 64;

  useEffect(() => {
    if (isARightPassword) {
      setPasswordError('');
    }
  }, [password]);

  const onVerifyPassword = () => {
    setPasswordError(
      (isARightPassword || password.length === 0) ? '' :
        endUserT([DynamicTranslationType.createAccountPasswordErrorLabel], ['PASSWORD_NOT_MATCH_CONDITIONS']),
    );
    onUpdateErrors('password', !isARightPassword);
  };

  return (
    <FormInput.Root error={!!passwordError}>
      <FormInput.FieldSet>
        <FormInput.Legend>
          {endUserT([DynamicTranslationType.globalPassword], ['PASSWORD'])}
        </FormInput.Legend>
        <FormInput.PasswordInput
          data-testid="authModalRegisterViewPasswordInput"
          name="password"
          onBlur={onVerifyPassword}
          onChange={onPasswordChange}
          placeholder={endUserT([DynamicTranslationType.globalPassword], ['PASSWORD'])}
          value={password}
        />
      </FormInput.FieldSet>
      {passwordError && <FormInput.SupportingText>{passwordError}</FormInput.SupportingText>}
    </FormInput.Root>
  );
};

ConfirmedEmailInput.propTypes = {
  onPasswordChange: PropTypes.func.isRequired,
  onUpdateErrors: PropTypes.func.isRequired,
  password: PropTypes.string.isRequired,
};

export default ConfirmedEmailInput;
