/* eslint react/destructuring-assignment: off */
import React from 'react';
import Icon from 'components/ui/Icon';
import { FACEBOOK_BLUE } from 'style/constants';
import PropTypes from 'prop-types';
import BaseLoginButton from 'components/modals/AuthModal/BaseLoginButton';

export default class FacebookLoginButton extends React.Component {
  static propTypes = {
    handleFBLogin: PropTypes.func.isRequired,
    signUp: PropTypes.bool.isRequired,
  };

  render() {
    const { signUp } = this.props;
    return (
      <span onClick={this.props.handleFBLogin} role="button" tabIndex={0}>
        <BaseLoginButton
          color={FACEBOOK_BLUE}
          dataTestId="authModalFacebookLoginButton"
          providerNameKey="FACEBOOK"
          renderIcon={
            () => <Icon name="facebook" />
          }
          signUp={signUp}
        />
      </span>
    );
  }
}
