import React from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';

const settings = {
  classes: 'pub_300x250 pub_300x250m pub_728x90 text-ad textAd text_ad text_ads text-ads text-ad-links',
  delay: 5000,
  runs: 10,
  style: {
    bottom: 0,
    color: 'transparent',
    fontSize: '20px',
    height: '10px !important',
    left: '-7500px',
    overflow: 'hidden',
    position: 'absolute',
  },
};

export default class TazerDetection extends React.Component {
  static propTypes = {
    onTazeDetection: PropTypes.func.isRequired,
  };

  ref = React.createRef();

  timeout = null;

  state = {
    key: shortid.generate(),
    runs: 1,
    shouldRender: true,
  };

  componentDidMount() {
    this.timeout = setTimeout(() => {
      this.handleTaze();
    }, settings.delay);
  }

  handleTaze = () => {
    const { onTazeDetection } = this.props;
    const { runs } = this.state;

    const node = this.ref.current;

    const detected = !node || node.offsetHeight === 0 || !document.body.contains(node) ||
      node.style.display === 'none' || node.style.visibility === 'hidden';

    if (detected) {
      onTazeDetection(true);
      return;
    }

    if (!detected && runs >= settings.runs) {
      onTazeDetection(false);
      return;
    }

    this.startTest();
  };

  startTest = () => {
    const { runs } = this.state;

    if (runs < settings.runs) {
      this.setState({
        key: shortid.generate(),
        runs: runs + 1,
        shouldRender: true,
      }, () => {
        this.timeout = setTimeout(() => {
          this.handleTaze();
        }, settings.delay);
      });
    }
  };

  render() {
    const { key, shouldRender } = this.state;

    return shouldRender && (
      <div
        key={key}
        ref={this.ref}
        className={settings.classes}
        style={settings.style}
      >
        &nbsp;
      </div>
    );
  }
}
