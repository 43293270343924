import { ObjectValues } from 'utils';

export interface IHardcodedPlan {
  description: string;
  dueAmount?: number;
  maxAdmins: string;
  name: string;
  paymentDescription?: string;
  planId: string;
  price: string;
  recommended?: boolean;
  shopifyKeepAmount: string;
  ticketAndSubscriptionKeepAmount: string;
  tipsKeepAmount: string;
}

export const PlanTerm = {
  ANNUALLY: 'annually',
  MONTHLY: 'monthly',
} as const;

// make a type for PlanTerm
export type PlanTermType = ObjectValues<typeof PlanTerm>;
