import styled from 'styled-components';

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column ;
`;

export const MarketingOptinContainer = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  height: auto;
  margin-top: 10px;
  overflow: hidden;
`;

export const InputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
