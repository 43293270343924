import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { DynamicTranslationType, useEndUserTranslation } from 'hooks/use-translation';
import FormInput from 'components/ui/v2/Inputs';

const ConfirmedEmailInput = ({ confirmedEmail, email, onConfirmedEmailChange, onUpdateErrors }) => {
  const [emailError, setEmailError] = useState('');
  const { endUserT } = useEndUserTranslation();

  const confirmedEmailMatch = useMemo(() => {
    return confirmedEmail.toLowerCase() === email.toLowerCase();
  }, [confirmedEmail, email]);

  const onVerifyEmail = () => {
    setEmailError(
      (confirmedEmailMatch || confirmedEmail.length === 0) ? '' :
        endUserT([DynamicTranslationType.createAccountConfirmEmailErrorLabel], ['CONFIRMED_EMAIL_DOES_NOT_MATCH']),
    );
    onUpdateErrors('confirmedEmail', !confirmedEmailMatch);
  };

  useEffect(() => {
    if (confirmedEmailMatch) {
      setEmailError('');
    }
  }, [confirmedEmail]);

  const lock = e => {
    e.preventDefault();
    return false;
  };

  return (
    <FormInput.Root error={!!emailError}>
      <FormInput.FieldSet>
        <FormInput.Legend>
          {endUserT([DynamicTranslationType.globalEmail], ['CONFIRMED_EMAIL'])}
        </FormInput.Legend>
        <FormInput.InputIcon name="mail" />
        <FormInput.TextInput
          autoFocus
          data-testid="authModalRegisterViewConfirmEmailInput"
          name="confirmedEmail"
          onBlur={onVerifyEmail}
          onChange={onConfirmedEmailChange}
          onCopy={lock}
          onPaste={lock}
          placeholder={endUserT([DynamicTranslationType.createAccountConfirmEmailPlaceholder], ['CONFIRMED_EMAIL'])}
          type="email"
          value={confirmedEmail}
        />
      </FormInput.FieldSet>
      {emailError && <FormInput.SupportingText>{emailError}</FormInput.SupportingText>}
    </FormInput.Root>
  );
};

ConfirmedEmailInput.propTypes = {
  confirmedEmail: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  onConfirmedEmailChange: PropTypes.func.isRequired,
  onUpdateErrors: PropTypes.func.isRequired,
};

export default ConfirmedEmailInput;
