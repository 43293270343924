import React from 'react';
import { RadioInputCard } from 'components/ui/RadioInputCard';
import { IGlobalAccountAdminSites } from 'services/auth/models';
import { AdminSitesWrapper, Slug } from './styles';

type AdminSiteProps = {
  isSelected: boolean;
  onClick: () => void;
  site: IGlobalAccountAdminSites;
};

const AdminSite: React.FC<AdminSiteProps> = React.memo(({ site, isSelected, onClick }) => {
  return (
    <RadioInputCard name="admin-sites" checked={isSelected} onClick={onClick}>
      <Slug>{`${window.location.origin}/${site.slug}`}</Slug>
    </RadioInputCard>
  );
});

type AdminSitesProps = {
  onChange: (site: IGlobalAccountAdminSites) => void;
  selectedSite: IGlobalAccountAdminSites;
  sites: IGlobalAccountAdminSites[];
};

const AdminSites: React.FC<AdminSitesProps> = ({ sites, onChange, selectedSite }) => {
  const handleSelectSite = React.useCallback((site: IGlobalAccountAdminSites) => () => {
    onChange(site);
  }, []);

  return (
    <AdminSitesWrapper>
      {sites.map(site => {
        return (
          <AdminSite
            key={site.slug}
            site={site}
            isSelected={site.slug === selectedSite.slug}
            onClick={handleSelectSite(site)}
          />
        );
      })}
    </AdminSitesWrapper>
  );
};

export default AdminSites;
