/* eslint react/destructuring-assignment: off */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Link from 'components/ui/Link';
import { LEGACY_DOMAIN } from 'config';
import adobeLogo from 'assets/adobe-logo.png';
import { ADOBE_RED } from 'style/constants';
import qs from 'qs';
import BaseLoginButton from '../BaseLoginButton';

export const ImgIcon = styled.img`
  height: 22px;
  width: 22px;
`;

/* eslint-disable camelcase */
const createAdobeUri = (redirectUrl) => {
  const redirect_uri = `https://${LEGACY_DOMAIN}/pkg/dobi-api/4.0/api/adobe/callback?location=${encodeURIComponent(redirectUrl)}`;
  const params = {
    client_id: 'adobe_maestro',
    redirect_uri,
    response_type: 'code',
    scope: 'openid',
  };
  return `https://ims-na1.adobelogin.com/ims/authorize/v1?${qs.stringify(params)}`;
};

export default class AdobeLoginButton extends React.Component {
  static propTypes = {
    redirectUrl: PropTypes.string.isRequired,
    signUp: PropTypes.bool.isRequired,
  };

  shouldComponentUpdate(nextProps) {
    return nextProps.redirectUrl !== this.props.redirectUrl;
  }

  render() {
    const { signUp } = this.props;
    return (
      <Link href={createAdobeUri(this.props.redirectUrl)} plain target="_self">
        <BaseLoginButton
          color={ADOBE_RED}
          dataTestId="authModalAdobeLoginButton"
          providerNameKey="ADOBE"
          renderIcon={
            () => <ImgIcon src={adobeLogo} />
          }
          signUp={signUp}
        />
      </Link>
    );
  }
}
