import PropTypes from 'prop-types';
import React from 'react';
import { Transition, TransitionGroup } from 'react-transition-group';
import Modal from 'components/modals/Modal';

const TRANSITION_MS = 200;

const CONTAINER_TRANSITION_STYLES = {
  entering: {
    opacity: 1,
    transition: `opacity ${TRANSITION_MS}ms ease-in`,
  },
  exiting: {
    opacity: 0,
    transition: `opacity ${TRANSITION_MS}ms ease-in`,
  },
};

const MODAL_TRANSITION_STYLES = {
  entered: {
    transition: `transform ${TRANSITION_MS}ms cubic-bezier(0.175, 0.885, 0.32, 1.275)`,
  },
  entering: {
    transform: 'scale(0)',
  },
  exiting: {
    transform: 'scale(0)',
    transition: `transform ${TRANSITION_MS}ms cubic-bezier(0.6, -0.28, 0.735, 0.045)`,
  },
};

const ModalContainer = ({ modals, onDismissModal }) => (
  <TransitionGroup>
    {
      modals.length > 0 && (
        <Transition in timeout={TRANSITION_MS}>
          {
            (state) => {
              let gdprModal = null;
              const filteredModals = [];
              for (const modal of modals) {
                if (modal.kind === 'gdpr') {
                  gdprModal = modal;
                } else {
                  filteredModals.push(modal);
                }
              }

              //  grabbing top most recent modal that's not gdpr
              const { kind, data } = filteredModals[filteredModals.length - 1] ?? {};

              return (
                <>
                  {gdprModal && (
                    <Modal
                      data={gdprModal.data}
                      kind={gdprModal.kind}
                      onDismiss={() => onDismissModal(gdprModal.kind)}
                    />
                  )}
                  {kind && (
                    <Modal
                      backgroundStyle={CONTAINER_TRANSITION_STYLES[state]}
                      data={data}
                      kind={kind}
                      onDismiss={() => onDismissModal(kind)}
                      style={MODAL_TRANSITION_STYLES[state]}
                    />
                  )}
                </>
              );
            }
          }
        </Transition>
      )
    }
  </TransitionGroup>
);

ModalContainer.propTypes = {
  modals: PropTypes.arrayOf(PropTypes.shape({
    data: PropTypes.any,
    kind: PropTypes.string.isRequired,
  })).isRequired,
  onDismissModal: PropTypes.func.isRequired,
};

export default ModalContainer;
