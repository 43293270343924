import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'hooks';

import { logIn, OauthProvider } from 'services/auth';
import Link from 'components/ui/Link';
import PlaystationLogo from 'assets/playstation-logo.svg';
import BaseLoginButton from './BaseLoginButton';

export const ImgIcon = styled.img`
  height: 21px;
  width: 21px;
`;

interface IProps {
  signUp: boolean;
}

// active and working oauth service
const PlaystationLoginButton = (({ signUp }: IProps) => {
  const dispatch = useDispatch();
  const triggerLogin = () => {
    dispatch(logIn({ provider: OauthProvider.PLAYSTATION, strategy: 'oauth' }));
  };

  return (
    <Link onClick={triggerLogin} target="_self">
      <BaseLoginButton
        color="#0063C5"
        dataTestId="authModalPlaystationLoginButton"
        providerNameKey="PLAYSTATION"
        renderIcon={ // tslint:disable-next-line: jsx-no-lambda no-empty
          () => <ImgIcon src={PlaystationLogo} />
        }
        signUp={signUp}
      />
    </Link>
  );
});

export default PlaystationLoginButton;
