import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import AdminGate from 'components/objects/Gate/AdminGate';
import AgeGate from 'components/objects/Gate/AgeGate';
import LoginGate from 'components/objects/Gate/LoginGate';
import PasswordGate from 'components/objects/Gate/PasswordGate';
import SubscriptionGate from 'components/objects/Gate/SubscriptionGate';
import AccessTokenGate from 'components/objects/Gate/AccessTokenGate';
import { AmazonBenefitGate } from 'components/objects/Gate/AmazonBenefitGate';
import { mobileOnly } from 'style/mixins';
import { Feature } from 'services/feature-gate';

const GateContainer = styled.div`
  background: transparent;
  box-sizing: border-box;
  display: flex;
  flex-grow: 1;
  align-items: center;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  ${({ channelGateActive, videoWidth }) => !channelGateActive && `width: ${videoWidth}px;`};
  ${props => (
    props.kind === 'subscription' && props.isDesktopLayout ?
      `height: ${props.subscriptionContentHeight}px;` : 'margin: 10px;'
  )}
  ${mobileOnly`
    height: 100%;
    position: absolute;
    width: 100%;
    margin: 0;
  `}
  & > * {
    margin-top: auto;
    margin-bottom: auto;
  }
  transition: opacity 0.5s;
  ${({ isDisabled }) => isDisabled && `
    pointer-events: none;
    opacity: 0.5;
  `}
`;

export default class Gate extends React.Component {
  static propTypes = {
    channelGateActive: PropTypes.bool.isRequired,
    channelGateKind: PropTypes.string,
    className: PropTypes.string,
    featureGates: PropTypes.object.isRequired,
    isDesktopLayout: PropTypes.bool.isRequired,
    isGateActive: PropTypes.bool.isRequired,
    regionRenderingStripeCreditCard: PropTypes.string,
    showMobileChannelSelector: PropTypes.bool.isRequired,
    subscriptionContentHeight: PropTypes.number.isRequired,
    videoWidth: PropTypes.number.isRequired,
  };

  static defaultProps = {
    channelGateKind: null,
    className: '',
    regionRenderingStripeCreditCard: null,
  };

  renderGate() {
    const { channelGateKind, className, featureGates } = this.props;
    switch (channelGateKind) {
      case 'admin':
        return <AdminGate />;
      case 'age':
        return <AgeGate />;
      case 'login':
        return <LoginGate />;
      case 'password':
        return (
          <PasswordGate />
        );
      case 'access_code':
        return (
          <AccessTokenGate className={className} />
        );
      case 'subscription':
        return (
          <SubscriptionGate className={className} />
        );
      case 'amazon_benefit':
        if (!featureGates[Feature.AMAZON_BENEFIT]) {
          return null;
        }
        return (
          <AmazonBenefitGate className={className} />
        );
      default:
        return null;
    }
  }

  render() {
    const {
      className,
      channelGateActive,
      channelGateKind,
      isDesktopLayout,
      isGateActive,
      regionRenderingStripeCreditCard,
      showMobileChannelSelector,
      subscriptionContentHeight,
      videoWidth,
    } = this.props;

    if (!isGateActive) {
      return null;
    }
    return channelGateActive ? (
      <GateContainer
        channelGateActive={channelGateActive}
        className={className}
        isDesktopLayout={isDesktopLayout}
        isDisabled={showMobileChannelSelector}
        kind={channelGateKind}
        regionRenderingStripeCreditCard={regionRenderingStripeCreditCard}
        subscriptionContentHeight={subscriptionContentHeight}
        videoWidth={videoWidth}
      >
        { channelGateKind && this.renderGate() }
      </GateContainer>
    ) : null;
  }
}
