import React, { useCallback, useMemo } from 'react';
import {
  CloseMark,
  CloseRegion,
  Container,
  NavContainer,
  SearchWrapper,
} from './styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  getSearchText,
  getNavigationAppliedInThePage,
  setSearchText,
} from 'services/navigationv2';
import ParentNavigation from './ParentNavigation';
import SearchInput from 'components/admin2/ui/SearchInput';
import useDismissOnSwipeDown from 'hooks/use-dismiss-on-swipe-down';
import MarketingAuthHeader from 'components/ui/MarketingAuthHeader';
import { IS_MARKETING_SITE } from 'config';
import useMobileLayout from '../MobileLayout/use-mobile-layout';
import { isLoggedIn } from 'services/auth';
import { externalLinkToNavigationParentAdapter } from './utils';
import { hidePrivatePagesFromNavigation } from 'services/navigationv2/utils';

const MARK_PADDING = 20;

interface IMobileNavigationProps {
  onClose: () => void;
}

const MobileNavigation: React.FC<IMobileNavigationProps> = ({ onClose }) => {
  const appliedNav = useSelector(getNavigationAppliedInThePage);
  const appliedNavigation = useMemo(() => hidePrivatePagesFromNavigation(appliedNav), [appliedNav]);
  const searchValue = useSelector(getSearchText);
  const isUserLoggedIn = useSelector(isLoggedIn);
  const { setMobileMenu } = useMobileLayout();
  const dispatch = useDispatch();

  const closeregionRef = React.useRef<HTMLDivElement>(null);
  const containerRef = React.useRef<HTMLDivElement>(null);

  const handleMarketingAuthHeaderClick = useCallback(() => {
    if (isUserLoggedIn) {
      setMobileMenu('profileMenu');
    } else {
      setMobileMenu('loginMenu');
    }
  }, [isUserLoggedIn]);

  const { isCloseMarkActive, slidingDownValue } = useDismissOnSwipeDown({
    onClose,
    closeregionRef,
    containerRef,
    padding: MARK_PADDING,
  });

  const displayedNavigation = useMemo(() => {
    if (searchValue === '') return appliedNavigation;
    const searchResult = appliedNavigation.parents.filter((parent) => {
      if (parent.name.toLowerCase().includes(searchValue.toLowerCase()))
        return true;
      if (parent.children) {
        const children = parent.children.filter((child) => {
          if (child.name.toLowerCase().includes(searchValue.toLowerCase())) {
            return true;
          }
          return false;
        });
        if (children.length > 0) return true;
      }
    });

    const linksSearchResult = appliedNavigation.externalLinks.filter((link) => {
      if (link.name.toLowerCase().includes(searchValue.toLowerCase()))
        return true;
    });

    return { ...appliedNavigation, parents: searchResult, externalLinks: linksSearchResult };
  }, [appliedNavigation, searchValue]);

  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setSearchText(e.target.value));
  };

  const renderDisplayedNavigation = useCallback(() => {
    const shouldDisplayNavigation =
      displayedNavigation &&
      displayedNavigation.parents &&
      displayedNavigation.parents.length > 0;
    if (shouldDisplayNavigation) {
      return displayedNavigation.parents.map((parent, index) => (
        <ParentNavigation key={index} parent={parent} onDismiss={onClose} />
      ));
    }
    return null;
  }, [displayedNavigation, onClose]);

  const renderLinks = useCallback(() => {
    const { externalLinks } = displayedNavigation;
    if (externalLinks && externalLinks.length > 0) {
      return externalLinkToNavigationParentAdapter(externalLinks).map(
        (link, index) => (
          <ParentNavigation
            key={index}
            parent={link}
            isLink={true}
            onDismiss={onClose}
          />
        ),
      );
    }
    return null;
  }, [displayedNavigation, onClose]);

  return (
    <Container
      ref={containerRef}
      topPosition={slidingDownValue}
      isSearchOpen={searchValue !== ''}
    >
      <CloseRegion ref={closeregionRef}>
        <CloseMark isActive={isCloseMarkActive} />
      </CloseRegion>
      <SearchWrapper>
        <SearchInput
          height={30}
          onChange={handleSearchInputChange}
          padding="0"
          searchPlaceholderKey="SEARCH_ALL_PAGES"
          short={true}
          testIdSearchInput="siteNavigationSearchInput"
          value={searchValue}
        />
      </SearchWrapper>
      <NavContainer>
        <>{renderDisplayedNavigation()}</>
        <>{renderLinks()}</>
      </NavContainer>
      {IS_MARKETING_SITE && !isUserLoggedIn && <MarketingAuthHeader onClick={handleMarketingAuthHeaderClick} />}
    </Container>
  );
};

export default MobileNavigation;
