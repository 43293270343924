import React from 'react';
import CountTo from 'components/ui/CountTo';
import { CompletedBy, CompletedByIcon } from './styles';
import useQuestCompletedByHowMany from 'hooks/use-quest-completed-by-how-many';

const QuestCompletedBy = ({ questId }: { questId: string; }) => {
  const completedBy = useQuestCompletedByHowMany(questId);

  if (!completedBy) {
    return null;
  }

  return (
    <CompletedBy>
      <CompletedByIcon />
      <span>
        <CountTo from={0} target={completedBy}>
          {renderCount => Math.round(renderCount).toLocaleString()}
        </CountTo>
      </span>
    </CompletedBy>
  );
};

export default QuestCompletedBy;
