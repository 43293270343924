import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import warning from 'warning';
import {
  dateLocales,
  dateOptions,
  localizedFormats,
  momentFormats,
} from './constants';

export default class FormattedTimestamp extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    compact: PropTypes.bool,
    dataTestId: PropTypes.string,
    endTimestamp: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.instanceOf(Date),
    ]),
    locale: PropTypes.string.isRequired,
    noLocale: PropTypes.bool,
    show: PropTypes.oneOf([
      'ampm',
      'date',
      'datetime',
      'day',
      'localdate',
      'md',
      'mdy',
      'month',
      'prettyDate',
      'time',
      'timeRange',
      'timezone',
      'weekday',
    ]).isRequired,
    timestamp: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.instanceOf(Date),
    ]).isRequired,
  };

  static defaultProps = {
    className: '',
    compact: true,
    dataTestId: '',
    endTimestamp: null,
    noLocale: false,
  };

  formatTimestamp() {
    const { compact, locale, show, timestamp, endTimestamp, noLocale } = this.props;
    const momentTimestamp = moment.utc(timestamp);
    const dateObject = new Date(timestamp);
    const useLocalized = locale && locale !== 'en' && !noLocale;
    const formats = useLocalized ? localizedFormats : momentFormats;
    const dateLocale = dateLocales[locale];
    const momentLocale = locale;

    if (useLocalized) {
      momentTimestamp.locale(momentLocale);
    }

    switch (show) {
      case 'ampm':
        return momentTimestamp.local().format(momentFormats.ampm);
      case 'day':
        return dateObject.toLocaleString(dateLocale, dateOptions.day);
      case 'date': {
        const format = compact ? formats.compactDate : formats.date;
        return momentTimestamp.local().format(format);
      }
      case 'datetime': {
        const format = compact ? formats.compactDatetime : formats.datetime;
        return momentTimestamp.local().format(format);
      }
      case 'prettyDate': {
        const year = moment().local().year();
        const local = momentTimestamp.local();
        const showYear = year !== local.year();
        if (useLocalized) {
          const localeWeekday = dateObject.toLocaleString(dateLocale, dateOptions.weekday);
          return showYear ?
            `${localeWeekday} ${local.format(localizedFormats.prettyDate)}` :
            dateObject.toLocaleString(dateLocale, dateOptions.prettyDate);
        }
        const format = showYear ? momentFormats.prettyDate : momentFormats.compactPrettydate;
        return local.format(format);
      }
      case 'localdate':
        return momentTimestamp.local().format(localizedFormats.compactDate);
      case 'md': {
        const localeMonth = dateObject.toLocaleString(dateLocale, dateOptions.shortMonth);
        const localeDay = dateObject.toLocaleString(dateLocale, dateOptions.day);
        return useLocalized ?
          `${localeMonth} ${localeDay}` :
          momentTimestamp.local().format(momentFormats.md);
      }
      case 'mdy': {
        return momentTimestamp.local().format(formats.mdy);
      }
      case 'month':
        return dateObject.toLocaleString(dateLocale, dateOptions.shortMonth);
      case 'timeRange': {
        const format = compact ? formats.compactTime : formats.time;
        const startTime = momentTimestamp.local().format(format);
        const timezone = dateObject.toLocaleDateString(dateLocale, dateOptions.timezone).split(' ')[1];
        const toEnd = endTimestamp ?
          ` - ${moment.utc(endTimestamp).locale(momentLocale).local().format(format)}` :
          '';
        if (useLocalized) {
          return `${startTime}${toEnd}`;
        }
        return `${startTime}${toEnd} ${timezone}`;
      }
      case 'time': {
        const format = compact ? formats.compactTime : formats.time;
        return momentTimestamp.local().format(format);
      }
      case 'timezone':
        return dateObject.toLocaleString(dateLocale, dateOptions.timezone).split(' ')[1];
      case 'weekday':
        if (useLocalized) {
          return dateObject.toLocaleString(dateLocale, dateOptions.weekday);
        }
        return dateObject.toLocaleString(dateLocales.en, dateOptions.weekday);
      default:
        break;
    }

    warning(false, 'FormattedTimestamp: Invalid formatter properties specified.');
    return null;
  }

  render() {
    const { className, dataTestId } = this.props;
    return (
      <span className={className} data-testid={dataTestId}>
        { this.formatTimestamp() }
      </span>
    );
  }
}
