import { rgba } from 'colors';
import Icon from 'components/ui/Icon';
import { ACCENT_PRIMARY, ALERT_CONFIRMATION, ALERT_ERROR, ALERT_WARNING, SURFACE_1, TEXT_100 } from 'style/constants';
import { TEXT_LABEL_S_MEDIUM } from 'style/design-system/textStyles';
import styled from 'styled-components';

export const Pill = styled.div`
  padding: 4px;
  padding-right: 10px;
  display: flex;
  align-items: center;
  gap: 6px;
  border-radius: 16px;
  background-color: ${props => rgba(SURFACE_1(props), 0.5)};
  width: fit-content;
  align-self: baseline;
`;

export const ProgressText = styled.label`
  ${TEXT_LABEL_S_MEDIUM}
  color: ${TEXT_100};
`;

export const Dot = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${ACCENT_PRIMARY};
`;

export const RedDot = styled(Dot)`
  background-color: ${ALERT_ERROR};
`;

export const YellowDot = styled(Dot)`
  background-color: ${ALERT_WARNING};
`;



export const ClaimedIcon = styled(Icon).attrs({
  name: 'checkmarkCircle',
})`
  & svg {
    width: 16px;
    height: 16px;
    & path {
      fill: ${ALERT_CONFIRMATION};
    }
  }
`;

export const CompletedIcon = styled(ClaimedIcon)`
  & svg {
    & path {
      fill: ${ACCENT_PRIMARY};
    }
  }
`;

export const FailedIcon = styled(Icon).attrs({
  name: 'close-thin',
})`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: ${ALERT_ERROR};
  position: relative;
  & svg {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 6.67px;
    height: 6.67px;
  }
`;
