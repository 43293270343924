import styled from 'styled-components';
import FormattedTimestamp from 'components/i18n/FormattedTimestamp';
import Icon from 'components/ui/Icon';
import { rgba } from 'colors';
import {
  TEXT_H5,
  TEXT_LABEL1,
  TEXT_H6,
} from 'style/mixins';
import { TEXT_100 } from 'style/constants';

const COUNTDOWN_HEIGHT = '64px';

export const MobileCountdownContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 20px;
  position: absolute;
  width: 100%;
`;

export const CountdownWrapper = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  overflow: hidden;
  min-height: ${COUNTDOWN_HEIGHT};
  align-items: center;
  border: 1px solid rgba(255, 255, 255, 0.2);
`;

export const FlexWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 8px;
  width: 190px;
  height: ${COUNTDOWN_HEIGHT};
  border-left: 1px solid rgba(255, 255, 255, 0.2);
`;

export const CountdownNumbersWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const BellIcon = styled(Icon).attrs(props => ({
  color: TEXT_100(props),
  name: 'bell',
}))`
  width: 20px;
  height: 20px;
  align-items: center;
  display: flex;
  margin-right: 8px;
  padding: 5px;
  justify-content: center;
  background-color: rgba(255,255,255,0.2);;
  fill: white;
  cursor: pointer;
`;

export const FormattedMonth = styled(FormattedTimestamp)`
  ${TEXT_H6}
  text-align:center;
`;
export const FormattedDate = styled(FormattedTimestamp)`
  ${TEXT_LABEL1}
  display: flex;
  align-item: center;
  justify-content: center;
  text-align:center;
`;

export const CountdownEndDate = styled.div`
  color: white;
  min-width: 50px;
  height: ${COUNTDOWN_HEIGHT};
  display: flex;
  ${TEXT_H6}
  justify-content: center;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  flex-direction: column;
  padding: 10px;
  border: 1px solid ${props => rgba(TEXT_100(props), 0.2)};
  border-right: none;
`;

export const NumberWrapper = styled.div`
  ${TEXT_LABEL1}
  color: white;
  display: flex;
  min-width: 22px;
`;

export const Colon = styled.div`
  opacity: .6;
  flex-shrink: 0;
  padding: 0 5px;
`;

export const Message = styled.div`
  ${TEXT_H5}
  text-align: center;
  width: 50%;
  margin-bottom: 16px;
  text-transform: uppercase;
  color: white;
`;
