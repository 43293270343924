import React, {
  useEffect,
  useState,
  useCallback,
} from 'react';
import styled from 'styled-components';
import isEqual from 'lodash/isEqual';
import useSelector from 'hooks/use-selector';
import useDispatch from 'hooks/use-dispatch';
import { isUserLoggedIn, logOut } from 'services/auth';
import {
  getAccountEntitlements,
  getAccountEntitlementsLoaded,
  getFixedGateData,
  userHasSubscriptions,
} from 'services/gate/selectors';
import { setAccessCodeEntryValid } from 'services/gate/actions';
import { dismissModal, loginModal, showModal } from 'services/modals/actions';
import AccessTokenGate from 'components/objects/Gate/AccessTokenGate';
import { clearBillingInfo, getNativeCurrency, setEntitlementData } from 'services/billing';
import { completePaymentProcess } from 'services/billing/actions';
import { mobileOnly, mobileLandscapeOnly, THIN_SCROLL_BAR, desktopOnly } from 'style/mixins';
import { checkGate } from 'services/auth/actions';
import { GATE_ENTITLEMENT_ID } from 'global-ids';
import { DATE_TIME, DEEP_LINK } from 'injection-classes';
import { ACCENT_PRIMARY, TEXT_100, BODY_FONT_FAMILY, TEXT_300 } from 'style/constants';
import { AccessCodeGateAccessControlElements } from './AccessTokenGate';
import {
  StyledDateAndTime,
  TicketsAndSubs,
  Bundles,
} from './styles';
import ISubscription, { SubscriptionType } from 'models/ISubscription';
import { getSubscriptionPrice } from 'utils';
import IBundle from 'models/IBundle';

import TicketOrSub from './TicketOrSub';
import Bundle from './Bundle';
import { ModalKinds } from 'services/modals/types';
import { isBundle } from 'services/bundle/utils';
import { DynamicTranslationType, useAdminTranslation, useEndUserTranslation } from 'hooks/use-translation';
import GateTitle from './GateTitle';
import GateSubtitle from './GateSubtitle';
import { TEXT_LABEL_M_BOLD } from 'style/design-system/textStyles';

const Container = styled.div`
  font-family: ${BODY_FONT_FAMILY};
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  ${mobileLandscapeOnly`
    height: unset;
    padding-top: 0px;
  `}
  ${mobileOnly`padding: 30px 15px 0px 15px;`};
  ${desktopOnly`
    padding: 40px;
    padding-bottom: 0;
  `};
`;

const WrapperContent = styled.div`
  ${THIN_SCROLL_BAR}
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  max-height: 100%;
  padding: 25px 0px;
  ${mobileOnly`
    max-width: 100%;
  `}
  ${mobileOnly`
    max-width: 100%;
  `}
`;

const SubscriptionContainer = styled.div`
  margin-bottom: 20px;
`;

const LoginTextWrapper = styled.div`
  font-size: 14px;
  margin-bottom: 10px;
`;

const TextWrapper = styled.span`
  color: ${TEXT_300};
  margin-right: 5px;
`;

const StyledLink = styled.span`
  color: ${ACCENT_PRIMARY};
  text-decoration: underline;
  cursor: pointer;
`;

const NotAvailableForPurchase = styled.div`
  ${TEXT_LABEL_M_BOLD}
  color: ${TEXT_100};
  ${desktopOnly`margin-top: 50px;`}
  ${mobileOnly`margin-top: 40px;`}
`;

interface ISubscriptionTicketGateAccessControlElements {
  bundles: Array<IBundle>;
  handleBundleButtonClick?: (selectedItem: IBundle) => void;
  handleSubscriptionButtonClick?: (selectedItem: ISubscription) => void;
  hiddenBundles: string[];
  hiddenEntitlements: Array<string>;
  subscriptions: Array<ISubscription>;
}

interface IGetFilteredSubscription {
  hiddenEntitlements: Array<string>;
  nativeCurrency: string | null;
  subscriptions: Array<ISubscription>;
}

interface IGetFilteredBundles {
  bundles: Array<IBundle>;
  hiddenBundles: string[];
}

interface IGetShouldRenderAccessCodeGate {
  bundles: Array<IBundle>;
  nativeCurrency: string | null;
  subscriptions: Array<ISubscription>;
}

const getFilteredSubscriptions = ({
  hiddenEntitlements,
  nativeCurrency,
  subscriptions,
}: IGetFilteredSubscription) => {
  return subscriptions
    .filter((subscription) => { // take out hidden entitlements and subs with no valid price
      const price = getSubscriptionPrice(subscription, nativeCurrency);
      return !hiddenEntitlements.includes(subscription.sku) && price.value > 0;
    });
};

const getFilteredBundles = ({
  hiddenBundles,
  bundles,
}: IGetFilteredBundles) => {
  return bundles.filter((bundle) => !hiddenBundles.includes(bundle.sku));
};

const shouldItRenderAccessCodeGate = ({ subscriptions, bundles, nativeCurrency }: IGetShouldRenderAccessCodeGate) => {
  return subscriptions.every((sub) => {
    const price = getSubscriptionPrice(sub, nativeCurrency);
    return (!sub.prices || price.value === 0) && !sub.price;
  }) && !bundles.length;
};

export const SubscriptionTicketGateAccessControlElements = ({
  bundles,
  handleSubscriptionButtonClick,
  handleBundleButtonClick,
  hiddenEntitlements,
  hiddenBundles,
  subscriptions,
}: ISubscriptionTicketGateAccessControlElements) => {
  const nativeCurrency = useSelector(getNativeCurrency);
  const ticketsAndSubs = getFilteredSubscriptions({ hiddenEntitlements, nativeCurrency, subscriptions });
  const filteredBundles = getFilteredBundles({ hiddenBundles, bundles });
  const shouldRenderAccessCodeGate = shouldItRenderAccessCodeGate({ bundles, subscriptions, nativeCurrency });
  const noVisibleEntitlements = !ticketsAndSubs.length && !filteredBundles.length;
  const { t } = useAdminTranslation();

  if (shouldRenderAccessCodeGate) {
    return <AccessCodeGateAccessControlElements isTransparent={true} />;
  }

  return (
    <SubscriptionContainer data-testid="ticketsAndSubscriptionsGateSection">
      <Bundles>
        {filteredBundles.map((bundle: IBundle, index: number) => {
          return (
            <Bundle
              data-testId={`gate-item-bundle-${index}`}
              key={bundle._id}
              bundle={bundle}
              handleBundleButtonClick={handleBundleButtonClick}
            />
          );
        })}
      </Bundles>
      <TicketsAndSubs>
        {ticketsAndSubs.map((subscription: ISubscription) => {
          return (
            <TicketOrSub
              data-testId="gate-item-entitlement"
              key={subscription._id}
              handleSubscriptionButtonClick={handleSubscriptionButtonClick}
              subscription={subscription}
            />
          );
        })}
      </TicketsAndSubs>
      {noVisibleEntitlements && <NotAvailableForPurchase>{t('NO_CONTENT_AVAILABLE_FOR_PURCHASE')}</NotAvailableForPurchase>}
    </SubscriptionContainer>
  );
};

const SubscriptionGate = () => {
  const { t } = useAdminTranslation();
  const {
    timestamp,
    bundles,
    subscriptions,
    hiddenEntitlements,
    hiddenBundles,
  } = useSelector(getFixedGateData(t), isEqual);
  const nativeCurrency = useSelector(getNativeCurrency);
  const shouldRenderAccessCodeGate = shouldItRenderAccessCodeGate({ bundles, subscriptions, nativeCurrency });
  const dispatch = useDispatch();
  const loggedIn = useSelector(isUserLoggedIn);
  const hasAccess = useSelector(userHasSubscriptions(t));
  const entitlements = useSelector(getAccountEntitlements);
  const accountEntitlementsLoaded = useSelector(getAccountEntitlementsLoaded);
  const [showAccessCode, setShowAccessCode] = useState(false);
  const [selectedItem, setSelectedItem] = useState<ISubscription | IBundle | null>(null);
  const ticketsAndSubs = getFilteredSubscriptions({ hiddenEntitlements, nativeCurrency, subscriptions });
  const filteredBundles = getFilteredBundles({ hiddenBundles, bundles });
  const hasVisibleEntitlements = !!ticketsAndSubs.length || !!filteredBundles.length;

  const { endUserT } = useEndUserTranslation();

  useEffect(() => {
    if (hasAccess) dispatch(checkGate());
  }, [hasAccess]);

  // if a user selects a subscription or bundle and is not logged in, we show the log in modal
  // as soon as they log in, we show the subscription or bundle modal
  // Although, if they already have access, this component will be unmounted
  // and we don't need to show the subscription or bundle modal
  useEffect(() => {
    return () => {
      dispatch(dismissModal(ModalKinds.bundlePurchase));
      dispatch(dismissModal(ModalKinds.subscriptionPurchase));
    };
  }, []);

  useEffect(() => {
    if (!loggedIn) {
      setShowAccessCode(false);
      return;
    }

    if (!selectedItem) {
      return;
    }

    if (isBundle(selectedItem)) {
      dispatch(showModal({ kind: ModalKinds.bundlePurchase, data: { bundle: selectedItem, locked: true } }));
      setSelectedItem(null);
      return;
    } else {
      // if its not a bundle, at this point currently it must be a subscription
      const price = getSubscriptionPrice(selectedItem, nativeCurrency);
      dispatch(setEntitlementData({
        entitlementPrice: price,
        subscriptionType: selectedItem.type,
      }));
      if (accountEntitlementsLoaded && !entitlements.some(e => e.entitlement._id === selectedItem._id)) {
        dispatch(showModal({ kind: ModalKinds.subscriptionPurchase, data: { subscription: selectedItem, locked: true } }));
      } else {
        return;
      }
    }

    setSelectedItem(null);
  }, [loggedIn, selectedItem, nativeCurrency, accountEntitlementsLoaded]);

  const onLinkClick = useCallback(() => {
    dispatch(loginModal());
  }, []);

  const onAccessCodeClick = useCallback(() => {
    setShowAccessCode(true);
  }, []);

  const handleBack = useCallback(() => {
    setShowAccessCode(false);
    dispatch(clearBillingInfo());
    dispatch(completePaymentProcess());
    dispatch(setAccessCodeEntryValid(null));
  }, []);

  const handleLogout = useCallback(() => {
    dispatch(logOut());
    dispatch(loginModal());
  }, []);

  const handleSubscriptionButtonClick = useCallback(
    (subscription: ISubscription) => {
      const price = getSubscriptionPrice(subscription, nativeCurrency);
      if (!price.value) {
        setShowAccessCode(true);
        return;
      }

      if (!loggedIn) {
        dispatch(loginModal());
        setSelectedItem(subscription);
      } else {
        dispatch(setEntitlementData({
          entitlementPrice: price,
          subscriptionType: subscription.type,
        }));
        dispatch(showModal({ kind: ModalKinds.subscriptionPurchase, data: { subscription, locked: true } }));
      }
    },
    [loggedIn, nativeCurrency],
  );

  const handleBundleButtonClick = useCallback((bundle: IBundle) => {
    if (!loggedIn) {
      dispatch(loginModal());
      setSelectedItem(bundle);
    } else {
      dispatch(showModal({ kind: ModalKinds.bundlePurchase, data: { bundle, locked: true } }));
    }
  }, [loggedIn]);

  return (
    <>
      {shouldRenderAccessCodeGate ? (
        <AccessTokenGate />
      ) : showAccessCode ? (
        <AccessTokenGate handleBack={handleBack} />
      ) : (
        <Container id={GATE_ENTITLEMENT_ID}>
          <WrapperContent>
            {timestamp && (
              <StyledDateAndTime className={DATE_TIME} timeStamp={timestamp} />
            )}
            <GateTitle />
            <GateSubtitle />
            <SubscriptionTicketGateAccessControlElements
              bundles={bundles}
              handleSubscriptionButtonClick={handleSubscriptionButtonClick}
              handleBundleButtonClick={handleBundleButtonClick}
              subscriptions={subscriptions}
              hiddenEntitlements={hiddenEntitlements}
              hiddenBundles={hiddenBundles}
            />
            {!loggedIn && (
              <LoginTextWrapper>
                <TextWrapper>
                  {endUserT(
                    [DynamicTranslationType.gateAlreadyPurchasedQuestion],
                    ['ALREADY_PURCHASED_QUESTION'],
                  )}
                </TextWrapper>
                <StyledLink className={DEEP_LINK} onClick={onLinkClick}>
                  {
                    endUserT([DynamicTranslationType.globalActionLogIn], ['ACTION_LOG_IN'])
                  }
                </StyledLink>
              </LoginTextWrapper>
            )}
            {!showAccessCode && hasVisibleEntitlements && (
              <LoginTextWrapper>
                <TextWrapper>
                  {endUserT([DynamicTranslationType.gateHaveAnAccessCode], ['HAVE_AN_ACCESS_CODE'])}
                </TextWrapper>
                <StyledLink
                  className={DEEP_LINK}
                  data-testid="egEnterCodeButton"
                  onClick={onAccessCodeClick}
                >
                  {endUserT([DynamicTranslationType.gateEnterCode], ['ENTER_CODE'])}
                </StyledLink>
              </LoginTextWrapper>
            )}
            {!showAccessCode && loggedIn && hasVisibleEntitlements && (
              <LoginTextWrapper>
                <TextWrapper>
                  {endUserT(
                    [DynamicTranslationType.gateBoughtTicketDifferentAccount],
                    ['BOUGHT_TICKET_DIFFERENT_ACCOUNT'],
                  )}
                </TextWrapper>
                <StyledLink className={DEEP_LINK} onClick={handleLogout}>
                  {endUserT(
                    [DynamicTranslationType.gateSwitchAccount],
                    ['SWITCH_ACCOUNT'],
                  )}
                </StyledLink>
              </LoginTextWrapper>
            )}
          </WrapperContent>
        </Container>
      )}
    </>
  );
};

export default SubscriptionGate;
