/* eslint react/destructuring-assignment: off */
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import qs from 'qs';

import Link from 'components/ui/Link';
import { LEGACY_DOMAIN, STATIC_BASE_URL } from 'config';
import { MAESTRO_WHITE } from 'style/constants';
import BaseLoginButton from '../BaseLoginButton';

export const ImgIcon = styled.img`
  height: 22px;
  width: 22px;
`;

const XBOX_CLIENT_ID = 'edd6f875-4cbd-426a-8138-0f032b4977bf';

const createXboxRedirect = (redirectUrl) => {
  const apiRedirect = `https://${LEGACY_DOMAIN}/pkg/dobi-api/4.0/api/xbox/login/callback`;
  const apiParam = `redirect=${encodeURIComponent(redirectUrl)}`;
  const url = 'https://login.live.com/oauth20_authorize.srf';
  const params = {
    client_id: XBOX_CLIENT_ID,
    fl: 'email',
    redirect_uri: `${apiRedirect}?${apiParam}`,
    response_type: 'code',
    scope: 'Xboxlive.signin Xboxlive.offline_access',
  };
  return `${url}?${qs.stringify(params)}`;
};

export default class MicrosoftLoginButton extends React.Component {
  static propTypes = {
    redirectUrl: PropTypes.string.isRequired,
    signUp: PropTypes.bool.isRequired,
  };

  state = {
    redirectHref: createXboxRedirect(this.props.redirectUrl),
  };

  render() {
    const { signUp } = this.props;
    const { redirectHref } = this.state;
    return (
      <Link href={redirectHref} target="_self">
        <BaseLoginButton
          color={MAESTRO_WHITE}
          dataTestId="authModalMicrosoftLoginButton"
          providerNameKey="MICROSOFT"
          renderIcon={() => <ImgIcon src={`${STATIC_BASE_URL}/media/578d2dea2659bf83031fff0b/58d023d7386278d2608d27ab.png`} />}
          signUp={signUp}
        />
      </Link>
    );
  }
}
