import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { replace } from 'services/app-router';
import { MobileNavTypes, setDisplayMobileNavMenu } from 'services/app/actions';
import { getActiveMobileMenu, getPageNavigationEnabled, isCurrentPageHome, isLandingPageType } from 'services/app/selectors';
import { isLoggedIn } from 'services/auth';
import { getNavigationAppliedInThePage } from 'services/navigationv2';
import { hidePrivatePagesFromNavigation } from 'services/navigationv2/utils';
import { getIsShopifyConnected } from 'services/shopify/selectors';
import { getActivePanelsV2 } from 'services/user-layout/selectors';

interface ILayoutParams {
  active: boolean;
  onTap: () => void;
}

export interface IMobileLayout {
  cartMenu?: ILayoutParams;
  home: ILayoutParams;
  profileMenu: ILayoutParams;
  searchMenu?: ILayoutParams;
}

const useMobileLayout = () => {
  const dispatch = useDispatch();
  const activeMobileMenu = useSelector(getActiveMobileMenu);
  const isHome = useSelector(isCurrentPageHome);
  const isUserLoggedIn = useSelector(isLoggedIn);
  const isShopifyConnected = useSelector(getIsShopifyConnected);
  const isNavigationEnabledOnPage = useSelector(getPageNavigationEnabled);
  const appliedNavigation = useSelector(getNavigationAppliedInThePage);
  const currentNavigation = useMemo(() => hidePrivatePagesFromNavigation(appliedNavigation), [appliedNavigation]);
  const doesNavigationHaveParents = currentNavigation?.parents?.length > 0 || currentNavigation?.externalLinks.length > 0;
  const isLanding = useSelector(isLandingPageType);
  const activeChannelPanels = useSelector(getActivePanelsV2);
  const channelHasNoPannels = !isLanding && !activeChannelPanels.length;

  const setMobileMenu = (menu: MobileNavTypes) => {
    dispatch(setDisplayMobileNavMenu(menu));
  };

  const resetMobileMenu = () => {
    dispatch(setDisplayMobileNavMenu(null));
  };

  const onHomeClick = useCallback(() => {
    resetMobileMenu();
    if (!isHome) {
      dispatch(replace({ path: '/' }));
    }
  }, [isHome, dispatch]);

  const onProfileClick = useCallback(() => {
    if (isUserLoggedIn) {
      setMobileMenu('profileMenu');
    } else {
      setMobileMenu('loginMenu');
    }
  }, [isUserLoggedIn]);

  const mobileLayout: IMobileLayout = useMemo(() => {
    const tabElements: IMobileLayout = {
      home: {
        active: !activeMobileMenu && isHome,
        onTap: onHomeClick,
      },
      searchMenu: {
        active: activeMobileMenu === 'searchMenu',
        onTap: () =>
          activeMobileMenu === 'searchMenu'
            ? resetMobileMenu()
            : setMobileMenu('searchMenu'),
      },
      cartMenu: {
        active: false,
        onTap: resetMobileMenu,
      },
      profileMenu: {
        active:
          activeMobileMenu === 'profileMenu' ||
          activeMobileMenu === 'loginMenu',
        onTap: onProfileClick,
      },
    };

    /**
     * If Shopify is connected,
     * We will rearrange the order of the mobile menu for UX purposes
     * With Shopify: Home, Search, Cart, Profile
     * Without Shopify: Home, Search, Profile
     */
    if (!isShopifyConnected || channelHasNoPannels) {
      delete tabElements?.cartMenu;
    }

    /**
     * If navigation is not available, we will remove the search menu
     */
    if (!isNavigationEnabledOnPage || !doesNavigationHaveParents) {
      delete tabElements?.searchMenu;
    }

    return tabElements;
  }, [
    activeMobileMenu,
    isHome,
    isShopifyConnected,
    onHomeClick,
    onProfileClick,
    channelHasNoPannels,
  ]);

  return { activeMobileMenu, setMobileMenu, resetMobileMenu, mobileLayout };
};

export default useMobileLayout;
