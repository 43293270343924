import Button from 'components/ui/Button';
import { ACCENT_PRIMARY, ALERT_CONFIRMATION, SURFACE_1, SURFACE_2, SURFACE_5, TEXT_100, TEXT_200, TEXT_300 } from 'style/constants';
import { TEXT_LABEL_S_MEDIUM } from 'style/design-system/textStyles';
import styled from 'styled-components';
import { css } from 'styled-components';
import { ClaimedIcon, CompletedIcon, FailedIcon } from '../QuestStatusBadge/styles';

export const ActionButton = styled(Button)`
  background-color: ${SURFACE_2};
  height: 24px;
  color: ${ACCENT_PRIMARY} !important;
  border-radius: 16px;

  &:hover {
    background-color: ${SURFACE_1} !important;
    color: ${ACCENT_PRIMARY} !important;
  }
`;

export const ActionCompletionValue = styled.span<{ claimed: boolean; completed: boolean; }>`
  ${TEXT_LABEL_S_MEDIUM}
  color: ${TEXT_300};

  ${({ claimed }) => claimed && css`
    color: ${ALERT_CONFIRMATION};

    & span {
      color: ${ALERT_CONFIRMATION};
    }
  `};

  ${({ completed }) => completed && css`
    color: ${ACCENT_PRIMARY};

    & span {
      color: ${ACCENT_PRIMARY};
    }
  `};
`;

export const ActionCompletedValue = styled.span`
  color: ${TEXT_100};
`;


export const Action = styled.li`
  display: flex;
  gap: 10px;
  position: relative;
`;

export const ActionInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

export const ActionTitle = styled.span`
  ${TEXT_LABEL_S_MEDIUM}
  color: ${TEXT_200};
`;

export const ActionActions = styled.div`
  display: flex;
  align-items: center;
  & > *:not(:first-child) {
    margin-left: 8px;
  }
`;

export const QUEST_ACTION_ICON_CSS = css`
  width: 30px;
  height: 30px;
  padding: 6px;
`;

export const QuestActionOpenWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${QUEST_ACTION_ICON_CSS}
`;

export const QuestActionOpenIcon = styled.div`
  width: 18px;
  height: 18px;
  background-color: ${SURFACE_2};
  border: 0.75px solid ${SURFACE_5};
  border-radius: 50%;
`;

export const QuestActionCompletedIcon = styled(CompletedIcon)`
  ${QUEST_ACTION_ICON_CSS}

  & > svg {
    width: 18px;
    height: 18px;
  }
`;

export const QuestActionFailedIcon = styled(FailedIcon)`
  width: 18px;
  height: 18px;
`;

export const QuestActionClaimedIcon = styled(ClaimedIcon)`
  ${QUEST_ACTION_ICON_CSS}
  color: ${ALERT_CONFIRMATION};

  & > svg {
    width: 18px;
    height: 18px;
  }
`;
