import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useApis } from 'contexts/ApisContext';
import { flattenCalls } from 'utils';
import { lazy } from 'utils/lazy';

// reset styles for embedded video frame
// please don't concatenate user input in here
const baseHtml = lazy(
  () => `<!-- maestro embedded --><style>*{margin:0;padding:0;border:none;outline:none}</style>`,
);

const dummyEmbedCodeFromUrl = (url: string) =>
  `<iframe style="width:100%;height:100%" src="${url}"></iframe>`;

export const useEmbedCode = (url?: string) => {
  const embedCodeRef = useRef<HTMLTextAreaElement>(null);

  const { videoUpload } = useApis();

  const embedVideo = useMemo(
    () => flattenCalls(
      videoUpload.embedVideo,
    ),
    [videoUpload],
  );
  const fetchEmbedCode = useMemo(
    () => flattenCalls(
      videoUpload.fetchEmbedCode,
    ),
    [videoUpload],
  );

  const generateUrl = useCallback(
    () => {
      const textArea = embedCodeRef.current;
      if (!textArea)
        return;

      return embedVideo(baseHtml() + textArea.value.trim());
    },
    [],
  );

  useEffect(
    () => {
      if (!url)
        return;

      (async () => {
        const updateTextArea = (value: string) => {
          if (embedCodeRef.current && !embedCodeRef.current.value.trim()) {
            embedCodeRef.current.value = value;
          }
        };

        try {
          const htmlData = await fetchEmbedCode(url);

          if (htmlData.indexOf(baseHtml()) !== 0)
            throw new Error('Provided url is not from Maestro embed.');

          updateTextArea(htmlData.substring(baseHtml().length));
        } catch {
          updateTextArea(
            dummyEmbedCodeFromUrl(url),
          );
        }
      })();
    },
    [url],
  );

  return {
    embedCodeRef,
    generateUrl,
  };
};
