import styled from 'styled-components';
import { MOBILE_TAB_BAR_HEIGHT } from '../MobileTabBar/styles';

export const Wrapper = styled.div`
  height: 100vh;
`;

export const MobileNavContent = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  margin-bottom: ${MOBILE_TAB_BAR_HEIGHT}px;
`;
