import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { loginModal } from 'services/modals';
import TranslatedText from 'components/i18n/TranslatedText';
import { BACKGROUND_ACTIVE, BACKGROUND_CONTENT, PADDING_SMALL, mobileOnly, TEXT_LABEL1 } from 'style/mixins';

const AdminGateContainer = styled.div`
  ${BACKGROUND_CONTENT};
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px 50px;
  text-align: center;
  flex: 0 1 auto;
`;

const Cta = styled.div`
  ${TEXT_LABEL1}
  ${PADDING_SMALL};
`;

const LoginButton = styled.button`
  ${BACKGROUND_ACTIVE}
  border-radius: 4px;
  line-height: 36px;
  margin: 40px;
  padding: 5px 50px;
  text-transform: uppercase;
  ${mobileOnly`
    margin: 10px;
  `}
`;

class AdminGate extends React.Component {
  static propTypes = {
    onLogInClick: PropTypes.func.isRequired,
  };

  render() {
    const { onLogInClick } = this.props;
    return (
      <AdminGateContainer>
        <Cta>
          <TranslatedText stringKey="MUST_BE_AN_ADMIN_TO_ACCESS_THIS_AREA" />
        </Cta>
        <LoginButton onClick={onLogInClick}>
          <TranslatedText stringKey="ACTION_LOG_IN" />
        </LoginButton>
      </AdminGateContainer>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  onLogInClick: () => dispatch(loginModal()),
});

export default connect(null, mapDispatchToProps)(AdminGate);
