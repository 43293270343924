import styled, { css } from 'styled-components';
import { BACKGROUND_CONTENT, PADDING_SMALL } from 'style/mixins';

export interface ModalWindowStyleProps {
  anchorDimensions: DOMRect;
  position?: keyof typeof PositionMap;
  separation: number;
}

const PositionMap = {
  'bottom-left': css<ModalWindowStyleProps>`
    ${({ separation }) => `
      transform: translate(calc(-100% - ${separation}px), 0);
    `}
  `,
  'bottom-right': css<ModalWindowStyleProps>`
    ${({ anchorDimensions: { width }, separation }) => `
      transform: translate(${width + separation}px, 0);
    `}
  `,
  'top-left': css<ModalWindowStyleProps>`
    ${({ anchorDimensions: { height }, separation = 5 }) => `
      transform: translate(calc(-100% - ${separation}px), calc(${height}px - 100%));
    `}
  `,
  'top-right': css<ModalWindowStyleProps>`
    ${({ anchorDimensions: { height, width }, separation }) => `
      transform: translate(${width + separation}px, calc(${height}px - 100%));
    `}
  `,
};

export const ModalWindowContainer = styled.div<ModalWindowStyleProps>`
  max-width: 100vw;
  ${BACKGROUND_CONTENT}
  ${PADDING_SMALL}
  ${({ anchorDimensions: { top, left }, position }) => position && css`
    position: absolute !important;
    top: ${top}px;
    left: ${left}px;
    ${PositionMap[position]}
  `}
`;
