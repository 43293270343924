import { asElementTypes, UnionToArray } from 'shared/type-utils';
import { ICatalogItemProps } from './CatalogItem';

export const CHAT_ID = 'chat';
export const IFRAME_ID = 'iframe';
export const IMAGE_V2_ID = 'image_v2';
export const PEOPLE_ID = 'peopleGroups';
export const SCHEDULE_ID = 'schedule';
export const SOCIAL_ID = 'social';
export const CHANNEL_SELECT_ID = 'channel_select';
export const PLAYLIST_ID = 'playlist';
export const TWITCH_CHAT_ID = 'twitch_chat';
export const MULTIPLE_CHOICE_ID = 'multiple_choice';
export const LEADERBOARD_ID = 'achievement_leaderboard';
export const QUEST_ID = 'quests';
export const PLAY_BY_PLAY_ID = 'playByPlay';
export const FORTNITE_DUOS_MATCH_ID = 'fortniteDuosMatch';
export const FORTNITE_SINGLES_MATCH_ID = 'fortniteSinglesMatch';
export const FORTNITE_DUOS_LEADERBOARD_ID = 'fortniteDuosLeaderboard';
export const FORTNITE_PLAY_BY_PLAY_ID = 'fortnitePlayByPlay';
export const PUBG_MATCH_ID = 'pubgMatch';
export const SHOPIFY_ID = 'shopify';
export const SHOPIFY_BLOCK_PANEL_ID = 'shopifyBlockPanel';
export const FORTNITE_LEADERBOARD_BLOCK_PANEL_ID = 'fortniteLeaderboardBlockPanel';
export const QUESTS_BLOCK_PANEL_ID = 'questsBlockPanel';
export const TIPS_ID = 'tips';
export const WELCOME_PANEL_ID = 'selfsignup_welcome';

export type ICatalogItemPropsWithoutPosition = Omit<ICatalogItemProps, 'position'>;

export const PANEL_V2_CATALOG: { [kind: string]: ICatalogItemPropsWithoutPosition } = {
  [CHAT_ID]: { kindId: CHAT_ID, iconKey: 'chatV2', titleKey: 'ADMIN_PANEL_CHAT_NAME', descriptionKey: 'ADMIN_PANEL_V2_CHAT_DESCRIPTION' },
  [TIPS_ID]: { kindId: TIPS_ID, iconKey: 'tipsV2', titleKey: 'ADMIN_PANEL_TIPS_NAME', descriptionKey: 'ADMIN_PANEL_TIPS_INFO' },
  // [SOCIAL_ID]: { kindId: SOCIAL_ID, iconKey: 'twitterV2', titleKey: 'ADMIN_PANEL_SOCIAL_NAME', descriptionKey: 'ADMIN_PANEL_SOCIAL_INFO' }, HIDDEN IN THE NS-7546
  [PEOPLE_ID]: { kindId: PEOPLE_ID, iconKey: 'people', titleKey: 'ADMIN_PANEL_PEOPLE_NAME', descriptionKey: 'ADMIN_PANEL_PEOPLE_INFO' },
  [QUEST_ID]: { kindId: QUEST_ID, iconKey: 'questV3', titleKey: 'ADMIN_PANEL_QUEST_NAME', descriptionKey: 'ADMIN_PANEL_QUEST_INFO' },
  [QUESTS_BLOCK_PANEL_ID]: { kindId: QUESTS_BLOCK_PANEL_ID, iconKey: 'award', titleKey: 'ADMIN_PANEL_QUEST_BLOCK', descriptionKey: 'ADMIN_PANEL_QUEST_INFO' },
  [IMAGE_V2_ID]: { kindId: IMAGE_V2_ID, iconKey: 'imageV3', titleKey: 'ADMIN_PANEL_IMAGE_NAME', descriptionKey: 'ADMIN_PANEL_IMAGE_INFO' },
  [SCHEDULE_ID]: { kindId: SCHEDULE_ID, iconKey: 'scheduleV3', titleKey: 'ADMIN_PANEL_SCHEDULE_NAME', descriptionKey: 'ADMIN_PANEL_SCHEDULE_INFO' },
  [CHANNEL_SELECT_ID]: { kindId: CHANNEL_SELECT_ID, iconKey: 'channelSelectV3', titleKey: 'ADMIN_PANEL_CHANNEL_SELECT_NAME', descriptionKey: 'ADMIN_PANEL_CHANNEL_SELECT_INFO' },
  [PLAYLIST_ID]: { kindId: PLAYLIST_ID, iconKey: 'playlistV3', titleKey: 'ADMIN_PANEL_PLAYLIST_NAME', descriptionKey: 'ADMIN_PANEL_PLAYLIST_INFO' },
  [TWITCH_CHAT_ID]: { kindId: TWITCH_CHAT_ID, iconKey: 'twitchV3', titleKey: 'ADMIN_PANEL_TWITCH_CHAT_NAME', descriptionKey: 'ADMIN_PANEL_TWITCH_CHAT_INFO' },
  [MULTIPLE_CHOICE_ID]: { kindId: MULTIPLE_CHOICE_ID, iconKey: 'multipleChoiceV2', titleKey: 'ADMIN_LABEL_MULTIPLE_CHOICE', descriptionKey: 'ADMIN_PANEL_MULTIPLE_CHOICE_INFO' },
  [IFRAME_ID]: { kindId: IFRAME_ID, iconKey: 'iframeV3', titleKey: 'ADMIN_PANEL_IFRAME_NAME', descriptionKey: 'ADMIN_PANEL_IFRAME_INFO' },
  [LEADERBOARD_ID]: { kindId: LEADERBOARD_ID, iconKey: 'leaderboardV3', titleKey: 'ADMIN_LABEL_LEADERBOARD', descriptionKey: 'ADMIN_DESC_LEADERBOARD' },
  // GATED_PANELS
  [SHOPIFY_BLOCK_PANEL_ID]: { kindId: SHOPIFY_BLOCK_PANEL_ID, iconKey: 'shopifyV2', titleKey: 'ADMIN_SHOPIFY_V2', descriptionKey: 'ADMIN_PANEL_SHOPIFY_INFO' },
  [FORTNITE_LEADERBOARD_BLOCK_PANEL_ID]: { kindId: FORTNITE_LEADERBOARD_BLOCK_PANEL_ID, iconKey: 'leaderboardV3', titleKey: 'ADMIN_LABEL_LEADERBOARD_V2', descriptionKey: 'ADMIN_DESC_LEADERBOARD' },
  [FORTNITE_DUOS_LEADERBOARD_ID]: { kindId: FORTNITE_DUOS_LEADERBOARD_ID, iconKey: 'bar-chart', titleKey: 'ADMIN_LABEL_FORTNITE_DUOS_LEADERBOARD', descriptionKey: 'FALLBACK' },
  [FORTNITE_DUOS_MATCH_ID]: { kindId: FORTNITE_DUOS_MATCH_ID, iconKey: 'gamer', titleKey: 'ADMIN_LABEL_FORTNITE_DUOS_MATCH', descriptionKey: 'FALLBACK' },
  [FORTNITE_PLAY_BY_PLAY_ID]: { kindId: FORTNITE_PLAY_BY_PLAY_ID, iconKey: 'gamer', titleKey: 'ADMIN_LABEL_FORTNITE_PLAY_BY_PLAY', descriptionKey: 'ADMIN_DESC_FORTNITE_PLAY_BY_PLAY' },
  [FORTNITE_SINGLES_MATCH_ID]: { kindId: FORTNITE_SINGLES_MATCH_ID, iconKey: 'gamer', titleKey: 'ADMIN_LABEL_FORTNITE_SINGLES_MATCH', descriptionKey: 'FALLBACK' },
  [PLAY_BY_PLAY_ID]: { kindId: PLAY_BY_PLAY_ID, iconKey: 'gamer', titleKey: 'ADMIN_LABEL_FORTNITE_PLAY_BY_PLAY', descriptionKey: 'ADMIN_DESC_FORTNITE_PLAY_BY_PLAY' },
  [PUBG_MATCH_ID]: { kindId: PUBG_MATCH_ID, iconKey: 'gamer', titleKey: 'ADMIN_LABEL_PUBG_MATCH', descriptionKey: 'FALLBACK' },
};

export const CARD_V2_CATALOG = asElementTypes<ICatalogItemProps>()({
  image: { kindId: 'image', position: 1, iconKey: 'imageV3', titleKey: 'ADMIN_CARD_IMAGE_NAME', descriptionKey: 'ADMIN_CARD_IMAGE_INFO' },
  message: { kindId: 'message', position: 2, iconKey: 'chatV2', titleKey: 'ADMIN_CARD_MESSAGE_NAME', descriptionKey: 'ADMIN_CARD_MESSAGE_INFO' },
  // tweet: { kindId: 'tweet', position: 3, iconKey: 'twitterV2', titleKey: 'ADMIN_CARD_TWEET_NAME', descriptionKey: 'ADMIN_CARD_MESSAGE_INFO' }, HIDDEN IN THE NS-7546
  poll: { kindId: 'poll', position: 4, iconKey: 'multipleChoiceV2', titleKey: 'ADMIN_LABEL_MULTIPLE_CHOICE', descriptionKey: 'ADMIN_CARD_POLL_INFO' },
  person: { kindId: 'person', position: 5, iconKey: 'people', titleKey: 'ADMIN_CARD_PERSON_NAME', descriptionKey: 'ADMIN_PANEL_PEOPLE_INFO' },
});

export const CARD_TYPES = Object.keys(CARD_V2_CATALOG) as UnionToArray<keyof typeof CARD_V2_CATALOG>;

export type CardType = typeof CARD_TYPES[number];
