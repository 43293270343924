import React from 'react';
import { useAdminTranslation } from 'hooks/use-translation';
import FormattedTimestamp from './view';

export default (props) => {
  const { currentLocale } = useAdminTranslation();
  return (
    <FormattedTimestamp
      {...props}
      locale={currentLocale}
    />
  );
};
