import React, { useCallback } from 'react';
import { TabWrapper } from './styles';
import { MOBILE_TAB_ID } from 'global-ids';
import useMobileLayout, {
  IMobileLayout,
} from '../MobileLayout/use-mobile-layout';
import TabButton from './TabButton';

const MobileTabBar: React.FC = () => {
  const { mobileLayout } = useMobileLayout();

  const renderTabElements = useCallback(() => {
    return Object.keys(mobileLayout).map((key, index) => {
      const element = mobileLayout[key as keyof IMobileLayout];
      return (
        <TabButton
          key={`tab-button-${index}`}
          isActive={element?.active!}
          mobileMenu={key as keyof IMobileLayout}
          onTap={element?.onTap!}
        />
      );
    });
  }, [mobileLayout]);

  return <TabWrapper id={MOBILE_TAB_ID}>{renderTabElements()}</TabWrapper>;
};

export default MobileTabBar;
