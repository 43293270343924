/* eslint react/destructuring-assignment: off */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Link from 'components/ui/Link';
import bowltvLogo from 'assets/bowltv-logo.png';
import qs from 'qs';
import { SERVICE_BASE_URL } from 'config';
import BaseLoginButton from '../BaseLoginButton';

export const ImgIcon = styled.img`
  height: 22px;
  width: 22px;
`;

/* eslint-disable camelcase */
const createBowltvUri = (redirectUrl) => {
  const redirect_uri = `${SERVICE_BASE_URL}/auth/v1/oauth/bowltv`;
  const params = {
    client_id: 'BOWLTV_CLIENT_B23D9481-6093-4109-A45D-801F314B3BFF',
    redirect_uri,
    response_type: 'code',
    scope: 'openid profile',
    state: redirectUrl, // using this to redirect us back (requireRedirect in node-services)
  };
  return `https://signon.bowl.com/connect/authorize?${qs.stringify(params)}`;
};

export default class BowltvLoginButton extends React.Component {
  static propTypes = {
    redirectUrl: PropTypes.string.isRequired,
    signUp: PropTypes.bool.isRequired,
  };

  shouldComponentUpdate(nextProps) {
    return nextProps.redirectUrl !== this.props.redirectUrl;
  }

  render() {
    const { signUp } = this.props;

    return (
      <Link href={createBowltvUri(this.props.redirectUrl)} plain target="_self">
        <BaseLoginButton
          color="white"
          dataTestId="authModalBowlTVLoginButton"
          providerNameKey="USBC"
          renderIcon={
            () => <ImgIcon src={bowltvLogo} />
          }
          signUp={signUp}
        />
      </Link>
    );
  }
}
