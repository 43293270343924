import { connect } from 'react-redux';

import withErrorGuard from 'components/core/withErrorGuard';
import { dismissModal } from 'services/modals/actions';
import { getStateModals } from 'services/modals/selectors';
import ModalContainer from './view';

const mapStateToProps = (state) => ({
  modals: getStateModals(state),
});

const mapDispatchToProps = dispatch => ({
  onDismissModal: kind => dispatch(dismissModal(kind || null)),
});

export default withErrorGuard()(
  connect(mapStateToProps, mapDispatchToProps)(ModalContainer),
);
