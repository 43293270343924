import React from 'react';
import KeyedListMapper from 'components/core/KeyedListMapper';
import { ImageTitleContainer, LinkBox, LinksWrapper, Name, PersonPanelContainer, Bio, IPerson } from 'components/panels/PersonPanelFromRenderer';
import Icon from 'components/ui/Icon';
import { parseInnerHTML } from 'utils';

interface IRenderer {
  people: IPerson[];
}

interface PeopleGroupPreviewPanelProps {
  renderer: IRenderer;
}

/* tslint:disable:no-empty */
const NO_OP = () => {};

const PeopleGroupPreviewPanel: React.FC<PeopleGroupPreviewPanelProps> = ({ renderer }) => {
  const {
    personImageUrl,
    personName,
    personLinks,
    personBio,
  } = renderer.people[0];


  return (
    <PersonPanelContainer>
    <ImageTitleContainer
      data-testid="personPanelCtaImage"
      src={personImageUrl}
    >
      {Boolean(personName) && (
        <Name data-testid="personNameTitle">
          <span>{personName}</span>
        </Name>
      )}
    </ImageTitleContainer>
    {personLinks?.length > 0 && (
      <LinksWrapper>
        <KeyedListMapper keyField="linkUrl" list={personLinks}>
          {(key, { linkIconName, linkUrl }) => linkIconName ? (
            <LinkBox
              key={key}
              href={linkUrl}
              onClick={NO_OP}
            >
              <Icon name={linkIconName} />
            </LinkBox>
          ) : null}
        </KeyedListMapper>
      </LinksWrapper>
    )}
    {Boolean(personBio) && (
      <Bio data-testid="personNameDescription">
        {parseInnerHTML(personBio)}
      </Bio>
    )}
  </PersonPanelContainer>
  );
};

export default PeopleGroupPreviewPanel;
