import { TTranslator } from 'hooks/use-translation';
import { IHardcodedPlan, PlanTerm } from './interfaces';

export const FREE_PLAN_ID = '645541ecba9a9808a403c3ae';

export const GROWTH_MONTHLY_PLAN_ID = '645541ecba9a9808a403c3af';
export const GROWTH_ANNUAL_PLAN_ID = '645541ecba9a9808a403c3b0';

export const SCALE_MONTHLY_PLAN_ID = '645541edba9a9808a403c3b1';
export const SCALE_ANNUAL_PLAN_ID = '645541edba9a9808a403c3b2';

type PlanTermType = 'monthly' | 'annually';

export const FREE_PLAN = {
  planId: FREE_PLAN_ID,
  name: 'Start',
  description:
    'Best for creators who are ready to start making money with no risk or investment',
  price: 'Free',
  maxAdmins: '1 Admin',
  shopifyKeepAmount: 'Keep 95%',
  ticketAndSubscriptionKeepAmount: 'Keep 60%',
  dueAmount: 0,
  paymentDescription: 'No payment required',
  tipsKeepAmount: 'Keep 85%',
} as IHardcodedPlan;

export const CUSTOM_PLAN = {
  planId: 'custom',
  name: 'Custom',
  description:
    'Best for businesses with large audiences who need greater customizability and support',
  price: "Let's talk",
  maxAdmins: 'Unlimited Admins',
  shopifyKeepAmount: 'Keep 95%',
  ticketAndSubscriptionKeepAmount: 'Keep 100%*',
  tipsKeepAmount: 'Keep 90%',
  dueAmount: 0,
  paymentDescription: '',
} as IHardcodedPlan;

export const getGrowthPlan = (term: PlanTermType) =>
  ({
    planId:
      term === PlanTerm.ANNUALLY
        ? GROWTH_ANNUAL_PLAN_ID
        : GROWTH_MONTHLY_PLAN_ID,
    name: 'Growth',
    description:
      'Best for creators who have at least 10 followers willing to pay $5/month',
    price: term === PlanTerm.ANNUALLY ? '$19/month' : '$23/month',
    maxAdmins: '2 Admins',
    shopifyKeepAmount: 'Keep 95%',
    ticketAndSubscriptionKeepAmount: 'Keep 75%',
    dueAmount: term === PlanTerm.ANNUALLY ? 228 : 23,
    tipsKeepAmount: 'Keep 85%',
    paymentDescription:
      term === PlanTerm.ANNUALLY
        ? 'Growth Plan (Billed Annually)'
        : 'Growth Plan (Billed Monthly)',
  } as IHardcodedPlan);

export const getScalePlan = (term: PlanTermType) =>
  ({
    planId:
      term === PlanTerm.ANNUALLY ? SCALE_ANNUAL_PLAN_ID : SCALE_MONTHLY_PLAN_ID,
    name: 'Scale',
    description:
      'Best for creators who have at least 100 followers willing to pay $5/month',
    price: term === PlanTerm.ANNUALLY ? '$99/month' : '$119/month',
    maxAdmins: '4 Admins',
    shopifyKeepAmount: 'Keep 95%',
    ticketAndSubscriptionKeepAmount: 'Keep 100%',
    tipsKeepAmount: 'Keep 90%',
    recommended: true,
    dueAmount: term === PlanTerm.ANNUALLY ? 1188 : 119,
    paymentDescription:
      term === PlanTerm.ANNUALLY
        ? 'Scale Plan (Billed Annually)'
        : 'Scale Plan (Billed Monthly)',
  } as IHardcodedPlan);

export const HARDCODED_PLANS: {
  [PlanTerm.ANNUALLY]: IHardcodedPlan[];
  [PlanTerm.MONTHLY]: IHardcodedPlan[];
} = {
  [PlanTerm.ANNUALLY]: [
    FREE_PLAN,
    getGrowthPlan(PlanTerm.ANNUALLY),
    getScalePlan(PlanTerm.ANNUALLY),
    CUSTOM_PLAN,
  ],
  [PlanTerm.MONTHLY]: [
    FREE_PLAN,
    getGrowthPlan(PlanTerm.MONTHLY),
    getScalePlan(PlanTerm.MONTHLY),
    CUSTOM_PLAN,
  ],
};

export const getKeepSummary = (
  plan: IHardcodedPlan,
  t: TTranslator,
) => {
  return [
    {
      label: t('ADMIN_UPGRADE_PLAN_PLAN_CARD_TICKETS_AND_SUBS'),
      keep: plan.ticketAndSubscriptionKeepAmount,
    },
    {
      label: t('ADMIN_UPGRADE_PLAN_PLAN_CARD_SHOPIFY'),
      keep: plan.shopifyKeepAmount,
    },
    {
      label: t('ADMIN_UPGRADE_PLAN_PLAN_CARD_TIPS'),
      keep: plan.tipsKeepAmount,
    },
  ];
};
