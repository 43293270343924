import { connect } from 'react-redux';
import { getCalculatedVideoWidth } from 'services/device/selectors/common';
import { getShowMobileChannelSelector } from 'services/app/selectors';
import {
  isGateActive,
  getChannelGateLoginMessage,
  isChannelGateEnabled,
  getChannelGateKind,
  getSubscriptionContentHeight,
} from 'services/gate/selectors';
import { isDesktopLayout } from 'services/device/selectors';
import { getActiveSiteFeatures } from 'services/app/selectors/common';
import { getRegionRenderingStripeCreditCard } from 'services/billing';
import Gate from './view';

const mapStateToProps = state => ({
  channelGateActive: isChannelGateEnabled(state),
  channelGateKind: getChannelGateKind(state),
  channelGateLoginMessage: getChannelGateLoginMessage(state),
  featureGates: getActiveSiteFeatures(state),
  isDesktopLayout: isDesktopLayout(state),
  isGateActive: isGateActive(state),
  regionRenderingStripeCreditCard: getRegionRenderingStripeCreditCard(state),
  showMobileChannelSelector: getShowMobileChannelSelector(state),
  subscriptionContentHeight: getSubscriptionContentHeight(state),
  videoWidth: getCalculatedVideoWidth(state),
});

export default connect(mapStateToProps)(Gate);
