import { IExternalLink, INavigationParent } from 'models/INavigation';

/**
 * Adapter to convert external links to navigation parent so this can be used in the mobile navigation
 * @param externalLinks
 * @returns INavigationParent[]
 */
export const externalLinkToNavigationParentAdapter = (
  externalLinks: IExternalLink[],
): INavigationParent[] => {
  if (!externalLinks.length) return [];
  return externalLinks.map((link) => ({
    id: link.id,
    name: link.name,
    slug: link.url,
    children: [],
    type: 'channel',
  }));
};
