import React from 'react';
import ContentLayout from 'components/regions/ContentLayout';
import LandingPageGrid from 'components/regions/LandingPageGrid';
import { useDispatch, useSelector } from 'react-redux';
import { isDesktopLayout, isLandscape } from 'services/device';
import { setOrientationLocked } from 'services/user-layout/actions';
import { getIsOrientationLocked } from 'services/user-layout/selectors/common';

const LandingPage = () => {
  const isOrientationLocked = useSelector(getIsOrientationLocked);
  const isDesktop = useSelector(isDesktopLayout);
  const isLandscapeLayout = useSelector(isLandscape);
  const dispatch = useDispatch();

  // mobile portrait view is rendered in MobileBody
  const showLandingPageGrid = isDesktop || isLandscapeLayout;

  if (!isOrientationLocked) {
    dispatch(setOrientationLocked(true));
  }

  return (
    <ContentLayout>
      {showLandingPageGrid && <LandingPageGrid />}
    </ContentLayout>
  );
};

export default LandingPage;
