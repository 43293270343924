import styled from 'styled-components';

import {
  ACCENT_PRIMARY,
  SURFACE_3,
  TEXT_100,
  TEXT_200,
} from 'style/constants';

import { mobilePortraitOnly } from 'style/mixins';

export const Container = styled.div`
  min-height: 150px;
  width: 397.25px;
  ${mobilePortraitOnly`
    width: 100%;
  `}
  margin-bottom: 20px;
`;

export const Image = styled.div<{ src: string }>`
  aspect-ratio: 16 / 9;
  border-radius: 10px 10px 0px 0px;

  background-image: url(${props => props.src});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;

export const Content = styled.div`
  background: ${(props) => SURFACE_3(props)};
  padding: 20px;
  border-radius: 0px 0px 10px 10px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
`;

export const NameAndDescription = styled.div``;

export const Name = styled.strong`
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 15px;
  color: ${TEXT_100};
`;

export const Description = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: ${TEXT_200};
  word-break: break-word;
  margin-bottom: 20px;
  margin-top: 12px;

  & > p {
    margin: 0;
  }
`;

export const Footer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const SoldOut = styled.div`
  font-weight: 700;
  font-size: 18px;
  line-height: 15px;

  color: ${ACCENT_PRIMARY};
`;
