import React from 'react';
import PropTypes from 'prop-types';

// functional non-legacy auths
import AmazonLoginButton from 'components/modals/AuthModal/AmazonLoginButton';
import BattlenetLoginButton from 'components/modals/AuthModal/BattlenetLoginButton';
import GoogleLoginButton from 'components/modals/AuthModal/GoogleLoginButton';
import UMGLoginButton from 'components/modals/AuthModal/UMGLoginButton';
import EpicLoginButton from 'components/modals/AuthModal/EpicLoginButton';
import OneFcLoginButton from 'components/modals/AuthModal/OneFcLoginButton';
import PlaystationLoginButton from 'components/modals/AuthModal/PlaystationLoginButton';

// new auths that have yet to be implemented
// import AdobeLoginButton from 'components/modals/AuthModal/AdobeLoginButton';
// import BowltvLoginButton from 'components/modals/AuthModal/BowltvLoginButton';
// import MicrosoftLoginButton from 'components/modals/AuthModal/MicrosoftLoginButton';
// import FacebookLoginButton from 'components/modals/AuthModal/FacebookLoginButton';
// import CornellLoginButton from 'components/modals/AuthModal/CornellLoginButton';

// legacy implementations
import AdobeLoginButton from 'components/modals/AuthModal/Legacy/AdobeLoginButton';
import CornellLoginButton from 'components/modals/AuthModal/Legacy/CornellLoginButton';
import BowltvLoginButton from 'components/modals/AuthModal/Legacy/BowltvLoginButton';
import FacebookLoginButton from 'components/modals/AuthModal/Legacy/FacebookLoginButton';
import MicrosoftLoginButton from 'components/modals/AuthModal/Legacy/MicrosoftLoginButton';
import TwitchLoginButton from 'components/modals/AuthModal/Legacy/TwitchLoginButton';

// non-functional/untested auths
import UplayLoginButton from 'components/modals/AuthModal/Legacy/UplayLoginButton';

export const KIND_MAP = {
  adobe: AdobeLoginButton,
  amazon: AmazonLoginButton,
  battlenet: BattlenetLoginButton,
  bowltv: BowltvLoginButton,
  cornell: CornellLoginButton,
  epic: EpicLoginButton,
  facebook: FacebookLoginButton,
  google: GoogleLoginButton,
  onefc: OneFcLoginButton,
  playstation: PlaystationLoginButton,
  twitch: TwitchLoginButton,
  umg1: UMGLoginButton,
  uplay: UplayLoginButton,
  xbox: MicrosoftLoginButton,
};

export default class ExternalLoginButton extends React.Component {
  static propTypes = {
    kind: PropTypes.string.isRequired,
    redirectUrl: PropTypes.string,
    signUp: PropTypes.bool.isRequired,
    slug: PropTypes.string,
  };

  static defaultProps = {
    redirectUrl: '',
    slug: '',
  };

  render() {
    const { kind, redirectUrl, slug, signUp } = this.props;
    if (!KIND_MAP[kind]) { return null; }
    const Component = KIND_MAP[kind];
    return (
      <Component
        redirectUrl={redirectUrl}
        signUp={signUp}
        slug={slug}
      />
    );
  }
}
