import styled, { css, keyframes } from 'styled-components';
import { BREAK_WORD, TITLE_FONT, mobileOnly } from 'style/mixins';
import { PanelProps } from '.';
import { SURFACE_2 } from 'style/constants';

export const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const FADE_IN = css`
  animation: ${fadeIn} 0.5s ease-out;
`;

export const ACTIVE_PANEL_STYLE = css`
  display: flex;
  flex-grow: 1;
  flex-flow: column nowrap;
`;

export const StyledPanel = styled.div<Omit<PanelProps, 'doc' | 'isMobileLayout'>>`
  & > *:last-child {
    scrollbar-width: thin;
  }
  scrollbar-width: thin;
  position: relative;
  width: 100%;
  max-height: 100%;
  ${props => (props.hidden ? '' : ACTIVE_PANEL_STYLE)};
  ${props => props.fade ? FADE_IN : ''};
  overflow-y: auto;
  & > *:last-child {
    scrollbar-width: thin;
    ${props => props.isEditMode ? 'overflow: visible;' : 'overflow-y: auto;'}
  }
  ${mobileOnly`
    & > *:last-child {
      overflow: auto;
    }
  `}
`;

if (process.env.NODE_ENV !== 'production') {
  StyledPanel.displayName = 'StyledPanel';
}

export const DebugJson = styled.div`
  ${BREAK_WORD}
`;

export const PanelWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PanelTitle = styled.div`
  background-color: ${SURFACE_2};
  text-align: center;
  min-height: 30px;
  ${TITLE_FONT};
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  padding: 8px;
  overflow: visible;
  text-overflow: unset;
  white-space: normal;
  word-break: break-word;
  line-height: normal;
`;

export const StopClicks = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  pointer-events: none;
  flex: 1;
  min-width: 0;
  max-width: 100%;
`;
