import styled, { css } from 'styled-components';
import DragButt from 'components/admin2/ui/DragButt';
import { ADMIN_ACCENT_PRIMARY, ADMIN_SURFACE_1, ADMIN_SURFACE_3, ADMIN_SURFACE_5, ADMIN_TEXT_300, ADMIN_TEXT_100, ADMIN_SURFACE_4, ADMIN_SURFACE_6 } from 'style/constants';
import { ADMIN_TEXT_BODY_S_BOLD, ADMIN_TEXT_LABEL_XS_MEDIUM } from 'style/design-system/textStyles';
import withTooltip from 'components/core/withTooltip';
import RoundedIcon from './RoundedIcon';

export const AvailableCatalogItemWrapper = styled.div<{renderPreview: boolean}>`
  display: grid;
  grid-template-columns: 48px 1fr 0.5fr;
  align-items: center;
  padding: 10px 16px 10px 12px;
  background: ${ADMIN_SURFACE_3};
  border-radius: 4px;
  height: 68px;
  gap: 12px;
  ${({ renderPreview }) => renderPreview && css`cursor: pointer` };
`;

export const ActiveCatalogItemWrapper = styled(AvailableCatalogItemWrapper)`
  grid-template-columns: 20px 15px 48px 1fr 0.5fr;
  padding: 0px 16px 0px 0px;
  background: ${ADMIN_SURFACE_5};
`;

export const CardInformantion = styled.div`
  display: flex;
  flex-flow: column wrap;
`;

export const CardTitle = styled.h2`
  color: ${ADMIN_TEXT_100};
  ${ADMIN_TEXT_BODY_S_BOLD}
  margin: 0px;
`;

export const CardDescription = styled.p`
  color: ${ADMIN_TEXT_300};
  ${ADMIN_TEXT_LABEL_XS_MEDIUM};
  margin: 0px;
`;

export const CardActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 4px;
`;

export const PositionIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  background: ${ADMIN_SURFACE_4};
  border-radius: 50px;
  & span {
    color: ${ADMIN_TEXT_100};
    ${ADMIN_TEXT_LABEL_XS_MEDIUM};
  }
`;

export const StyledDragButt = styled(DragButt)`
  background: ${ADMIN_SURFACE_3};
  & > div div:after, > div div:before {
    background: ${ADMIN_SURFACE_6};
  }
`;

export const CardIcon = styled(RoundedIcon)<{ isActive: boolean }>`
  background: ${({ isActive }) =>
    isActive ? `${ADMIN_SURFACE_6}` : `${ADMIN_SURFACE_4}`};
`;

const ActionIcon = styled(RoundedIcon)<{ isActive: boolean }>`
  cursor: pointer;
  background: ${({ isActive }) =>
  isActive ? ADMIN_ACCENT_PRIMARY : ADMIN_SURFACE_1};
  width: 24px;
  height: 24px;
  padding: 5px;
  // action icons have 14*14 dimensions
  &:hover {
    background: ${ADMIN_ACCENT_PRIMARY};
  }
`;

export const SettingsIcon = withTooltip(
  styled(ActionIcon).attrs({
    iconKey: 'settingsV2',
  })<{ isActive: boolean }>``,
  { admin: true },
);

export const PlusIcon = withTooltip(
  styled(ActionIcon).attrs({
    iconKey: 'plusTiny',
  })<{ isActive: boolean }>``,
  { admin: true },
);

export const DeleteIcon = withTooltip(
  styled(ActionIcon).attrs({
    iconKey: 'trashV2',
  })<{ isActive: boolean }>``,
  { admin: true },
);
