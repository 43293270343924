import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import DropDown from 'components/ui/DropDown';
import { connect } from 'react-redux';
import { getCurrentGateData } from 'services/gate/selectors';
import { getObjectId } from 'services/app/selectors';
import { setGateUserAge } from 'services/gate/actions';
import TranslatedText from 'components/i18n/TranslatedText';
import { BACKGROUND_ACTIVE, BACKGROUND_CONTENT, PADDING_SMALL, mobileOnly } from 'style/mixins';
import { persistenceService } from 'services/persistence';

// TODO i18 date selector
const MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
const DAYS = [...Array(31).keys()].map(x => x + 1);
const YEARS = [...Array(101).keys()].map(x => x + 1920).reverse();

const AgeGateContainer = styled.div`
  ${BACKGROUND_CONTENT};
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px 50px;
  text-align: center;
  & .DropDown__menu{
    margin-top: 1px;
  }
`;

const Cta = styled.div`
  font-size: 24px;
  line-height: 30px;
  ${PADDING_SMALL};
`;

const EnterDateForm = styled.form`
  align-items: center;
  display: flex;
  justify-content: center;
`;

const AgeGateSubmitButton = styled.button`
  ${BACKGROUND_ACTIVE}
  border-radius: 4px;
  line-height: 36px;
  margin: 40px;
  padding: 5px 50px;
  text-transform: uppercase;
  ${mobileOnly`
    margin: 10px;
  `}
`;

class AgeGate extends React.Component {
  static propTypes = {
    currentChannelId: PropTypes.string.isRequired,
    currentGateData: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    onSetGateUserAge: PropTypes.func.isRequired,
  };

  static persistence = persistenceService();

  state = {
    day: 0,
    isTimeoutBlocked: false,
    month: 0,
    year: 0,
  };

  componentDidMount() {
    this.isTimeoutBlocked();
  }

  isTimeoutBlocked = async () => {
    const { currentChannelId, currentGateData } = this.props;
    const { timeout } = currentGateData;
    const failTime = await this.persistence.read(`channel:${currentChannelId}:gate:age:verification:failTime`);
    const parsedFailTime = parseInt(failTime, 10) || null;
    if (!parsedFailTime) { return; }
    const threshold = Date.now() - (timeout * 1000 * 60);
    this.setState({ isTimeoutBlocked: parsedFailTime > threshold });
  };

  handleChange = eventTarget => (event) => {
    this.setState({ [eventTarget]: event.value });
  };

  handleSubmit = () => {
    const { month, day, year } = this.state;
    const {
      currentChannelId,
      currentGateData,
      onSetGateUserAge,
    } = this.props;
    const birthday = new Date(YEARS[year], month, day + 1).getTime();
    const { age } = currentGateData;
    const now = Date.now();
    const userAge = Math.trunc((now - birthday) / 1000 / 60 / 60 / 24 / 365);
    const isOldEnough = userAge >= age;
    this.persistence.write(`channel:${currentChannelId}:gate:age:verification`, userAge);
    this.persistence.write(`channel:${currentChannelId}:gate:age:verification:failTime`, isOldEnough ? null : now);
    onSetGateUserAge({ date: day + 1, month, year: YEARS[year] });
    if (!isOldEnough) {
      this.setState({ isTimeoutBlocked: true });
    }
  };

  render() {
    const { day, month, isTimeoutBlocked, year } = this.state;

    return (
      isTimeoutBlocked ? (
        <AgeGateContainer>
          <Cta>
            <TranslatedText stringKey="SORRY_NO_ACCESS_AT_THIS_TIME" />
          </Cta>
        </AgeGateContainer>
      ) : (
        <AgeGateContainer>
          <Cta>
            <TranslatedText stringKey="PLEASE_ENTER_YOUR_DATE_OF_BIRTH" />
          </Cta>
          <EnterDateForm>
            <DropDown
              name="month"
              onChange={this.handleChange('month')}
              options={MONTHS.map((_month, index) => ({ label: _month, value: index }))}
              value={{ label: MONTHS[month], value: month }}
            />
            <DropDown
              name="day"
              onChange={this.handleChange('day')}
              options={DAYS.map((_day, index) => ({ label: _day, value: index }))}
              value={{ label: DAYS[day], value: day }}
            />
            <DropDown
              name="year"
              onChange={this.handleChange('year')}
              options={YEARS.map((_year, index) => ({ label: _year, value: index }))}
              value={{ label: YEARS[year], value: year }}
            />
          </EnterDateForm>
          <AgeGateSubmitButton onClick={this.handleSubmit}>
            <TranslatedText stringKey="SUBMIT" />
          </AgeGateSubmitButton>
        </AgeGateContainer>
      ));
  }
}

const mapStateToProps = state => ({
  currentChannelId: getObjectId(state),
  currentGateData: getCurrentGateData(state),
});

const mapDispatchToProps = dispatch => ({
  onSetGateUserAge: age => dispatch(setGateUserAge(age)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AgeGate);
