import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { DynamicTranslationType, useEndUserTranslation } from 'hooks/use-translation';
import FormInput from 'components/ui/v2/Inputs';

const ProfileNameInput = ({ profileName, onProfileChange, onUpdateErrors }) => {
  const [profileNameError, setProfileNameError] = useState('');
  const { endUserT } = useEndUserTranslation();

  const onVerifyEmail = () => {
    const isEmpty = profileName === '';
    setProfileNameError(isEmpty ? endUserT([DynamicTranslationType.createAccountUsernameErrorLabel], ['EMPTY_NAME']) : '');
    onUpdateErrors('profileName', isEmpty);
  };

  return (
    <FormInput.Root error={!!profileNameError}>
      <FormInput.FieldSet>
        <FormInput.Legend>
          {endUserT([DynamicTranslationType.createAccountUsernamePlaceholder], ['USER_NAME'])}
        </FormInput.Legend>
        <FormInput.InputIcon name="singleUser" />
        <FormInput.TextInput
          data-testid="authModalRegisterViewNameInput"
          name="name"
          onBlur={onVerifyEmail}
          onChange={onProfileChange}
          placeholder={endUserT([DynamicTranslationType.createAccountUsernamePlaceholder], ['USER_NAME'])}
          type="name"
          value={profileName}
        />
      </FormInput.FieldSet>
      {profileNameError && <FormInput.SupportingText>{profileNameError}</FormInput.SupportingText>}
    </FormInput.Root>
  );
};

ProfileNameInput.propTypes = {
  onProfileChange: PropTypes.func.isRequired,
  onUpdateErrors: PropTypes.func.isRequired,
  profileName: PropTypes.string.isRequired,
};

export default ProfileNameInput;
