/* eslint react/destructuring-assignment: off */
import PropTypes from 'prop-types';
import React from 'react';
import shortid from 'shortid';
import get from 'lodash/get';
import InsightsViewable from 'components/insights/InsightsViewable';
import NonKeyedListMapper from 'components/core/NonKeyedListMapper';
import RealtimeDocument from 'components/core/RealtimeDocument';
import { withEndUserT, DynamicTranslationType } from 'hooks/use-translation';
import CountTo from 'components/ui/CountTo';
import isEqual from 'lodash/isEqual';

import FormattedTimestamp from 'components/i18n/FormattedTimestamp';
import { msIn } from 'shared/datetime-utils';
import { QUEST_ID } from 'global-ids';
import {
  REWARD,
  AVAILABLE,
  PROGRESS_BAR,
  ACTION_CONTAINER,
  NAME,
  HEADER,
  ICON,
  QUEST,
} from 'injection-classes';

import {
  Actions,
  AwardTitle,
  BadgeBackground,
  BadgeWrap,
  ClaimReward,
  ClaimedReward,
  CompletedCountBadge,
  Container,
  CountContainer,
  CountText,
  CoverSpinner,
  Header,
  HeaderContainer,
  HeaderIcon,
  HeaderText,
  ProgressBar,
  ProgressBarBackground,
  RewardDesc,
  RewardImage,
  RewardTitle,
  RewardAvailable,
  StyledQuestAction,
  SubHeader,
  TrophyIcon,
  ViewPrize,
} from './styles';

const convertDate = date => new Date(date || 0);

const SPACER = ': ';
class Quest extends React.Component {
  static propTypes = {
    claiming: PropTypes.bool.isRequired,
    claimQuest: PropTypes.func.isRequired,
    claimStatus: PropTypes.shape({
      _id: PropTypes.string,
      status: PropTypes.string,
    }),
    data: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    endUserT: PropTypes.func.isRequired,
    hidden: PropTypes.bool.isRequired,
    id: PropTypes.string.isRequired,
    onAdd: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
    progress: PropTypes.shape({
      actions: PropTypes.shape({}),
      claimed: PropTypes.bool,
      completed: PropTypes.bool,
    }).isRequired,
    questEngage: PropTypes.func.isRequired,
    refetchClaimStatus: PropTypes.func.isRequired,
    showCompletedCount: PropTypes.bool,
    showProfilePanel: PropTypes.func.isRequired,
    showReward: PropTypes.func.isRequired,
    userId: PropTypes.string,
  };

  static defaultProps = {
    claimStatus: {},
    showCompletedCount: false,
    userId: null,
  };

  static getPercentComplete({
    actions,
    questCompleted,
    tasksCompleted,
  }) {
    if (questCompleted) {
      return 100;
    }
    if (actions.length === 0) {
      return 0;
    }
    return (tasksCompleted / actions.length) * 100;
  }

  loadedUserIds = {};

  refreshTimeout = null;

  timeout = null;

  state = {
    allowClaimRefetch: false,
    available: true,
    clickedClaim: false,
    expired: false,
  };

  componentDidMount() {
    const { id, onAdd } = this.props;
    onAdd(id);
    this.updateExpiration();
  }

  componentDidUpdate({ data: oldQuestData, id: oldId, progress: oldProgress }) {
    const { timeMaximum: oldMax, timeMinimum: oldMin } = oldQuestData;
    const { data, id: newId, onAdd, onRemove, progress } = this.props;
    const { actions, timeMaximum, timeMinimum } = data;

    if (oldId !== newId) {
      onRemove(oldId);
      onAdd(newId);
    }

    if (timeMinimum !== oldMin || timeMaximum !== oldMax) {
      this.updateExpiration();
    }

    if (oldProgress && !oldProgress.claimed && progress && progress.claimed) {
      this.props.refetchClaimStatus();
    }
    if (!isEqual(oldQuestData.actions, actions)) {
      this.forceSendViewEvent();
    }
  }

  componentWillUnmount() {
    const { id, onRemove } = this.props;
    onRemove(id);
    clearTimeout(this.refreshTimeout);
    clearTimeout(this.timeout);
  }

  forceSendViewEvent() {
    // wait 5 seconds because the cache on the dataflow processor
    // for quests is 5 seconds. this only affects insta-win quests
    // that previously had an impossible action
    clearTimeout(this.refreshTimeout);
    this.refreshTimeout = setTimeout(() => {
      this.setState({
        refreshKey: shortid.generate(),
      });
    }, 5000);
  }

  updateExpiration = () => {
    const { data: { timeMaximum, timeMinimum } } = this.props;

    clearTimeout(this.timeout);

    if (timeMinimum && timeMaximum) {
      const now = Date.now();
      const start = convertDate(timeMinimum);
      const end = convertDate(timeMaximum);

      if (now <= end) {
        const duration = (now < start ? start : end) - now;
        this.timeout = setTimeout(() => {
          this.updateExpiration();
        }, Math.min(duration, msIn.day));
      }

      this.setState({ available: now >= start, expired: now >= end });
    }
  };

  claimPrize = async () => {
    const { available, expired, clickedClaim } = this.state;

    if (available && !expired && !clickedClaim) {
      this.props.claimQuest(this.props.id);
      this.setState({ clickedClaim: true });
    }
  };

  renderHeaderIcon = (completed, failed, claimed, notAvailable) => {
    if (failed) {
      return <HeaderIcon className={ICON} name="circle-x" />;
    }
    if (completed || claimed) {
      return <HeaderIcon className={ICON} name="circledCheckmarkUnfilled" />;
    }

    if (notAvailable) {
      return <HeaderIcon className={ICON} name="clock" />;
    }

    return null;
  };

  handleRefetchClaim = (doc, loaded) => {
    // Prevent refresh on subscribe
    const { refetchClaimStatus } = this.props;
    const { allowClaimRefetch } = this.state;

    if (!doc || !loaded) {
      return;
    }

    if (allowClaimRefetch) {
      refetchClaimStatus();
    } else {
      this.setState({
        allowClaimRefetch: true,
      });
    }
  };

  renderClaimButton = () => {
    const { data, endUserT, showProfilePanel } = this.props;

    switch (data.rewardType) {
      case 'webhook':
        return (
          <ClaimedReward>
            {
              endUserT(
                [DynamicTranslationType.questClaimed],
                ['CLAIMED'],
              )
            }
          </ClaimedReward>
        );
      case 'prize':
        return (
          <ViewPrize onClick={showProfilePanel}>
            {
              endUserT(
                [DynamicTranslationType.questViewPrize],
                ['VIEW_YOUR_PRIZE'],
              )
            }
          </ViewPrize>
        );
      default:
        return (
          <ClaimedReward>
            {
              endUserT(
                [DynamicTranslationType.questEntrySubmitted],
                ['ENTRY_SUBMITTED'],
              )
            }
          </ClaimedReward>
        );
    }
  };

  renderCompletedCount = (faded) => {
    const { id } = this.props;
    return (
      <RealtimeDocument collection="quests-stats" id={id}>
        {(count) => {
          return count > 0 ? (
            <>
              <CountContainer>
                <BadgeWrap>
                  <BadgeBackground>
                    <span>
                      Completed by
                      &nbsp;
                      <CountTo from={0} target={count}>
                        {renderCount => Math.round(renderCount).toLocaleString()}
                      </CountTo>
                      &nbsp;
                      viewers
                    </span>
                  </BadgeBackground>
                </BadgeWrap>
              </CountContainer>
              <CountContainer>
                <BadgeWrap>
                  <CompletedCountBadge faded={faded}>
                    <CountText>
                      Completed by
                      &nbsp;
                      <CountTo from={0} target={count}>
                        {renderCount => Math.round(renderCount).toLocaleString()}
                      </CountTo>
                      &nbsp;
                      viewers
                    </CountText>
                  </CompletedCountBadge>
                </BadgeWrap>
              </CountContainer>
            </>
          ) : null;
        }}
      </RealtimeDocument>
    );
  };

  mapProgressToActions = () => {
    const {
      data: questData,
      progress: myProgress,
    } = this.props;
    const actions = questData.actions || [];
    let tasksCompleted = 0;

    const userQuestActions = actions
      .map((item) => {
        const completed = get(myProgress, ['actions', item.arrayId, 'completed'], false);
        const progress = get(myProgress, ['actions', item.arrayId, 'progress'], 0);
        const failed = get(myProgress, ['actions', item.arrayId, 'failed'], false);

        if (completed) {
          tasksCompleted++;
        }

        return {
          data: item,
          failed,
          progress,
        };
      });
    return [userQuestActions, tasksCompleted];
  };

  getClaimRewardButtonText() {
    const { data, endUserT } = this.props;
    const { available, expired } = this.state;

    if (!available) {
      return endUserT(
        [DynamicTranslationType.questNotAvailable],
        ['NOT_AVAILABLE'],
      );
    }

    if (expired) {
      return endUserT(
        [DynamicTranslationType.questNotClaimed],
        ['DID_NOT_CLAIM'],
      );
    }

    if (data.rewardType === 'raffle') {
      return endUserT(
        [DynamicTranslationType.questSubmitEntry],
        ['ACTION_ENTER_RAFFLE'],
      );
    }

    return endUserT(
      [DynamicTranslationType.questClaimPrize],
      ['CLAIM_YOUR_PRIZE'],
    );
  }

  renderUi() {
    const {
      claiming,
      data,
      endUserT,
      id,
      questEngage,
      progress: myProgress,
      showCompletedCount,
      showReward,
      userId,
    } = this.props;
    const { available, expired } = this.state;
    const [actions, tasksCompleted] = this.mapProgressToActions();

    const claimed = Boolean(myProgress?.claimed);
    const questCompleted = Boolean(myProgress?.completed) || (actions.length === 0 && available);
    const questFailed = expired || Boolean(myProgress?.failed);
    const percent = Quest.getPercentComplete({ actions, questCompleted, tasksCompleted });
    const notAvailable = !available;
    const faded = notAvailable || expired || questFailed;

    return (
      <Container className={QUEST} faded={faded} id={`${QUEST_ID}-${id}`}>
        {claiming && <CoverSpinner fade={false} />}
        <HeaderContainer>
          {showCompletedCount && this.renderCompletedCount(faded)}
          <Header className={HEADER} showCompletedCount={showCompletedCount}>
            <HeaderText>
              {this.renderHeaderIcon(questCompleted, questFailed, claimed, notAvailable)}
              <div className={NAME}>
                <span>
                  {
                    endUserT([
                      DynamicTranslationType.questTitle,
                      { questId: data._id },
                    ]) || data.title
                  }
                </span>
              </div>
              {
                notAvailable && (
                  <RewardTitle>
                    <RewardAvailable className={AVAILABLE}>
                      {
                        endUserT(
                          [DynamicTranslationType.questAvailable],
                          ['AVAILABLE'],
                        )
                      }
                      {SPACER}
                      <FormattedTimestamp
                        show="datetime"
                        timestamp={convertDate(data.timeMinimum)}
                      />
                    </RewardAvailable>
                  </RewardTitle>
                )
              }
              {
                expired && (
                  <RewardTitle>
                    {
                      endUserT(
                        [DynamicTranslationType.questExpired],
                        ['EXPIRED'],
                      )
                    }
                    {SPACER}
                    <FormattedTimestamp
                      show="datetime"
                      timestamp={convertDate(data.timeMaximum)}
                    />
                  </RewardTitle>
                )
              }

            </HeaderText>
            {
              data.description && (
                <AwardTitle className={NAME}>
                  <span>
                    {
                      endUserT([
                        DynamicTranslationType.questDescription,
                        { questId: data._id },
                      ]) || data.description
                    }
                  </span>
                </AwardTitle>
              )
            }
          </Header>
        </HeaderContainer>
        {
          !(claimed || questCompleted) && (
            <ProgressBarBackground className={PROGRESS_BAR}>
              <ProgressBar percent={percent} />
            </ProgressBarBackground>
          )
        }
        {
          questCompleted && !claimed && (
            <ClaimReward expired={notAvailable || expired} onClick={this.claimPrize}>
              {this.getClaimRewardButtonText()}
            </ClaimReward>
          )
        }
        {claimed && this.renderClaimButton()}
        <SubHeader
          className={REWARD}
        >
          {
            data.rewardImage ? (
              <RewardImage
                background={data.rewardImage}
                onClick={() => {
                  questEngage({
                    action: 'inspect reward',
                    image: data.rewardImage,
                    quest: id,
                  });
                  showReward(data.rewardImage);
                }}
              />
            ) : <TrophyIcon name="awardColored" />
          }
          <div>
            <RewardTitle>
              {
                endUserT(
                  [DynamicTranslationType.questReward],
                  ['REWARD'],
                )
              }
            </RewardTitle>
            <RewardDesc className={NAME}>
              {
                data.rewardDescription ? (
                  <span>
                    {
                      endUserT([
                        DynamicTranslationType.questRewardDescription,
                        { questId: data._id },
                      ]) || data.rewardDescription
                    }
                  </span>
                ) : endUserT(
                  [DynamicTranslationType.questTbd],
                  ['TBD'],
                )
              }
            </RewardDesc>
          </div>
        </SubHeader>
        {
          actions?.length > 0 && (
            <Actions className={ACTION_CONTAINER}>
              <NonKeyedListMapper list={actions}>
                {
                  (key, { data: actionData, progress, failed }) => (
                    <StyledQuestAction
                      key={key}
                      available={available}
                      data={actionData}
                      failed={failed || expired}
                      progress={progress}
                      questEngage={questEngage}
                      questId={data._id}
                      userId={userId}
                    />
                  )
                }
              </NonKeyedListMapper>
            </Actions>
          )
        }
      </Container>
    );
  }

  render() {
    const { data, hidden, id } = this.props;
    const { refreshKey } = this.state;
    return (
      <>
        {this.renderUi()}
        {/* {
          userId && (
            <RealtimeDocument
              collection="quests-progress-updates"
              id={userId}
              onValue={this.handleServerUpdate}
            />
          )
        } */}
        <InsightsViewable
          doc={data}
          extra={{ class: 'panel', family: data.title, order: id }}
          kingdom="quest"
          refreshKey={refreshKey}
          visible={!hidden}
        />
      </>
    );
  }
}

export default withEndUserT(Quest);
