import React, { FormEventHandler, useCallback, useEffect, useState } from 'react';
import ExternalLoginButton from 'components/modals/AuthModal/ExternalLoginButton';
import { TITLE, SUBTITLE, BUTTON, DEEP_LINK } from 'injection-classes';
import { exponentialBackoffWaitMsg, exponentialBackoffMessageWaitMs, exponentialBackoffTotalWaitTimeMs } from 'utils';
import {
  MaestroLoginContainer,
  FormBody,
  ExternalAuthsWrapper,
  OrText,
  FormSubmit,
  RegisterLoginWaitingMessageContainer,
  RegisterLoginSpinnerContainer,
  RegisterLoginSpinner,
  ModalTitle,
} from '../commonStyles';
import {
  LockedEmailInput,
  EmailIconWrapper,
  EmailIcon,
  EmailLockedText,
  ForgotPasswordButton,
  ForgotPasswordContainer,
} from './styles';
import { DynamicTranslationType, useEndUserTranslation } from 'hooks/use-translation';
import FormInput from 'components/ui/v2/Inputs';

interface IAccountFound {
  email: string;
  isAccountFound: boolean;
  userName: string;
}

interface ILoginView {
  accountFound: IAccountFound;
  dismissError: () => void;
  enableMaestroLogin: boolean;
  id: string;
  loginFailed?: boolean;
  onForgotPassword: () => void;
  onLogin: (email: string, password: string) => void;
  validAuths: string[];
}

const LoginView: React.FC<ILoginView> = ({
  accountFound,
  dismissError,
  enableMaestroLogin,
  id,
  loginFailed: loginFailedProp = false,
  onForgotPassword,
  onLogin,
  validAuths,
}) => {
  const [loggingIn, setLoggingIn] = useState(false);
  const [loggingInResetTimer, setLoggingInResetTimer] = useState<NodeJS.Timeout | null>(null);
  const [loggingInWaitingMessage, setLoggingInWaitingMessage] = useState('');
  const [loggingInWaitingMessageTimer, setLoggingInWaitingMessageTimer] = useState<NodeJS.Timeout | null>(null);
  const [loginFailed, setLoginFailed] = useState(false);
  const [password, setPassword] = useState('');

  const { endUserT } = useEndUserTranslation();

  const clearLoginTimeoutsState = useCallback(() => {
    setLoggingIn(false);
    setLoggingInResetTimer(null);
    setLoggingInWaitingMessage('');
    setLoggingInWaitingMessageTimer(null);
  }, []);

  const clearLoginTimeouts = useCallback(() => {
    if (loggingInResetTimer) {
      clearTimeout(loggingInResetTimer);
    }
    if (loggingInWaitingMessageTimer) {
      clearTimeout(loggingInWaitingMessageTimer);
    }
  }, [loggingInResetTimer, loggingInWaitingMessageTimer]);

  const startLoginTimeouts = useCallback(() => {
    const newLoggingInResetTimer = setTimeout(() => {
      clearLoginTimeouts();
      clearLoginTimeoutsState();
    }, exponentialBackoffTotalWaitTimeMs);
    const newLoggingInWaitingMessageTimer = setTimeout(() => {
      setLoggingInWaitingMessage(exponentialBackoffWaitMsg);
    }, exponentialBackoffMessageWaitMs);
    setLoggingIn(true);
    setLoggingInResetTimer(newLoggingInResetTimer);
    setLoggingInWaitingMessageTimer(newLoggingInWaitingMessageTimer);
  }, [clearLoginTimeouts, clearLoginTimeoutsState]);

  useEffect(() => {
    if (loginFailedProp) {
      clearLoginTimeouts();
      clearLoginTimeoutsState();

      setLoginFailed(true);
      dismissError();
    }
  }, [loginFailedProp, clearLoginTimeouts, clearLoginTimeoutsState, dismissError]);

  useEffect(() => {
    return () => {
      clearLoginTimeouts();
    };
  }, []);

  const onSubmit: FormEventHandler<HTMLFormElement> = useCallback(
    (e) => {
      e.preventDefault();
      const { email } = accountFound;
      if (!email || !password) {
        setLoginFailed(true);
        return;
      }
      onLogin(email, password);
      dismissError();
      setLoginFailed(false);
      startLoginTimeouts();
    },
    [password, accountFound, startLoginTimeouts, dismissError, onLogin],
  );

  const userName = accountFound?.userName?.replace(/ .*$/gi, '');

  return (
    <FormBody id={id} onSubmit={onSubmit}>
      {enableMaestroLogin && (
        <MaestroLoginContainer>
          {accountFound?.userName && (
            <ModalTitle className={TITLE} data-testid="authModalLoginViewTitle">
              {
                endUserT(
                    [DynamicTranslationType.loginScreenWelcomeBack, { variables: { userName } }],
                    ['LOGIN_WELCOME_BACK', { userName }],
                )
              }
            </ModalTitle>
          )}
          <LockedEmailInput>
            <EmailIconWrapper>
              <EmailIcon />
            </EmailIconWrapper>
            <EmailLockedText data-testid="authModalLoginViewLockedEmailText">
              {accountFound?.email}
            </EmailLockedText>
          </LockedEmailInput>
          <FormInput.Root error={loginFailed}>
            <FormInput.FieldSet>
              <FormInput.Legend className={SUBTITLE} data-testid="authModalLoginViewDescription">
                {endUserT([DynamicTranslationType.globalFormLabelEnterPassword], ['PASSWORD'])}
              </FormInput.Legend>
              <FormInput.PasswordInput
                data-testid="authModalLoginViewPasswordInput"
                autoFocus={true}
                name="password"
                onChange={setPassword}
                placeholder={endUserT([DynamicTranslationType.globalPassword],['PASSWORD'])}
                value={password}
              />
            </FormInput.FieldSet>
            {loginFailed && (
              <FormInput.SupportingText>
                {endUserT([DynamicTranslationType.globalValidationInvalidPassword], ['LOGIN_FAILED_WRONG_PASSWORD'])}
              </FormInput.SupportingText>
            )}
          </FormInput.Root>
          <ForgotPasswordContainer>
            <ForgotPasswordButton
              className={DEEP_LINK}
              data-testid="authModalLoginViewForgotPasswordButton"
              onClick={onForgotPassword}
              type="button"
            >
              {endUserT([DynamicTranslationType.loginScreenFormLabelForgotPassword], ['FORGOT_PASSWORD'])}
            </ForgotPasswordButton>
          </ForgotPasswordContainer>
          <FormSubmit
            className={BUTTON}
            data-testid="authModalLoginViewSubmitButton"
            type={!loggingIn ? 'submit' : 'button'}
          >
            {!loggingIn ? (
              endUserT([DynamicTranslationType.globalActionLogIn], ['ACTION_LOG_IN'])
            ) : (
              <RegisterLoginSpinnerContainer>
                {loggingInWaitingMessage && (
                  <RegisterLoginWaitingMessageContainer>
                    {loggingInWaitingMessage}
                  </RegisterLoginWaitingMessageContainer>
                )}
                <RegisterLoginSpinner />
              </RegisterLoginSpinnerContainer>
            )}
          </FormSubmit>
          {validAuths.length > 1 && (
            <OrText>
              {endUserT([DynamicTranslationType.globalFormLabelOr], ['OR'])}
            </OrText>
          )}
        </MaestroLoginContainer>
      )}
      <ExternalAuthsWrapper data-testid="authModalLoginViewExternalAuthsWrapper">
        {validAuths.map((kind) => (
          <ExternalLoginButton key={kind} kind={kind} signUp={false} />
        ))}
      </ExternalAuthsWrapper>
    </FormBody>
  );
};

export default LoginView;
