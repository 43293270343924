import React, { useCallback, MouseEvent } from 'react';
import { useDispatch } from 'react-redux';
import AdminSwitch from 'components/admin2/ui/ToggleSwitch';
import { TranslationKey, useAdminTranslation } from 'hooks/use-translation';
import { showModal } from 'services/modals';
import { ModalKinds } from 'services/modals/types';
import {
  ActiveCatalogItemWrapper,
  AvailableCatalogItemWrapper,
  CardActions,
  CardDescription,
  CardIcon,
  CardInformantion,
  CardTitle,
  DeleteIcon,
  PositionIcon,
  PlusIcon,
  StyledDragButt,
  SettingsIcon,
} from './styles';
import { PartialBy } from 'shared/type-utils';

export interface ExistingCatalogItemProps {
  descriptionKey?: TranslationKey;
  draggable?: boolean;
  iconKey: string;
  id: string;
  isACopy?: boolean;
  isActive?: boolean;
  kindId: string;
  multiInstance?: boolean;
  onDelete?: (kindId: string) => void;
  onDuplicate?: (kindId: string) => void;
  onEdit?: (kind: string) => void,
  onPreview?: (kindId: string) => void,
  onToggle?: (kindId: string, checked: boolean) => void,
  position: number;
  titleKey: TranslationKey;
}
export type ICatalogItemProps = PartialBy<ExistingCatalogItemProps, 'id'>;

const CatalogItem: React.FC<ICatalogItemProps> = ({
  kindId,
  titleKey,
  descriptionKey,
  draggable,
  iconKey,
  isActive = false,
  position,
  multiInstance = false,
  isACopy = false,
  onToggle,
  onEdit,
  onDuplicate,
  onDelete,
  onPreview,
}) => {
  const { t } = useAdminTranslation();
  const dispatch = useDispatch();

  const CatalogItemWrapper = isActive && draggable
    ? ActiveCatalogItemWrapper
    : AvailableCatalogItemWrapper;

  const onHandleToggle = useCallback((checked: boolean) => {
    onToggle?.(kindId, checked);
  }, [onToggle, kindId]);

  const handleEdit = useCallback((e?: MouseEvent) => {
    e?.stopPropagation();
    onEdit?.(kindId);
  }, [onEdit, kindId]);

  const handleDuplicate = useCallback((e?: MouseEvent) => {
    e?.stopPropagation();
    onDuplicate?.(kindId);
  }, [onDuplicate, kindId]);

  const handleDelete = useCallback(
    (e?: MouseEvent) => {
      e?.stopPropagation();
      dispatch(
        showModal({
          kind: ModalKinds.adminConfirmation,
          data: {
            onConfirmClick: () => onDelete?.(kindId),
            title: t('ADMIN_PANEL_V2_DELETE_DUPLICATED_PANEL_MODAL_TITLE', {
              title: t(titleKey),
            }),
            subtitleKey:
              'ADMIN_PANEL_V2_DELETE_DUPLICATED_PANEL_MODAL_SUBTITLE',
          },
        }),
      );
    },
    [onDelete, kindId, t, titleKey],
  );

  const numCopy = kindId.split('-')[1] || '';
  const title = t(titleKey);
  const titleText = numCopy ? `${title} ${numCopy}` : title;
  const testIdPrefix = titleText.replace(' ', '-');

  const renderMultiInstanceActions = () => {
    if (isACopy) {
      return (
        <DeleteIcon
          isActive={isActive}
          tooltip={t('ADMIN_PANEL_V2_DELETE_DUPLICATED_PANEL_BUTTON_TOOLTIP', { title: t(titleKey) })}
          onClick={handleDelete}
          testId={`${testIdPrefix}-Delete`}
        />
      );
    } else {
      return (
        <PlusIcon
          isActive={isActive}
          tooltip={t('ADMIN_PANEL_V2_DUPLICATE_PANEL_BUTTON_TOOLTIP', { title: t(titleKey) })}
          onClick={handleDuplicate}
          testId={`${testIdPrefix}-Duplicate`}
        />
      );
    }
  };

  const handlePreview = useCallback(() => {
    if (onPreview) {
      onPreview(kindId);
    }
  }, [onPreview, kindId]);

  return (
    <CatalogItemWrapper onClick={handlePreview} data-testid={`${testIdPrefix}-Card`} renderPreview={Boolean(onPreview)}>
      {isActive && draggable ? (
        <>
          <StyledDragButt />
          <PositionIcon>
            <span>{position}</span>
          </PositionIcon>
        </>
      ) : null}
      <CardIcon isActive={isActive} iconKey={iconKey} />
      <CardInformantion>
        <CardTitle>{titleText}</CardTitle>
        {descriptionKey && <CardDescription>{t(descriptionKey)}</CardDescription>}
      </CardInformantion>
      <CardActions>
        {multiInstance && renderMultiInstanceActions()}
        <SettingsIcon
          isActive={isActive}
          tooltipKey="ADMIN_PANEL_V2_SETTINGS_BUTTON_TOOLTIP"
          onClick={handleEdit}
          testId={`${testIdPrefix}-Settings`}
        />
        <AdminSwitch
          checked={isActive}
          data-testid={`${testIdPrefix}-Toggle`}
          onChange={onHandleToggle}
        />
      </CardActions>
    </CatalogItemWrapper>
  );
};

export default React.memo(CatalogItem);
