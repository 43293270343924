import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import CloseButton from 'components/ui/CloseButton';
import EmailVerificationNotice from 'components/ui/EmailVerificationNotice';
import { ACCENT_PRIMARY, TEXT_100 } from 'style/constants';

const DesktopContainer = styled.div`
  align-items: center;
  background: ${ACCENT_PRIMARY};
  color: ${TEXT_100};
  display: ${props => props.hide ? 'none' : 'flex'};
  left: 0;
  margin-bottom: 20px;
  padding: 16px;
  width: 100%;
`;

const DismissButton = styled(CloseButton)`
  align-self: center;
  color: ${TEXT_100};
  font-size: 24px;
  margin-left: 12px;
`;

export default class HeaderNotification extends React.Component {
  static propTypes = {
    futureVerificationNotice: PropTypes.bool.isRequired,
    showEmailVerificationNotice: PropTypes.bool.isRequired,
  };

  state = {
    hide: false,
  };

  dismissNotice = () => {
    this.setState({ hide: true });
  };

  render() {
    const {
      futureVerificationNotice,
      showEmailVerificationNotice,
    } = this.props;
    const { hide } = this.state;

    let Notification; // set precedence logic here - only displaying one notification
    if (showEmailVerificationNotice) {
      Notification = EmailVerificationNotice;
    } else if (futureVerificationNotice) {
      Notification = null;
    } else {
      Notification = null;
    }

    return (
      Notification !== null && (
      <DesktopContainer hide={hide}>
        <Notification />
        <DismissButton onClick={this.dismissNotice} />
      </DesktopContainer>
      )
    );
  }
}
