// polls
export const ANSWER_ANY_POLL = 'answer:polls:any';
export const ANSWER_SPECIFIC_POLL = 'answer:polls:specific';

// predictions
export const ANSWER_ANY_PREDICTION = 'answer:predictions:any';
export const ANSWER_ANY_PREDICTION_CORRECTLY = 'answer:predictions:any:correctly';
export const ANSWER_SPECIFIC_PREDICTION = 'answer:predictions:specific';
export const ANSWER_SPECIFIC_PREDICTION_CORRECTLY = 'answer:predictions:specific:correctly';

// trivia
export const ANSWER_ANY_TRIVIA = 'answer:trivia:any';
export const ANSWER_ANY_TRIVIA_CORRECTLY = 'answer:trivia:any:correctly';
export const ANSWER_SPECIFIC_TRIVIA = 'answer:trivia:specific';
export const ANSWER_SPECIFIC_TRIVIA_CORRECTLY = 'answer:trivia:specific:correctly';

// other
export const IMPOSSIBLE = 'impossible';
export const OPEN_LINK = 'open:link';
export const RETWEET = 'retweet';
export const SHARE_FACEBOOK = 'share:facebook:video';
export const VIEW_PANEL = 'view:panel';
export const VISIT_CHANNEL = 'visit:channel';
export const VISIT_TIME = 'visit:time';
export const WATCH_ANY_VIDEO = 'watch:any:video';
export const WATCH_SPECIFIC_VIDEO = 'watch:specific:video';

// client-specific
export const SEA_OF_THIEVES_KILL_PLAYER_ID = 'sea_of_thieves_player_kill';
export const SEA_OF_THIEVES_KILL_SKELETON_ID = 'sea_of_thieves_skeleton_kill';
export const SEA_OF_THIEVES_VISIT_ISLAND_ID = 'sea_of_thieves_island_visit';

export const DISABLED_ACTIONS = [
  ANSWER_SPECIFIC_PREDICTION_CORRECTLY,
  ANSWER_ANY_PREDICTION_CORRECTLY,
];
