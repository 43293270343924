import { useAdminTranslation } from 'hooks/use-translation';
import React, { Fragment, ComponentType } from 'react';
import { TI18nKey } from './i18nKey';

interface IProps {
  children?: (args: any) => any;
  component?: ComponentType | keyof JSX.IntrinsicElements;
  componentProps?: Record<string, any>;
  fallback?: string;
  renderAsFragment?: boolean;
  stringKey: TI18nKey;
}

const TranslatedText = (
  {
    children: func,
    component = 'span',
    componentProps = {},
    fallback = '',
    renderAsFragment,
    stringKey,
  }: IProps,
) => {
  const { t } = useAdminTranslation();
  const Component = renderAsFragment ? Fragment : component;
  const message = t(stringKey) || fallback;

  if (func)
    return func(message);

  return (<Component {...componentProps}>{message}</Component>);
};

export default TranslatedText;
