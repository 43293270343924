import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'hooks';

import { logIn, OauthProvider } from 'services/auth';
import Link from 'components/ui/Link';
import BaseLoginButton from './BaseLoginButton';
import { MAESTRO_WHITE } from 'style/constants';

export const ImgIcon = styled.img`
  border-radius: 50%;
  border: 3px solid ${MAESTRO_WHITE};
  background: ${MAESTRO_WHITE};
  height: 25px;
  width: 25px;
`;

interface IProps {
  signUp: boolean;
}

// unactive and not working oauth service
const UMGLoginButton = ({ signUp }: IProps) => {
  const dispatch = useDispatch();
  const triggerLogin = () => {
    dispatch(logIn({ provider: OauthProvider.UMG, strategy: 'oauth' }));
  };

  return (
    <Link onClick={triggerLogin} target="_self">
      <BaseLoginButton
        color="white"
        dataTestId="authModalUmgLoginButton"
        providerNameKey="UMG"
        renderIcon={ // tslint:disable-next-line: jsx-no-lambda no-empty
          () => 'UMG' // TODO: Either add a proper icon or make BaseLoginButton support no icon.
        }
        signUp={signUp}
      />
    </Link>
  );
};

export default UMGLoginButton;
