import React from 'react';
import PropTypes from 'prop-types';
import { DynamicTranslationType, useEndUserTranslation } from 'hooks/use-translation';
import FormInput from 'components/ui/v2/Inputs';

const EmailInput = ({ email }) => {
  const { endUserT } = useEndUserTranslation();

  return (
    <FormInput.FieldSet>
      <FormInput.Legend>
        {endUserT([DynamicTranslationType.globalEmail], ['EMAIL'])}
      </FormInput.Legend>
      <FormInput.InputIcon name="mail" />
      <FormInput.TextInput
        data-testid="authModalRegisterViewEmailInput"
        disabled
        name="email"
        placeholder={endUserT([DynamicTranslationType.globalEmail], ['EMAIL'])}
        type="email"
        value={email}
      />
    </FormInput.FieldSet>
  );
};

EmailInput.propTypes = {
  email: PropTypes.string.isRequired,
};

export default EmailInput;
