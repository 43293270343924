import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  getAmountBreakdown,
  getServiceChargeAmount,
  getTaxAmount,
} from 'services/billing/selectors';
import { SubscriptionType } from 'models/ISubscription';
import { StyledPurchaseSummary } from './styles';
import { useAdminTranslation } from 'hooks/use-translation';

const AmountBreakdown: React.FC = () => {
  const { entitlementPrice, subscriptionType } = useSelector(getAmountBreakdown);
  const taxAmount = useSelector(getTaxAmount);
  const serviceCharge = useSelector(getServiceChargeAmount);
  const { t: translator } = useAdminTranslation();

  const entitlementPriceStringKey = useMemo(() => {
    if (subscriptionType === SubscriptionType.subscription) {
      return 'BILLING_AMOUNT_SUBSCRIPTION';
    }
    return 'BILLING_AMOUNT_TICKET';
  }, [subscriptionType]);

  const items = React.useMemo(() => {
    const summaryItems = [
      {
        name: translator(entitlementPriceStringKey) as string,
        price: entitlementPrice.value,
      },
    ];

    if (serviceCharge > 0 ) {
      summaryItems.push({
        name: translator('BILLING_SERVICE_CHARGE'),
        price: serviceCharge,
      });
    }

    if (taxAmount > 0 ) {
      summaryItems.push({
        name: translator('BILLING_TAX'),
        price: taxAmount,
      });
    }

    return summaryItems;
  }, [taxAmount, entitlementPriceStringKey, entitlementPrice.value, serviceCharge, translator]);

  return <StyledPurchaseSummary items={items} currency={entitlementPrice.currency} />;
};

export default AmountBreakdown;
